"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ILUStatusEnum;
(function (ILUStatusEnum) {
    ILUStatusEnum[ILUStatusEnum["Lead"] = 0] = "Lead";
    ILUStatusEnum[ILUStatusEnum["TourBooked"] = 1] = "TourBooked";
    ILUStatusEnum[ILUStatusEnum["TourCompleted"] = 2] = "TourCompleted";
    ILUStatusEnum[ILUStatusEnum["Waiting"] = 3] = "Waiting";
    ILUStatusEnum[ILUStatusEnum["Confirmed"] = 4] = "Confirmed";
})(ILUStatusEnum = exports.ILUStatusEnum || (exports.ILUStatusEnum = {}));
