"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./tour-dto");
class ProspectDto {
}
exports.ProspectDto = ProspectDto;
/**
 * Returns a string of the provide prospect's name.
 *
 * @param prospect The prospect
 * @returns The propect's name.
 */
function getProspectName(prospect) {
    return `${prospect.firstName} ${prospect.lastName.toUpperCase()}`;
}
exports.getProspectName = getProspectName;
