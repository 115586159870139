"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
require("../accommodation-preferences/dto/accommodation-preferences-save-dto");
require("../dtos/facility-dto");
require("../dtos/accommodation-type-dto");
const i0 = require("@angular/core");
class AccommodationPreferencesService {
    constructor(dataService) {
        this.dataService = dataService;
        this.getSelectedFacility = () => {
            const selectedFacilityId = this.dataService.getSelectedFacilityId();
            return this.getFacilityById(selectedFacilityId);
        };
        this.getUnitTypesForSelectedFacility = () => {
            return this.dataService.get("accommodations/types", true, null, false);
        };
        this.getUnitsForSelectedFacility = () => {
            return this.dataService.get("accommodations", true, null, false).then((result) => {
                var transformedData = [];
                result.forEach(location => {
                    location.accommodations.forEach(accommodation => {
                        transformedData.push({ header: location.locationName, id: accommodation.accommodationId, name: 'Unit ' + accommodation.roomNumber });
                    });
                });
                return transformedData;
            });
        };
        this.getAccommodationPreferences = (residencyId) => {
            return this.dataService.get("enquiries/get-accommodation-preferences", true, { residencyId }, true);
        };
        this.saveAccommodationPreferences = (accommodationPreferencesSaveDto) => {
            return this.dataService.post("enquiries/save-accommodation-preferences", accommodationPreferencesSaveDto);
        };
        this.getFacilityById = (selectedFacilityId) => {
            return this.dataService.get(`facilities/selectedFacility/${selectedFacilityId}`, true, null, true);
        };
    }
}
exports.AccommodationPreferencesService = AccommodationPreferencesService;
AccommodationPreferencesService.ɵfac = function AccommodationPreferencesService_Factory(t) { return new (t || AccommodationPreferencesService)(i0.ɵɵinject('dataService')); };
AccommodationPreferencesService.ɵprov = i0.ɵɵdefineInjectable({ token: AccommodationPreferencesService, factory: AccommodationPreferencesService.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AccommodationPreferencesService, [{
        type: core_1.Injectable
    }], function () { return [{ type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['dataService']
            }] }]; }, null); })();
