"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const common_1 = require("@angular/common");
const reporting_service_1 = require("../reporting.service");
require("../Models");
const CardType_1 = require("./enums/CardType");
const i0 = require("@angular/core");
const i1 = require("../reporting.service");
const i2 = require("@angular/common");
const i3 = require("@angular/forms");
function ResidentCardsReportComponent_label_18_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 15);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r0.selectedFacility.name);
} }
function ResidentCardsReportComponent_select_20_option_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 17);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const facility_r4 = ctx.$implicit;
    i0.ɵɵproperty("ngValue", facility_r4);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(facility_r4.name);
} }
function ResidentCardsReportComponent_select_20_Template(rf, ctx) { if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "select", 16);
    i0.ɵɵlistener("ngModelChange", function ResidentCardsReportComponent_select_20_Template_select_ngModelChange_0_listener($event) { i0.ɵɵrestoreView(_r6); const ctx_r5 = i0.ɵɵnextContext(); return ctx_r5.selectedFacility = $event; })("change", function ResidentCardsReportComponent_select_20_Template_select_change_0_listener() { i0.ɵɵrestoreView(_r6); const ctx_r7 = i0.ɵɵnextContext(); return ctx_r7.onFacilityChange(); });
    i0.ɵɵtext(1, "\n                    ");
    i0.ɵɵelementStart(2, "option", 17);
    i0.ɵɵtext(3, "All");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(4, "         \n                    ");
    i0.ɵɵtemplate(5, ResidentCardsReportComponent_select_20_option_5_Template, 2, 2, "option", 11);
    i0.ɵɵtext(6, "                              \n                ");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngModel", ctx_r1.selectedFacility);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngValue", null);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngForOf", ctx_r1.facilities);
} }
function ResidentCardsReportComponent_option_34_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 17);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const cardType_r8 = ctx.$implicit;
    i0.ɵɵproperty("ngValue", cardType_r8.value);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(cardType_r8.text);
} }
class ResidentCardsReportComponent {
    constructor(reportingService, facilityService, notificationService, downloadFileService, datePipe) {
        this.reportingService = reportingService;
        this.facilityService = facilityService;
        this.notificationService = notificationService;
        this.downloadFileService = downloadFileService;
        this.datePipe = datePipe;
        this.cardTypes = [
            { text: 'All', value: 0 },
            { text: 'DVA', value: CardType_1.CardType.DVA },
            { text: 'Medicare', value: CardType_1.CardType.Medicare },
            { text: 'Pension', value: CardType_1.CardType.Pension },
        ];
        this.initialiseFilters = () => {
            this.reportFilters = {
                facilityId: 0,
                CardType: 0
            };
            this.facilities = this.facilityService.activeUserFacilities.filter(x => x.facilityType == "AgeCare");
            if (this.facilities.length == 1) {
                this.selectedFacility = this.facilities[0];
                this.reportFilters.facilityId = this.selectedFacility.id;
            }
            else {
                this.selectedFacility = null; //Default value for "All"
            }
            this.selectedCardType = 0;
        };
        this.onFacilityChange = () => {
            if (this.selectedFacility != null) {
                this.reportFilters.facilityId = this.selectedFacility.id;
            }
            else {
                this.reportFilters.facilityId = 0;
            }
        };
        this.onCardTypeChange = () => {
            if (this.selectedCardType != 0) {
                this.reportFilters.CardType = this.selectedCardType;
            }
            else {
                this.reportFilters.CardType = 0;
            }
        };
        this.exportReport = () => {
            var runDateTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd_HH.mm.ss');
            var fileName = `Resident Cards Report - ${runDateTime}.xlsx`;
            this.reportingService.getResidentCardsReportExcelStream(this.reportFilters).then((response) => {
                this.downloadFile(response, fileName);
            }, () => {
                this.notificationService.error("Error occurred while exporting Resident Cards Report report");
            });
        };
        this.downloadFile = (response, fileName) => {
            this.downloadFileService.downloadFile(response, fileName);
        };
        this.initialiseFilters();
    }
}
exports.ResidentCardsReportComponent = ResidentCardsReportComponent;
ResidentCardsReportComponent.ɵfac = function ResidentCardsReportComponent_Factory(t) { return new (t || ResidentCardsReportComponent)(i0.ɵɵdirectiveInject(i1.ReportingService), i0.ɵɵdirectiveInject('facility.service'), i0.ɵɵdirectiveInject('notification.service'), i0.ɵɵdirectiveInject('downloadFile.service'), i0.ɵɵdirectiveInject(i2.DatePipe)); };
ResidentCardsReportComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ResidentCardsReportComponent, selectors: [["residentCards-report"]], decls: 50, vars: 4, consts: [["nav-publisher", "reporting", 1, "mtcfs-report"], [1, "col-xs-12"], [1, "page-header"], [1, "row", "space-below-8"], [1, "col-xs-1"], ["for", "facility", 1, "control-label", "label-facility"], [1, "col-xs-3"], ["class", "control-label v-align-middle", 4, "ngIf"], ["name", "facility", "class", "form-control", 3, "ngModel", "ngModelChange", "change", 4, "ngIf"], ["for", "cardType", 1, "control-label", "label-cardType"], ["name", "cardType", 1, "form-control", 3, "ngModel", "ngModelChange", "change"], [3, "ngValue", 4, "ngFor", "ngForOf"], [1, "row"], [1, "col-xs-3", "col-xs-offset-1"], ["type", "button", 1, "btn", "btn-primary", "btn-lg", 3, "click"], [1, "control-label", "v-align-middle"], ["name", "facility", 1, "form-control", 3, "ngModel", "ngModelChange", "change"], [3, "ngValue"]], template: function ResidentCardsReportComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtext(1, "\n    ");
        i0.ɵɵelementStart(2, "div", 1);
        i0.ɵɵtext(3, "\n        ");
        i0.ɵɵelementStart(4, "h1", 2);
        i0.ɵɵtext(5, "Resident cards");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(6, "\n    ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(7, "\n    ");
        i0.ɵɵelementStart(8, "div", 1);
        i0.ɵɵtext(9, "\n        ");
        i0.ɵɵelementStart(10, "div", 3);
        i0.ɵɵtext(11, "       \n            ");
        i0.ɵɵelementStart(12, "div", 4);
        i0.ɵɵelementStart(13, "label", 5);
        i0.ɵɵtext(14, "Facility");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵtext(15, "\n            ");
        i0.ɵɵelementStart(16, "div", 6);
        i0.ɵɵtext(17, "           \n                ");
        i0.ɵɵtemplate(18, ResidentCardsReportComponent_label_18_Template, 2, 1, "label", 7);
        i0.ɵɵtext(19, "  \n                ");
        i0.ɵɵtemplate(20, ResidentCardsReportComponent_select_20_Template, 7, 3, "select", 8);
        i0.ɵɵtext(21, "            \n            ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(22, "\n        ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(23, "\n        ");
        i0.ɵɵelementStart(24, "div", 3);
        i0.ɵɵtext(25, "       \n            ");
        i0.ɵɵelementStart(26, "div", 4);
        i0.ɵɵelementStart(27, "label", 9);
        i0.ɵɵtext(28, "Card type");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵtext(29, "\n            ");
        i0.ɵɵelementStart(30, "div", 6);
        i0.ɵɵtext(31, "           \n                ");
        i0.ɵɵelementStart(32, "select", 10);
        i0.ɵɵlistener("ngModelChange", function ResidentCardsReportComponent_Template_select_ngModelChange_32_listener($event) { return ctx.selectedCardType = $event; })("change", function ResidentCardsReportComponent_Template_select_change_32_listener() { return ctx.onCardTypeChange(); });
        i0.ɵɵtext(33, "       \n                    ");
        i0.ɵɵtemplate(34, ResidentCardsReportComponent_option_34_Template, 2, 2, "option", 11);
        i0.ɵɵtext(35, "                              \n                ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(36, "            \n            ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(37, "\n        ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(38, "\n        ");
        i0.ɵɵelementStart(39, "div", 12);
        i0.ɵɵtext(40, "  \n            ");
        i0.ɵɵelementStart(41, "div", 13);
        i0.ɵɵtext(42, "\n                ");
        i0.ɵɵelementStart(43, "button", 14);
        i0.ɵɵlistener("click", function ResidentCardsReportComponent_Template_button_click_43_listener() { return ctx.exportReport(); });
        i0.ɵɵtext(44, "Export report");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(45, "\n            ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(46, "\n        ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(47, "\n    ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(48, "\n");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(49, "\n");
    } if (rf & 2) {
        i0.ɵɵadvance(18);
        i0.ɵɵproperty("ngIf", ctx.facilities.length == 1);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.facilities.length > 1);
        i0.ɵɵadvance(12);
        i0.ɵɵproperty("ngModel", ctx.selectedCardType);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngForOf", ctx.cardTypes);
    } }, directives: [i2.NgIf, i3.SelectControlValueAccessor, i3.NgControlStatus, i3.NgModel, i2.NgForOf, i3.NgSelectOption, i3.ɵangular_packages_forms_forms_x], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ResidentCardsReportComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'residentCards-report',
                templateUrl: './residentCards-report.component.html',
                preserveWhitespaces: true
            }]
    }], function () { return [{ type: i1.ReportingService }, { type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['facility.service']
            }] }, { type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['notification.service']
            }] }, { type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['downloadFile.service']
            }] }, { type: i2.DatePipe }]; }, null); })();
