"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SupportStatusEnum;
(function (SupportStatusEnum) {
    SupportStatusEnum["All"] = "All";
    SupportStatusEnum["Supported"] = "Supported";
    SupportStatusEnum["Unsupported"] = "Unsupported";
    SupportStatusEnum["Bond"] = "Bond";
    SupportStatusEnum["Charge"] = "Charge";
    SupportStatusEnum["Respite"] = "Respite";
    SupportStatusEnum["Unknown"] = "Unknown";
})(SupportStatusEnum = exports.SupportStatusEnum || (exports.SupportStatusEnum = {}));
