"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class RoomUseInfo {
}
exports.RoomUseInfo = RoomUseInfo;
class Roommate {
}
exports.Roommate = Roommate;
class RoomChange {
}
exports.RoomChange = RoomChange;
