"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("underscore");
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const Models_1 = require("./Models");
const i0 = require("@angular/core");
class BillingService {
    constructor(dataService) {
        this.dataService = dataService;
        this.state = new rxjs_1.BehaviorSubject("");
        this.currentState = this.state.asObservable();
        this.notifyStateChange = (stateName) => {
            this.state.next(stateName);
        };
        this.saveBillingRun = (dto) => {
            return this.dataService.post("billing/billing-run", dto);
        };
        this.getBillingRun = (id) => {
            return this.dataService.get("billing/billing-run/" + id);
        };
        this.getBillingRunResidents = (billingRunId) => {
            return this.dataService.get("billing/billing-run/" + billingRunId + "/residents");
        };
        this.getSelectedBillingRunResidents = (billingRunId) => {
            return this.dataService.get("billing/billing-run/" + billingRunId + "/selected-residents");
        };
        this.checkAllFacilitiesSelected = (allFacilities, selectedFacilities) => {
            return (allFacilities.length > 1 && allFacilities.length == selectedFacilities.length);
        };
        this.checkAllCareTypesSelected = (selectedFacilities, selectedCareTypes) => {
            let agedCareFacilitySelected = false;
            if (_.some(selectedFacilities, x => { return x.facilityType == "AgeCare"; }))
                agedCareFacilitySelected = true;
            let iluFacilitySelected = false;
            if (_.some(selectedFacilities, x => { return x.facilityType == "IndependentLiving"; }))
                iluFacilitySelected = true;
            if (agedCareFacilitySelected && iluFacilitySelected) {
                return selectedCareTypes.length == 4;
            }
            else if (agedCareFacilitySelected && !iluFacilitySelected) {
                return selectedCareTypes.length == 3;
            }
            else if (!agedCareFacilitySelected && iluFacilitySelected)
                return false;
        };
        this.checkAllFeeTypesSelected = (selectedCareTypes, selectedFeeTypes) => {
            let allFeeTypes = this.getAllFeeTypesBySelectedCareTypes(selectedCareTypes);
            return allFeeTypes.length > 1 && selectedFeeTypes.length == allFeeTypes.length;
        };
        this.getAllFeeTypesBySelectedCareTypes = (selectedCareTypes) => {
            let allFeeTypes = [];
            let privateSelected = _.some(selectedCareTypes, x => { return x == Models_1.CareType.Private; });
            if (privateSelected) {
                this.addNewNewFeeTypes(allFeeTypes, [Models_1.FeeType.Pre2014AccomodationChargeDailyAmount, Models_1.FeeType.BasicDailyCareFee,
                    Models_1.FeeType.DailyAccommodationContribute, Models_1.FeeType.DailyAccommodationPayment,
                    Models_1.FeeType.Hardship, Models_1.FeeType.IncomeTestedCareFee, Models_1.FeeType.MeansTestedCareFee, Models_1.FeeType.OptionalService,
                    Models_1.FeeType.Pre2014PeriodicInterestDailyAmountFee, Models_1.FeeType.PrivateDailyCareFee, Models_1.FeeType.SundryCharge]);
            }
            let permanentSelected = _.some(selectedCareTypes, x => { return x == Models_1.CareType.Permanent; });
            if (permanentSelected) {
                this.addNewNewFeeTypes(allFeeTypes, [Models_1.FeeType.Pre2014AccomodationChargeDailyAmount, Models_1.FeeType.BasicDailyCareFee,
                    Models_1.FeeType.DailyAccommodationContribute, Models_1.FeeType.DailyAccommodationPayment,
                    Models_1.FeeType.Hardship, Models_1.FeeType.IncomeTestedCareFee, Models_1.FeeType.MeansTestedCareFee, Models_1.FeeType.OptionalService,
                    Models_1.FeeType.Pre2014PeriodicInterestDailyAmountFee, Models_1.FeeType.SundryCharge]);
            }
            let respiteSelected = _.some(selectedCareTypes, x => { return x == Models_1.CareType.Respite; });
            if (respiteSelected) {
                this.addNewNewFeeTypes(allFeeTypes, [Models_1.FeeType.BasicDailyCareFee, Models_1.FeeType.Hardship, Models_1.FeeType.OptionalService, Models_1.FeeType.SundryCharge]);
            }
            let IluSelected = _.some(selectedCareTypes, x => { return x == Models_1.CareType.ILU; });
            if (IluSelected) {
                this.addNewNewFeeTypes(allFeeTypes, [Models_1.FeeType.OptionalService]);
            }
            return allFeeTypes;
        };
        this.addNewNewFeeTypes = (feeTypes, newFeeTypes) => {
            newFeeTypes.forEach(x => {
                let alreadyExist = _.some(feeTypes, y => { return y == x; });
                if (!alreadyExist)
                    feeTypes.push(x);
            });
        };
    }
}
exports.BillingService = BillingService;
BillingService.ɵfac = function BillingService_Factory(t) { return new (t || BillingService)(i0.ɵɵinject('dataService')); };
BillingService.ɵprov = i0.ɵɵdefineInjectable({ token: BillingService, factory: BillingService.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(BillingService, [{
        type: core_1.Injectable
    }], function () { return [{ type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['dataService']
            }] }]; }, null); })();
