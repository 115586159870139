"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const independent_living_service_1 = require("../services/independent-living.service");
const enquiry_accessibility_requirement_enum_1 = require("../enums/enquiry-accessibility-requirement.enum");
const dnaRSnackBar_service_1 = require("../../common/dna-r/dnaRSnackBar.service");
const i0 = require("@angular/core");
const i1 = require("../services/independent-living.service");
const i2 = require("../../common/dna-r/dnaRSnackBar.service");
const i3 = require("@angular/common");
const i4 = require("@angular/forms");
const i5 = require("@angular/material/radio");
const i6 = require("../../common/pipes/enquiryAccessibilityRequirementDescription.pipe");
function MobilityRequirementsComponent_div_5_a_1_Template(rf, ctx) { if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 10);
    i0.ɵɵlistener("click", function MobilityRequirementsComponent_div_5_a_1_Template_a_click_0_listener() { i0.ɵɵrestoreView(_r6); const ctx_r5 = i0.ɵɵnextContext(2); return ctx_r5.showEditMode(); });
    i0.ɵɵtext(1, "Add");
    i0.ɵɵelementEnd();
} }
function MobilityRequirementsComponent_div_5_a_2_Template(rf, ctx) { if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 10);
    i0.ɵɵlistener("click", function MobilityRequirementsComponent_div_5_a_2_Template_a_click_0_listener() { i0.ɵɵrestoreView(_r8); const ctx_r7 = i0.ɵɵnextContext(2); return ctx_r7.showEditMode(); });
    i0.ɵɵtext(1, "Edit");
    i0.ɵɵelementEnd();
} }
function MobilityRequirementsComponent_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵtemplate(1, MobilityRequirementsComponent_div_5_a_1_Template, 2, 0, "a", 9);
    i0.ɵɵtemplate(2, MobilityRequirementsComponent_div_5_a_2_Template, 2, 0, "a", 9);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.accessibilityRequirement);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.accessibilityRequirement);
} }
function MobilityRequirementsComponent_div_8_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "div", 14);
} }
function MobilityRequirementsComponent_div_8_span_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 15);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "enquiryAccessibilityRequirementDescription");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r10 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, ctx_r10.accessibilityRequirement));
} }
function MobilityRequirementsComponent_div_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 11);
    i0.ɵɵtemplate(1, MobilityRequirementsComponent_div_8_div_1_Template, 1, 0, "div", 12);
    i0.ɵɵtemplate(2, MobilityRequirementsComponent_div_8_span_2_Template, 3, 3, "span", 13);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r1.accessibilityRequirement);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r1.accessibilityRequirement);
} }
function MobilityRequirementsComponent_div_9_Template(rf, ctx) { if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 16);
    i0.ɵɵelementStart(1, "form", 17);
    i0.ɵɵlistener("ngSubmit", function MobilityRequirementsComponent_div_9_Template_form_ngSubmit_1_listener() { i0.ɵɵrestoreView(_r12); const ctx_r11 = i0.ɵɵnextContext(); return ctx_r11.save(); });
    i0.ɵɵelementStart(2, "mat-radio-group", 18);
    i0.ɵɵlistener("ngModelChange", function MobilityRequirementsComponent_div_9_Template_mat_radio_group_ngModelChange_2_listener($event) { i0.ɵɵrestoreView(_r12); const ctx_r13 = i0.ɵɵnextContext(); return ctx_r13.newAccessibilityRequirement = $event; });
    i0.ɵɵelementStart(3, "mat-radio-button", 19);
    i0.ɵɵtext(4, "Accessible Unit Required");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "mat-radio-button", 20);
    i0.ɵɵtext(6, "No Requirements");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "div", 21);
    i0.ɵɵelementStart(8, "button", 22);
    i0.ɵɵlistener("click", function MobilityRequirementsComponent_div_9_Template_button_click_8_listener() { i0.ɵɵrestoreView(_r12); const ctx_r14 = i0.ɵɵnextContext(); return ctx_r14.reset(); });
    i0.ɵɵtext(9, "Cancel");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(10, "button", 23);
    i0.ɵɵtext(11, "Save");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngModel", ctx_r2.newAccessibilityRequirement);
} }
/**
 * MobilityRequirements component.
 *
 * @remarks
 * Independently sets AccessibilityRequirement for a lead.
 */
class MobilityRequirementsComponent {
    /**
     * Initializes a new instance of the MobilityRequirementsComponent class.
     *
     * @param independentLivingService - The Independent Living service.
     * @param snackBar - The Snack Bar service.
     */
    constructor(authenticationService, independentLivingService, snackBarService) {
        this.authenticationService = authenticationService;
        this.independentLivingService = independentLivingService;
        this.snackBarService = snackBarService;
        /**
         * Whether the component is readonly.
         */
        this.readonly = false;
        this.isReadOnly = () => {
            this.authenticationService.userHasPermissionDeferred((x) => {
                return x.leadsDetails.canModify;
            }).then(() => {
                this.readonly = this.readonly ? true : false;
            }, () => {
                this.readonly = true;
            });
        };
        /**
         * Show details.
         */
        this.showEditMode = () => {
            this.isEditMode = true;
        };
        /**
         * Hide details.
         */
        this.hideEditMode = () => {
            this.isEditMode = false;
        };
        /**
         * Submits the currently selected AccessibilityRequirement for the provided Lead to the API.
         */
        this.save = () => {
            if (!this.readonly) {
                this.independentLivingService.updateEnquiryAccessibilityRequirement(this.enquiryId, this.newAccessibilityRequirement).then(result => {
                    this.accessibilityRequirement = this.newAccessibilityRequirement;
                    this.change.emit(this.accessibilityRequirement);
                    this.hideEditMode();
                    this.snackBarService.success('Mobility saved');
                }, () => {
                    this.snackBarService.failure('Mobility couldn\'t be saved');
                });
            }
        };
        /**
         * Resets component.
         */
        this.reset = () => {
            this.hideEditMode();
            this.newAccessibilityRequirement = this.accessibilityRequirement;
        };
        this.change = new core_1.EventEmitter();
        this.isEditMode = false;
    }
    /**
     * @inheritdoc
     */
    ngOnChanges(changes) {
        this.isReadOnly();
        // Update the current AccessibilityRequirement value to the provided value.
        if (changes['accessibilityRequirement']) {
            this.newAccessibilityRequirement = this.accessibilityRequirement;
        }
    }
}
exports.MobilityRequirementsComponent = MobilityRequirementsComponent;
MobilityRequirementsComponent.ɵfac = function MobilityRequirementsComponent_Factory(t) { return new (t || MobilityRequirementsComponent)(i0.ɵɵdirectiveInject('authentication.service'), i0.ɵɵdirectiveInject(i1.IndependentLivingService), i0.ɵɵdirectiveInject(i2.DnaRSnackBarService)); };
MobilityRequirementsComponent.ɵcmp = i0.ɵɵdefineComponent({ type: MobilityRequirementsComponent, selectors: [["mobility-requirements"]], inputs: { enquiryId: "enquiryId", accessibilityRequirement: "accessibilityRequirement", readonly: "readonly" }, outputs: { change: "change" }, features: [i0.ɵɵNgOnChangesFeature], decls: 10, vars: 3, consts: [[1, "mobility-requirements"], [1, "section-top"], [1, "section-header"], [1, "header-text"], ["class", "edit-link", 4, "ngIf"], [1, "section-detail"], ["class", "section-summary-content", 4, "ngIf"], ["class", "section-detail-content", 4, "ngIf"], [1, "edit-link"], [3, "click", 4, "ngIf"], [3, "click"], [1, "section-summary-content"], ["class", "icon icon-accessible-fill detail-icon", 4, "ngIf"], ["class", "detail-text", 4, "ngIf"], [1, "icon", "icon-accessible-fill", "detail-icon"], [1, "detail-text"], [1, "section-detail-content"], ["unsaved-changes-warning", "", "novalidate", "", 3, "ngSubmit"], ["name", "newAccessibilityRequirement", 3, "ngModel", "ngModelChange"], ["value", "AccessibleUnit"], ["value", "None"], [1, "button-group"], ["type", "reset", 1, "btn", "btn-small", "btn-warning", 3, "click"], ["type", "submit", 1, "btn", "btn-small", "btn-primary"]], template: function MobilityRequirementsComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "div", 3);
        i0.ɵɵtext(4, "Mobility");
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(5, MobilityRequirementsComponent_div_5_Template, 3, 2, "div", 4);
        i0.ɵɵelementEnd();
        i0.ɵɵelement(6, "hr");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "div", 5);
        i0.ɵɵtemplate(8, MobilityRequirementsComponent_div_8_Template, 3, 2, "div", 6);
        i0.ɵɵtemplate(9, MobilityRequirementsComponent_div_9_Template, 12, 1, "div", 7);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(5);
        i0.ɵɵproperty("ngIf", !(ctx.readonly || ctx.isEditMode));
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", !ctx.isEditMode);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.isEditMode);
    } }, directives: [i3.NgIf, i4.ɵangular_packages_forms_forms_y, i4.NgControlStatusGroup, i4.NgForm, i5.MatRadioGroup, i4.NgControlStatus, i4.NgModel, i5.MatRadioButton], pipes: [i6.EnquiryAccessibilityRequirementDescription], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(MobilityRequirementsComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'mobility-requirements',
                templateUrl: './mobility-requirements.component.html'
            }]
    }], function () { return [{ type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['authentication.service']
            }] }, { type: i1.IndependentLivingService }, { type: i2.DnaRSnackBarService }]; }, { enquiryId: [{
            type: core_1.Input
        }], accessibilityRequirement: [{
            type: core_1.Input
        }], change: [{
            type: core_1.Output
        }], readonly: [{
            type: core_1.Input
        }] }); })();
