"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("underscore");
const core_1 = require("@angular/core");
const reporting_service_1 = require("../reporting.service");
require("../Models");
const i0 = require("@angular/core");
const i1 = require("../reporting.service");
const i2 = require("@angular/forms");
const i3 = require("devextreme-angular");
const i4 = require("devextreme-angular/ui/nested");
if (!/localhost/.test(document.location.host)) {
    core_1.enableProdMode();
}
class CareMinutesReportComponent {
    constructor(reportingService, facilityService, notificationService, downloadFileService) {
        this.reportingService = reportingService;
        this.facilityService = facilityService;
        this.notificationService = notificationService;
        this.downloadFileService = downloadFileService;
        this.initialiseFilters = () => {
            this.reportFilters = {
                facilityId: 0,
                fromDate: this.fromDateUtc,
                toDate: this.toDateUtc
            };
            this.facilities = this.facilityService.activeUserFacilities.filter(x => x.facilityType == "AgeCare");
            if (this.facilities.length == 1) {
                this.selectedFacility = this.facilities[0];
                this.reportFilters.facilityId = this.selectedFacility.id;
            }
            else {
                this.selectedFacility = null; //Default value for "All"
            }
            this.reportFilters.fromDate = this.fromDateUtc;
            this.reportFilters.toDate = this.toDateUtc;
        };
        this.loadReportData = () => {
            this.reportingService.getCareMinutesReportData(this.reportFilters).then(result => {
                this.careMinutesReportData = result;
                this.filterredCareMinutesReportData = result;
            }, () => {
                this.notificationService.error('Unexpected error occurred while loading report data.');
            });
        };
        this.onFromDateValueChanged = (e) => {
            this.validator2.validate();
            if (e.value != null) {
                const selectedDate = new Date(e.value); //get the selected date      
                //Convert the selected date to utc midnight
                var utcDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0, 0));
                e.component.option('value', utcDate); // re-assign the modified date
                this.reportFilters.fromDate = utcDate;
            }
        };
        this.onToDateValueChanged = (e) => {
            this.validator1.validate();
            if (e.value != null) {
                const selectedDate = new Date(e.value); //get the selected date      
                //Convert the selected date to utc midnight
                var utcDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0, 0));
                e.component.option('value', utcDate); // re-assign the modified date
                this.reportFilters.toDate = utcDate;
            }
        };
        this.dateValidation = () => {
            //if we have either start or end validate, else not required and return true.
            if (this.fromDateUtc && this.toDateUtc) {
                //start date must come before end date
                if (this.fromDateUtc < this.toDateUtc) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return true;
            }
        };
        this.onFacilityChange = () => {
            if (this.selectedFacility != null) {
                this.reportFilters.facilityId = this.selectedFacility.id;
            }
            else {
                this.reportFilters.facilityId = 0;
            }
            if (this.reportFilters.facilityId == 0) {
                this.filterredCareMinutesReportData = this.careMinutesReportData;
            }
            else {
                this.filterredCareMinutesReportData = this.careMinutesReportData.filter(x => x.facilityId == this.reportFilters.facilityId);
            }
        };
        this.onSubmit = () => {
            this.reportingService.getCareMinutesReportExcelStream(this.reportFilters).then((response) => {
                let dateTime = new Date();
                let month = dateTime.getMonth() + 1;
                this.downloadFile(response, 'CareMinutesReport-' + dateTime.getFullYear() + '-' + month + '-' + dateTime.getDate() +
                    '_' + dateTime.getHours() + '.' + dateTime.getMinutes() + '.' + dateTime.getSeconds() + '.xlsx');
            }, () => {
                this.notificationService.error("Error occurred while exporting Care minutes report");
            });
        };
        this.downloadFile = (response, fileName) => {
            this.downloadFileService.downloadFile(response, fileName);
        };
        this.initialiseFilters();
        this.loadReportData();
    }
    onInitialized1(e) {
        this.validator1 = e.component;
    }
    onInitialized2(e) {
        this.validator2 = e.component;
    }
}
exports.CareMinutesReportComponent = CareMinutesReportComponent;
CareMinutesReportComponent.ɵfac = function CareMinutesReportComponent_Factory(t) { return new (t || CareMinutesReportComponent)(i0.ɵɵdirectiveInject(i1.ReportingService), i0.ɵɵdirectiveInject('facility.service'), i0.ɵɵdirectiveInject('notification.service'), i0.ɵɵdirectiveInject('downloadFile.service')); };
CareMinutesReportComponent.ɵcmp = i0.ɵɵdefineComponent({ type: CareMinutesReportComponent, selectors: [["care-minutes-report"]], decls: 65, vars: 13, consts: [["nav-publisher", "reporting", 1, "care-minutes-export"], ["novalidate", "", 3, "ngSubmit"], [1, "row"], [1, "col-xs-6"], [1, "page-header"], [1, "row", "space-below-8"], [1, "col-xs-1"], ["for", "fromDate", 1, "control-label", "v-align-middle"], [1, "col-xs-2"], ["name", "dateBox", "type", "date", "placeholder", "dd/mm/yyyy", "displayFormat", "dd/MM/yyyy", 3, "value", "useMaskBehavior", "height", "width", "valueChange", "onValueChanged"], [3, "onInitialized"], ["type", "custom", "message", "Start date must come before end date", 3, "validationCallback", "reevaluate"], ["for", "toDate", 1, "control-label", "v-align-middle"], ["type", "custom", "message", "End date must come after start date", 3, "validationCallback", "reevaluate"], ["ng-class", "margin-top-20", 1, "col-xs-6", "col-xs-offset-1"], ["type", "submit", 1, "btn", "btn-primary", "btn-lg", 3, "disabled"]], template: function CareMinutesReportComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtext(1, "\n    ");
        i0.ɵɵelementStart(2, "form", 1);
        i0.ɵɵlistener("ngSubmit", function CareMinutesReportComponent_Template_form_ngSubmit_2_listener() { return ctx.onSubmit(); });
        i0.ɵɵtext(3, "\n        ");
        i0.ɵɵelementStart(4, "div", 2);
        i0.ɵɵtext(5, "\n            ");
        i0.ɵɵelementStart(6, "div", 3);
        i0.ɵɵtext(7, "\n                ");
        i0.ɵɵelementStart(8, "h1", 4);
        i0.ɵɵtext(9, "Care Minutes");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(10, "\n            ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(11, "\n        ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(12, "\n        ");
        i0.ɵɵelementStart(13, "div", 5);
        i0.ɵɵtext(14, "\n            ");
        i0.ɵɵelementStart(15, "div", 6);
        i0.ɵɵtext(16, "\n                ");
        i0.ɵɵelementStart(17, "label", 7);
        i0.ɵɵtext(18, "From");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(19, "\n            ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(20, "\n            ");
        i0.ɵɵelementStart(21, "div", 8);
        i0.ɵɵtext(22, "\n                ");
        i0.ɵɵelementStart(23, "dx-date-box", 9);
        i0.ɵɵlistener("valueChange", function CareMinutesReportComponent_Template_dx_date_box_valueChange_23_listener($event) { return ctx.fromDateUtc = $event; })("onValueChanged", function CareMinutesReportComponent_Template_dx_date_box_onValueChanged_23_listener($event) { return ctx.onFromDateValueChanged($event); });
        i0.ɵɵtext(24, "\n                            ");
        i0.ɵɵelementStart(25, "dx-validator", 10);
        i0.ɵɵlistener("onInitialized", function CareMinutesReportComponent_Template_dx_validator_onInitialized_25_listener($event) { return ctx.onInitialized1($event); });
        i0.ɵɵtext(26, "\n                                ");
        i0.ɵɵelementStart(27, "dxi-validation-rule", 11);
        i0.ɵɵtext(28, "\n                                ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(29, "\n                            ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(30, "\n                ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(31, "\n            ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(32, "\n        ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(33, "\n        ");
        i0.ɵɵelementStart(34, "div", 5);
        i0.ɵɵtext(35, "\n            ");
        i0.ɵɵelementStart(36, "div", 6);
        i0.ɵɵtext(37, "\n                ");
        i0.ɵɵelementStart(38, "label", 12);
        i0.ɵɵtext(39, "To");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(40, "\n            ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(41, "\n            ");
        i0.ɵɵelementStart(42, "div", 8);
        i0.ɵɵtext(43, "\n                ");
        i0.ɵɵelementStart(44, "dx-date-box", 9);
        i0.ɵɵlistener("valueChange", function CareMinutesReportComponent_Template_dx_date_box_valueChange_44_listener($event) { return ctx.toDateUtc = $event; })("onValueChanged", function CareMinutesReportComponent_Template_dx_date_box_onValueChanged_44_listener($event) { return ctx.onToDateValueChanged($event); });
        i0.ɵɵtext(45, "\n                            ");
        i0.ɵɵelementStart(46, "dx-validator", 10);
        i0.ɵɵlistener("onInitialized", function CareMinutesReportComponent_Template_dx_validator_onInitialized_46_listener($event) { return ctx.onInitialized2($event); });
        i0.ɵɵtext(47, "\n                                ");
        i0.ɵɵelementStart(48, "dxi-validation-rule", 13);
        i0.ɵɵtext(49, "\n                                ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(50, "\n                            ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(51, "\n                ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(52, "\n            ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(53, "\n        ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(54, "\n        ");
        i0.ɵɵelementStart(55, "div", 2);
        i0.ɵɵtext(56, "\n            ");
        i0.ɵɵelementStart(57, "div", 14);
        i0.ɵɵtext(58, "\n                ");
        i0.ɵɵelementStart(59, "button", 15);
        i0.ɵɵtext(60, "Export report");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(61, "\n            ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(62, "\n        ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(63, "\n    ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(64, "\n");
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(23);
        i0.ɵɵproperty("value", ctx.fromDateUtc)("useMaskBehavior", true)("height", 46)("width", 350);
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("validationCallback", ctx.dateValidation)("reevaluate", true);
        i0.ɵɵadvance(17);
        i0.ɵɵproperty("value", ctx.toDateUtc)("useMaskBehavior", true)("height", 46)("width", 350);
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("validationCallback", ctx.dateValidation)("reevaluate", true);
        i0.ɵɵadvance(11);
        i0.ɵɵproperty("disabled", !ctx.fromDateUtc || !ctx.toDateUtc || ctx.toDateUtc < ctx.fromDateUtc);
    } }, directives: [i2.ɵangular_packages_forms_forms_y, i2.NgControlStatusGroup, i2.NgForm, i3.DxDateBoxComponent, i3.DxValidatorComponent, i4.DxiValidationRuleComponent], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CareMinutesReportComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'care-minutes-report',
                templateUrl: './care-minutes-report.component.html',
                preserveWhitespaces: true
            }]
    }], function () { return [{ type: i1.ReportingService }, { type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['facility.service']
            }] }, { type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['notification.service']
            }] }, { type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['downloadFile.service']
            }] }]; }, null); })();
