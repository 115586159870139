"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const Models_1 = require("../Models");
const _ = require("underscore");
const independent_living_service_1 = require("../services/independent-living.service");
const drag_drop_1 = require("@angular/cdk/drag-drop");
require("../dtos/prospect-dto");
const simple_enquiry_dto_1 = require("../dtos/simple-enquiry-dto");
const dnaRSnackBar_service_1 = require("../../common/dna-r/dnaRSnackBar.service");
const lead_card_1 = require("../models/lead-card");
const ILU_status_enum_1 = require("../enums/ILU-status.enum");
const ilu_leads_dashboard_filter_dto_1 = require("../dtos/ilu-leads-dashboard-filter-dto");
const ilu_leads_dashboard_filter_1 = require("./ilu-leads-dashboard-filter");
const i0 = require("@angular/core");
const i1 = require("../services/independent-living.service");
const i2 = require("../../common/dna-r/dnaRSnackBar.service");
const i3 = require("@angular/common");
const i4 = require("@angular/cdk/drag-drop");
const i5 = require("./ilu-lead-card.component");
const i6 = require("../archived-leads/components/loading-lead-card.component");
function ILULeadsDashboardColumnComponent_h3_2_span_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 8);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r4.totalCount);
} }
function ILULeadsDashboardColumnComponent_h3_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "h3", 6);
    i0.ɵɵtext(1);
    i0.ɵɵtemplate(2, ILULeadsDashboardColumnComponent_h3_2_span_2_Template, 2, 1, "span", 7);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1("", ctx_r0.columnTitle, " ");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.leadCards.length > 0);
} }
function ILULeadsDashboardColumnComponent_div_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "div", 9);
} }
function ILULeadsDashboardColumnComponent_ul_8_li_2_Template(rf, ctx) { if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 14);
    i0.ɵɵtext(1, "\n                ");
    i0.ɵɵelementStart(2, "ilu-lead-card", 15);
    i0.ɵɵlistener("onDelete", function ILULeadsDashboardColumnComponent_ul_8_li_2_Template_ilu_lead_card_onDelete_2_listener($event) { i0.ɵɵrestoreView(_r9); const ctx_r8 = i0.ɵɵnextContext(2); return ctx_r8.handleOnDelete($event); })("onArchive", function ILULeadsDashboardColumnComponent_ul_8_li_2_Template_ilu_lead_card_onArchive_2_listener($event) { i0.ɵɵrestoreView(_r9); const ctx_r10 = i0.ɵɵnextContext(2); return ctx_r10.handleOnArchive($event); });
    i0.ɵɵelementEnd();
    i0.ɵɵtext(3, "\n            ");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const card_r7 = ctx.$implicit;
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("cdkDragData", card_r7);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("leadCard", card_r7)("canDeleteLeads", ctx_r5.canDeleteLeads);
} }
function ILULeadsDashboardColumnComponent_ul_8_a_6_Template(rf, ctx) { if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 16);
    i0.ɵɵlistener("click", function ILULeadsDashboardColumnComponent_ul_8_a_6_Template_a_click_0_listener() { i0.ɵɵrestoreView(_r12); const ctx_r11 = i0.ɵɵnextContext(2); return ctx_r11.loadNextPage(); });
    i0.ɵɵtext(1, "Show more items");
    i0.ɵɵelementEnd();
} }
function ILULeadsDashboardColumnComponent_ul_8_Template(rf, ctx) { if (rf & 1) {
    const _r14 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ul", 10);
    i0.ɵɵlistener("cdkDropListDropped", function ILULeadsDashboardColumnComponent_ul_8_Template_ul_cdkDropListDropped_0_listener($event) { i0.ɵɵrestoreView(_r14); const ctx_r13 = i0.ɵɵnextContext(); return ctx_r13.drop($event); });
    i0.ɵɵtext(1, "\n            ");
    i0.ɵɵtemplate(2, ILULeadsDashboardColumnComponent_ul_8_li_2_Template, 4, 3, "li", 11);
    i0.ɵɵtext(3, "\n            ");
    i0.ɵɵelementStart(4, "li", 12);
    i0.ɵɵtext(5, "\n                ");
    i0.ɵɵtemplate(6, ILULeadsDashboardColumnComponent_ul_8_a_6_Template, 2, 0, "a", 13);
    i0.ɵɵtext(7, "\n            ");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(8, "\n        ");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("cdkDropListData", ctx_r2.leadCards);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r2.leadCards);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("ngIf", ctx_r2.hasMoreItems);
} }
function ILULeadsDashboardColumnComponent_ul_10_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "ul", 17);
    i0.ɵɵtext(1, "\n            ");
    i0.ɵɵelementStart(2, "li", 18);
    i0.ɵɵtext(3, "\n                ");
    i0.ɵɵelement(4, "loading-lead-card");
    i0.ɵɵtext(5, "\n            ");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(6, "\n        ");
    i0.ɵɵelementEnd();
} }
class ILULeadsDashboardColumnComponent {
    constructor(independentLivingService, notificationService, dialogService, snackBarService) {
        this.independentLivingService = independentLivingService;
        this.notificationService = notificationService;
        this.dialogService = dialogService;
        this.snackBarService = snackBarService;
        /**
         * Event emitted after the successful completion a delete action for a lead card.
         */
        this.onLeadDeleted = new core_1.EventEmitter();
        /**
         * Event emitted after the successful completion a archive action for a lead card.
         */
        this.onLeadArchived = new core_1.EventEmitter();
        this.isMoveAcrossColumnsAllowed = (isTourCompletedForAllLeadsInEnquiry, targetStatus) => __awaiter(this, void 0, void 0, function* () {
            //Not allowed if:
            //- Moving to Enquiries column and all prospects have tour completed
            //- Moving to Tour booked column and all prospects have tour completed
            //- Moving to Enquiries column when one prospect has tour completed and the other has tour booked
            //- Moving to Enquiries column when one or more prospect has tour booked
            let isTourCompletedForAnyLeadInEnquiry = yield this.isTourCompletedForAnyLeadInEnquiry(this.enquiryProspects);
            let isTourBookedForAnyLeadInEnquiry = yield this.isTourBookedForAnyLeadInEnquiry(this.enquiryProspects);
            if ((targetStatus == ILU_status_enum_1.ILUStatusEnum.TourBooked || targetStatus == ILU_status_enum_1.ILUStatusEnum.Lead) && isTourCompletedForAllLeadsInEnquiry) {
                this.notificationService.error("Cannot move to Enquiries or Tour booked columns when tour already completed for all prospects in the enquiry.....");
                return false;
            }
            else if (targetStatus == ILU_status_enum_1.ILUStatusEnum.Lead && isTourBookedForAnyLeadInEnquiry) {
                if (isTourCompletedForAnyLeadInEnquiry) {
                    this.notificationService.error("Cannot move to Enquiries column when tour already completed for one prospect and tour already booked for the other prospect in the enquiry.....");
                }
                else {
                    this.notificationService.error("Cannot move to Enquiries column when one or more prospect in the enquiry has a tour booked.....");
                }
                return false;
            }
            else {
                return true;
            }
        });
        this.isTourBookedForAnyLeadInEnquiry = (prospects) => {
            if (prospects.find((prospect) => prospect.tourInfo.id !== 0 && !prospect.tourInfo.isCompleted) !== undefined) {
                return true;
            }
            return false;
        };
        this.isTourCompletedForAnyLeadInEnquiry = (prospects) => {
            if (prospects.find((prospect) => prospect.tourInfo.id !== 0 && prospect.tourInfo.isCompleted) !== undefined) {
                return true;
            }
            return false;
        };
        this.isTourCompletedForAllLeadsInEnquiry = (prospects) => {
            if (prospects.every((prospect) => prospect.tourInfo.id !== 0 && prospect.tourInfo.isCompleted)) {
                return true;
            }
            return false;
        };
        this.getFirstProspectWithoutTourBooked = (prospects) => {
            return prospects.find((prospect) => prospect.tourInfo.id == 0 && !prospect.tourInfo.isCompleted);
        };
        this.getFirstProspectWithoutTourCompleted = (prospects) => {
            return prospects.find((prospect) => !prospect.tourInfo.isCompleted);
        };
        this.tourBookingDialogCancelCallback = () => {
            this.reloadLeadCards();
        };
        this.tourBookingDialogsuccessfulCallback = () => __awaiter(this, void 0, void 0, function* () {
            yield this.moveCardAccrossColumns();
        });
        this.openTourBookingDialog = (residencyId, residentName, tourInfo, sourceStatus, targetStatus, originalOrderNumber) => {
            this.dialogService.openStaticDialog("app/leads/partials/tourBookForLeadsDashboardDialog.html", "tourBookForLeadsDashboardDialog.controller", {
                residencyId: residencyId,
                residentName: residentName,
                tourInfo: tourInfo,
                sourceStatus: sourceStatus,
                targetStatus: targetStatus,
                originalOrderNumber: originalOrderNumber,
                cancelCallback: this.tourBookingDialogCancelCallback,
                successfulCallback: this.tourBookingDialogsuccessfulCallback,
            });
        };
        /**
         * Handles the onDelete event from the child components.
         */
        this.handleOnDelete = (leadCard) => {
            this.independentLivingService.deleteEnquiry(leadCard.enquiry.enquiryId).then((result) => {
                const names = simple_enquiry_dto_1.getEnquiryNames(leadCard.enquiry);
                this.snackBarService.success(names + " deleted");
                const index = _.indexOf(this.leadCards, leadCard);
                this.leadCards.splice(index, 1);
                this.onLeadDeleted.emit(leadCard);
            }, () => {
                this.snackBarService.failure("Unexpected error occurred while deleting an enquiry record.");
            });
        };
        /**
         * Handles the onArchive event from the child components.
         */
        this.handleOnArchive = (leadCard) => {
            this.independentLivingService.archive(leadCard.enquiry.enquiryId).then((result) => {
                const names = simple_enquiry_dto_1.getEnquiryNames(leadCard.enquiry);
                this.snackBarService.success(names + " archived");
                const index = _.indexOf(this.leadCards, leadCard);
                this.leadCards.splice(index, 1);
                this.onLeadArchived.emit(leadCard);
            }, () => {
                this.snackBarService.failure("Unexpected error occurred while archiving an enquiry record.");
            });
        };
        this.leadCards = [];
        this.totalCount = 0;
        this.hasMoreItems = false;
    }
    /**
     * Converts the current @see ILUStatusEnum value in @see leadStatus to the equivalent @see EnquiryStatus value.
     *
     * Currently, the Tour dialog uses the @see EnquiryStatus enum, which is the same but different to @see ILUStatusEnum.
     */
    get enquiryStatus() {
        if (this.leadStatus === ILU_status_enum_1.ILUStatusEnum.Lead) {
            return "Enquiries";
        }
        else if (this.leadStatus === ILU_status_enum_1.ILUStatusEnum.TourBooked) {
            return "TourBooked";
        }
        else if (this.leadStatus === ILU_status_enum_1.ILUStatusEnum.TourCompleted) {
            return "TourCompleted";
        }
        else if (this.leadStatus === ILU_status_enum_1.ILUStatusEnum.Waiting) {
            return "Waiting";
        }
        else if (this.leadStatus === ILU_status_enum_1.ILUStatusEnum.Confirmed) {
            return "Confirmed";
        }
        return "Enquiries";
    }
    ngOnChanges() {
        this.hasMoreItems = this.leadCards.length < this.totalCount;
    }
    /**
     * Loads the next page of leads and concatinates them to the end of the leads card collection.
     */
    loadNextPage() {
        const iluLeadsDashboardFilter = new ilu_leads_dashboard_filter_dto_1.ILULeadsDashboardFilterDto();
        iluLeadsDashboardFilter.facilityLocations = this.leadDashboardFilter.facilityLocations.join(",");
        iluLeadsDashboardFilter.unitTypes = this.leadDashboardFilter.unitTypes.join(",");
        iluLeadsDashboardFilter.accessibilityRequirements = this.leadDashboardFilter.accessibilityRequirements.join(",");
        iluLeadsDashboardFilter.tags = this.leadDashboardFilter.tags.join(",");
        this.independentLivingService.getLeadsForDashboardByStatus(this.leadStatus, this.leadCards.length, Models_1.Constants.CARDS_NUMBER_TAKE_EACH_TIME, iluLeadsDashboardFilter, this.notIncludingItemsAfter).then(response => {
            this.leadCards = this.leadCards.concat(response.result.map(l => new lead_card_1.LeadCard(l.enquiry)));
            this.totalCount = response.totalCount;
            this.hasMoreItems = this.leadCards.length < this.totalCount;
            this.notIncludingItemsAfter = response.notIncludingItemsAfter;
        }, () => {
            this.notificationService.error("Unexpected error while loading leads for column.");
        });
    }
    /**
     * Reloads leads from the API. Will take upto the current number of lead cards without skipping any.
     */
    reloadLeadCards() {
        const take = this.leadCards.length;
        const iluLeadsDashboardFilter = new ilu_leads_dashboard_filter_dto_1.ILULeadsDashboardFilterDto();
        iluLeadsDashboardFilter.facilityLocations = this.leadDashboardFilter.facilityLocations.join(",");
        iluLeadsDashboardFilter.unitTypes = this.leadDashboardFilter.unitTypes.join(",");
        iluLeadsDashboardFilter.accessibilityRequirements = this.leadDashboardFilter.accessibilityRequirements.join(",");
        iluLeadsDashboardFilter.tags = this.leadDashboardFilter.tags.join(",");
        this.loading = true;
        this.independentLivingService.getLeadsForDashboardByStatus(this.leadStatus, 0, take, iluLeadsDashboardFilter, this.notIncludingItemsAfter).then((response) => {
            this.leadCards = response.result.map((l) => new lead_card_1.LeadCard(l.enquiry));
            this.totalCount = response.totalCount;
            this.hasMoreItems = this.leadCards.length < this.totalCount;
            this.notIncludingItemsAfter = response.notIncludingItemsAfter;
            this.loading = false;
        }, () => {
            this.notificationService.error("Unexpected error while loading leads for column.");
            this.loading = false;
        });
    }
    updateProspectsOrderNumber(leadCards) {
        return __awaiter(this, void 0, void 0, function* () {
            let index = 0;
            const reversedLeadCards = yield leadCards.reverse();
            for (let card of reversedLeadCards) {
                yield this.independentLivingService.updateCardsOrderNumber(index, card.enquiry.enquiryId).then(() => { }, (error) => {
                    this.notificationService.error("Unexpected error while reordering cards.");
                });
                index += 1;
            }
            this.reloadLeadCards();
        });
    }
    //Handle card moving within a column or moving to another column:
    drop(event) {
        return __awaiter(this, void 0, void 0, function* () {
            if (event.previousContainer === event.container) {
                //card was moved within a column:
                drag_drop_1.moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
                yield this.updateProspectsOrderNumber(event.container.data);
            }
            else {
                //card was moved to a different column:
                this.enquiryProspects = event.item.data.enquiry.prospects;
                let firstProspectWithoutTourBooked = this.getFirstProspectWithoutTourBooked(this.enquiryProspects);
                let firstProspect = this.enquiryProspects[0];
                let firstProspectWithoutTourCompleted = this.getFirstProspectWithoutTourCompleted(this.enquiryProspects);
                this.previousContainerData = event.previousContainer.data;
                this.containerData = event.container.data;
                this.previousIndex = event.previousIndex;
                this.currentIndex = event.currentIndex;
                let isTourBookedForAnyLeadInEnquiry = yield this.isTourBookedForAnyLeadInEnquiry(this.enquiryProspects);
                let isTourCompletedForAnyLeadInEnquiry = yield this.isTourCompletedForAnyLeadInEnquiry(this.enquiryProspects);
                let isTourCompletedForAllLeadsInEnquiry = yield this.isTourCompletedForAllLeadsInEnquiry(this.enquiryProspects);
                if (yield this.isMoveAcrossColumnsAllowed(isTourCompletedForAllLeadsInEnquiry, this.leadStatus)) {
                    if (this.leadStatus == ILU_status_enum_1.ILUStatusEnum.TourBooked && !isTourBookedForAnyLeadInEnquiry) {
                        if (firstProspect.tourInfo.isCompleted) {
                            yield this.openTourBookingDialog(firstProspectWithoutTourBooked.residencyId, firstProspectWithoutTourBooked.firstName + " " + firstProspectWithoutTourBooked.lastName, firstProspectWithoutTourBooked.tourInfo, "TourBooked", this.enquiryStatus, event.previousIndex);
                        }
                        else {
                            yield this.openTourBookingDialog(firstProspect.residencyId, firstProspect.firstName + " " + firstProspect.lastName, firstProspect.tourInfo, "TourBooked", this.enquiryStatus, event.previousIndex);
                        }
                    }
                    else if (this.leadStatus === ILU_status_enum_1.ILUStatusEnum.TourCompleted && !isTourCompletedForAnyLeadInEnquiry) {
                        this.openTourBookingDialog(firstProspectWithoutTourCompleted.residencyId, firstProspectWithoutTourCompleted.firstName + " " + firstProspectWithoutTourCompleted.lastName, firstProspectWithoutTourCompleted.tourInfo, "TourBooked", this.enquiryStatus, event.previousIndex);
                    }
                    else {
                        yield this.moveCardAccrossColumns();
                    }
                }
            }
        });
    }
    moveCardAccrossColumns() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.independentLivingService.updateEnquiryStatus(this.previousContainerData[this.previousIndex].enquiry.enquiryId, this.leadStatus).then(() => { }, (error) => {
                this.notificationService.error("Unexpected error while updating enquiry status.");
            });
            drag_drop_1.transferArrayItem(this.previousContainerData, this.containerData, this.previousIndex, this.currentIndex);
            this.updateProspectsOrderNumber(this.containerData);
        });
    }
}
exports.ILULeadsDashboardColumnComponent = ILULeadsDashboardColumnComponent;
ILULeadsDashboardColumnComponent.ɵfac = function ILULeadsDashboardColumnComponent_Factory(t) { return new (t || ILULeadsDashboardColumnComponent)(i0.ɵɵdirectiveInject(i1.IndependentLivingService), i0.ɵɵdirectiveInject('notification.service'), i0.ɵɵdirectiveInject('dialog.service'), i0.ɵɵdirectiveInject(i2.DnaRSnackBarService)); };
ILULeadsDashboardColumnComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ILULeadsDashboardColumnComponent, selectors: [["ilu-leads-dashboard-column"]], inputs: { leadStatus: "leadStatus", columnTitle: "columnTitle", leadCards: "leadCards", card: "card", totalCount: "totalCount", loading: "loading", canDeleteLeads: "canDeleteLeads", leadDashboardFilter: "leadDashboardFilter" }, outputs: { onLeadDeleted: "onLeadDeleted", onLeadArchived: "onLeadArchived" }, features: [i0.ɵɵNgOnChangesFeature], decls: 13, vars: 4, consts: [[1, "row", "dashboard-column", "ilu-dashboard-column"], ["class", "row", 4, "ngIf"], ["class", "template", 4, "ngIf"], [1, "row", "body-container"], ["totalCount", "totalCount", "cdkDropList", "", "column-name", "columnTitle", "class", "sortable-container ilu-sortable-container", "leadCards", "leadCards", 3, "cdkDropListData", "cdkDropListDropped", 4, "ngIf"], ["class", "sortable-container ilu-sortable-container", 4, "ngIf"], [1, "row"], ["class", "text-lead ilu-dashboard-column-count", 4, "ngIf"], [1, "text-lead", "ilu-dashboard-column-count"], [1, "template"], ["totalCount", "totalCount", "cdkDropList", "", "column-name", "columnTitle", "leadCards", "leadCards", 1, "sortable-container", "ilu-sortable-container", 3, "cdkDropListData", "cdkDropListDropped"], ["cdkDrag", "", "class", "ilu-lead-card-drag", 3, "cdkDragData", 4, "ngFor", "ngForOf"], [1, "more-items-link"], [3, "click", 4, "ngIf"], ["cdkDrag", "", 1, "ilu-lead-card-drag", 3, "cdkDragData"], [3, "leadCard", "canDeleteLeads", "onDelete", "onArchive"], [3, "click"], [1, "sortable-container", "ilu-sortable-container"], [1, "ilu-lead-card-drag", "ilu-lead-card"]], template: function ILULeadsDashboardColumnComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtext(1, "\n    ");
        i0.ɵɵtemplate(2, ILULeadsDashboardColumnComponent_h3_2_Template, 3, 2, "h3", 1);
        i0.ɵɵtext(3, "\n    ");
        i0.ɵɵtemplate(4, ILULeadsDashboardColumnComponent_div_4_Template, 1, 0, "div", 2);
        i0.ɵɵtext(5, "\n    ");
        i0.ɵɵelementStart(6, "div", 3);
        i0.ɵɵtext(7, "\n        ");
        i0.ɵɵtemplate(8, ILULeadsDashboardColumnComponent_ul_8_Template, 9, 3, "ul", 4);
        i0.ɵɵtext(9, "\n        ");
        i0.ɵɵtemplate(10, ILULeadsDashboardColumnComponent_ul_10_Template, 7, 0, "ul", 5);
        i0.ɵɵtext(11, "\n    ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(12, "\n");
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", !ctx.loading);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.loading);
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("ngIf", !ctx.loading);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.loading);
    } }, directives: [i3.NgIf, i4.CdkDropList, i3.NgForOf, i4.CdkDrag, i5.ILULeadCardComponent, i6.LoadingLeadCardComponent], styles: [".ilu-dashboard-column.dashboard-column[_ngcontent-%COMP%] {\n  padding: 0px;\n}\n.ilu-dashboard-column.dashboard-column[_ngcontent-%COMP%]   .more-items-link[_ngcontent-%COMP%] {\n  cursor: pointer;\n  padding-bottom: 16px;\n}\n.ilu-dashboard-column.dashboard-column[_ngcontent-%COMP%]   .sortable-container[_ngcontent-%COMP%] {\n  margin: 0px 0px 0px 0px;\n}\n.ilu-dashboard-column.dashboard-column[_ngcontent-%COMP%]   .template[_ngcontent-%COMP%] {\n  background: #949494;\n  display: inline-block;\n  height: 10px;\n  animation: loading-pulse 2s ease-in-out 0.5s infinite;\n  width: 50%;\n  margin-top: 28px;\n  margin-bottom: 20px;\n}\n.ilu-dashboard-column.dashboard-column[_ngcontent-%COMP%]   .ilu-lead-card[_ngcontent-%COMP%] {\n  margin-bottom: 16px;\n}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ILULeadsDashboardColumnComponent, [{
        type: core_1.Component,
        args: [{
                selector: "ilu-leads-dashboard-column",
                templateUrl: "./ilu-leads-dashboard-column.component.html",
                styleUrls: ["./ilu-leads-dashboard-column.component.less"],
                preserveWhitespaces: true,
            }]
    }], function () { return [{ type: i1.IndependentLivingService }, { type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['notification.service']
            }] }, { type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['dialog.service']
            }] }, { type: i2.DnaRSnackBarService }]; }, { leadStatus: [{
            type: core_1.Input
        }], columnTitle: [{
            type: core_1.Input
        }], leadCards: [{
            type: core_1.Input
        }], card: [{
            type: core_1.Input
        }], totalCount: [{
            type: core_1.Input
        }], loading: [{
            type: core_1.Input
        }], canDeleteLeads: [{
            type: core_1.Input
        }], leadDashboardFilter: [{
            type: core_1.Input
        }], onLeadDeleted: [{
            type: core_1.Output
        }], onLeadArchived: [{
            type: core_1.Output
        }] }); })();
