"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./region-dto");
class FacilityDto {
}
exports.FacilityDto = FacilityDto;
class FacilityLocationDto {
}
exports.FacilityLocationDto = FacilityLocationDto;
class ValuesWithEffectiveDateDto {
}
exports.ValuesWithEffectiveDateDto = ValuesWithEffectiveDateDto;
