"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const prospect_dto_1 = require("../dtos/prospect-dto");
const i0 = require("@angular/core");
class ProspectName {
    transform(value) {
        if (value) {
            return prospect_dto_1.getProspectName(value);
        }
        return "";
    }
}
exports.ProspectName = ProspectName;
ProspectName.ɵfac = function ProspectName_Factory(t) { return new (t || ProspectName)(); };
ProspectName.ɵpipe = i0.ɵɵdefinePipe({ name: "prospectName", type: ProspectName, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ProspectName, [{
        type: core_1.Pipe,
        args: [{ name: 'prospectName' }]
    }], null, null); })();
