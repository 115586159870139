"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const moment = require("moment");
require("../dtos/assign-bed-filter-dto");
const i0 = require("@angular/core");
class ResidentsService {
    /**
     * Initializes a new instance of the FundingService class.
     *
     * @param dataService The data service.
     */
    constructor(dataService) {
        this.dataService = dataService;
    }
    getAvailableBedsAndRooms(assignBedFilter, residentId, effectiveDate, expectedBookingEndDateUtc) {
        return this.dataService.get("accommodations/available-beds?residentId=" +
            residentId +
            "&effectiveDate=" +
            moment(effectiveDate).toISOString() +
            "&expectedBookingEndDateUtc=" +
            moment(expectedBookingEndDateUtc).toISOString(), true, assignBedFilter);
    }
    getPhoto(residentId) {
        return this.dataService.get("residents/" + residentId + "/photo", true);
    }
    getOccupiedBedsAndRooms(assignBedFilter, residentId, effectiveDate, expectedBookingEndDateUtc) {
        return this.dataService.get("accommodations/occupied-beds?residentId=" +
            residentId +
            "&effectiveDate=" +
            moment(effectiveDate).toISOString() +
            "&expectedBookingEndDateUtc=" +
            moment(expectedBookingEndDateUtc).toISOString(), true, assignBedFilter);
    }
}
exports.ResidentsService = ResidentsService;
ResidentsService.ɵfac = function ResidentsService_Factory(t) { return new (t || ResidentsService)(i0.ɵɵinject('dataService')); };
ResidentsService.ɵprov = i0.ɵɵdefineInjectable({ token: ResidentsService, factory: ResidentsService.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ResidentsService, [{
        type: core_1.Injectable
    }], function () { return [{ type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['dataService']
            }] }]; }, null); })();
