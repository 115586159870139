"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
require("./Models");
const i0 = require("@angular/core");
class ReportingService {
    constructor(dataService) {
        this.dataService = dataService;
        this.getMtcfReportData = (reportFilters) => {
            return this.dataService.get("reporting/mtcfs-report-data/", false, reportFilters);
        };
        this.updateMtcfEffectiveDates = (residencyId, updateMtcfEffectiveDatesDto) => {
            return this.dataService.put("reporting/mtcfs-report-data/" + residencyId, updateMtcfEffectiveDatesDto);
        };
        this.getMtcfsReportExcelStream = (reportFilters) => {
            return this.dataService.getArrayBuffer("reporting/mtcfs-report-data-excel", false, reportFilters);
        };
        this.getResidentCardsReportExcelStream = (reportFilters) => {
            return this.dataService.getArrayBuffer("reporting/residentCards-report-data-excel", false, reportFilters);
        };
        this.getCareMinutesReportData = (reportFilters) => {
            return this.dataService.get("reporting/care-minutes-report-data/", false, reportFilters);
        };
        this.getCareMinutesReportExcelStream = (reportFilters) => {
            return this.dataService.getArrayBuffer("reporting/care-minutes-report-data-excel", false, reportFilters);
        };
        this.getRoomsReportExcelStream = (reportFilters) => {
            return this.dataService.getArrayBuffer("reporting/rooms-report-data-excel", false, reportFilters);
        };
        this.getMovementsReportExcelStream = (reportFilters) => {
            return this.dataService.getArrayBuffer("reporting/movements-report-data-excel", false, reportFilters);
        };
    }
}
exports.ReportingService = ReportingService;
ReportingService.ɵfac = function ReportingService_Factory(t) { return new (t || ReportingService)(i0.ɵɵinject('dataService')); };
ReportingService.ɵprov = i0.ɵɵdefineInjectable({ token: ReportingService, factory: ReportingService.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ReportingService, [{
        type: core_1.Injectable
    }], function () { return [{ type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['dataService']
            }] }]; }, null); })();
