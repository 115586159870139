"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class OrganisationDto {
}
exports.OrganisationDto = OrganisationDto;
class FacilityRaNumber {
}
exports.FacilityRaNumber = FacilityRaNumber;
var ProdaDeviceStatus;
(function (ProdaDeviceStatus) {
    ProdaDeviceStatus[ProdaDeviceStatus["Active"] = 1] = "Active";
    ProdaDeviceStatus[ProdaDeviceStatus["Expired"] = 2] = "Expired";
    ProdaDeviceStatus[ProdaDeviceStatus["Disabled"] = 3] = "Disabled";
    ProdaDeviceStatus[ProdaDeviceStatus["Inactive"] = 4] = "Inactive";
})(ProdaDeviceStatus = exports.ProdaDeviceStatus || (exports.ProdaDeviceStatus = {}));
class ActivatedProdaDeviceDto {
}
exports.ActivatedProdaDeviceDto = ActivatedProdaDeviceDto;
class ActivatedProdaDevicesDto {
}
exports.ActivatedProdaDevicesDto = ActivatedProdaDevicesDto;
