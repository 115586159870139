"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * The Assign bed filter definition.
 */
class AssignBedFilter {
    constructor() {
        /**
         * The facility locations to filter on.
         */
        this.facilityLocations = [];
        /**
         * The room type to filter on.
         */
        this.roomTypes = [];
    }
}
exports.AssignBedFilter = AssignBedFilter;
