"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("underscore");
const core_1 = require("@angular/core");
const core_2 = require("@uirouter/core");
const devextreme_angular_1 = require("devextreme-angular");
const billing_service_1 = require("../billing.service");
const Models_1 = require("../Models");
const i0 = require("@angular/core");
const i1 = require("@uirouter/core");
const i2 = require("../billing.service");
const i3 = require("@uirouter/angular");
const i4 = require("devextreme-angular");
const i5 = require("devextreme-angular/ui/nested");
const i6 = require("@angular/common");
const i7 = require("devextreme-angular/core");
function ViewCommittedBillingRunComponent_dxi_column_46_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "dxi-column", 28);
} if (rf & 2) {
    i0.ɵɵproperty("allowSorting", false);
} }
function ViewCommittedBillingRunComponent_div_48_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 31);
    i0.ɵɵelementStart(1, "span");
    i0.ɵɵtext(2, "Departed");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
const _c0 = function (a0, a1) { return { "col-xs-8": a0, "col-xs-12": a1 }; };
function ViewCommittedBillingRunComponent_div_48_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "div", 29);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, ViewCommittedBillingRunComponent_div_48_div_3_Template, 3, 0, "div", 30);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const data_r2 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("title", data_r2.value);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(4, _c0, data_r2.data.isDeparted, !data_r2.data.isDeparted));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(data_r2.value);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", data_r2.data.isDeparted);
} }
const _c1 = function () { return ["residentName", "crid", "debtorId", "subTotal"]; };
if (!/localhost/.test(document.location.host)) {
    core_1.enableProdMode();
}
class ViewCommittedBillingRunComponent {
    constructor($state, uiRouter, billingService, facilityService, notificationService) {
        this.$state = $state;
        this.uiRouter = uiRouter;
        this.billingService = billingService;
        this.facilityService = facilityService;
        this.notificationService = notificationService;
        this.billingRun = new Models_1.BillingRunDto();
        this.selectedFacilities = [];
        this.billingRunResidents = [];
        this.export = () => {
        };
        this.billingRunId = this.uiRouter.globals.params.id;
    }
    ngOnInit() {
        this.facilities = this.facilityService.activeUserFacilities;
        this.billingService.getBillingRun(this.billingRunId).then((response) => {
            this.billingRun = response;
            this.selectedFacilities = this.facilities.filter(x => _.some(response.facilityIds, y => { return y == x.id; }));
            this.selectedFacilitiesStr = this.billingService.checkAllFacilitiesSelected(this.facilities, this.selectedFacilities) ? "All" : this.selectedFacilities.map(x => x.name).join(',');
            this.selectedCareTypes = response.careTypes.map(x => Models_1.CareType[x]);
            this.selectedCareTypesStr = this.billingService.checkAllCareTypesSelected(this.selectedFacilities, this.selectedCareTypes) ? "All" : this.selectedCareTypes.join(",");
            this.selectedFeeTypes = response.feeTypes.map(x => Models_1.FeeType[x]);
            this.selectedFeeTypesStr = this.billingService.checkAllFeeTypesSelected(this.selectedCareTypes, this.selectedFeeTypes) ? "All" : this.selectedFeeTypes.join(',');
            this.billingService.getSelectedBillingRunResidents(this.billingRun.id).then((response) => {
                this.billingRunResidents = response;
            }, () => {
                this.notificationService.error("Unexpected error while getting the selected billing run residents.");
            });
        }, () => {
            this.notificationService.error("Unexpected error while getting the billing run.");
        });
    }
}
exports.ViewCommittedBillingRunComponent = ViewCommittedBillingRunComponent;
ViewCommittedBillingRunComponent.ɵfac = function ViewCommittedBillingRunComponent_Factory(t) { return new (t || ViewCommittedBillingRunComponent)(i0.ɵɵdirectiveInject(i1.StateService), i0.ɵɵdirectiveInject(i1.UIRouter), i0.ɵɵdirectiveInject(i2.BillingService), i0.ɵɵdirectiveInject('facility.service'), i0.ɵɵdirectiveInject('notification.service')); };
ViewCommittedBillingRunComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ViewCommittedBillingRunComponent, selectors: [["view-committed-billing-run"]], viewQuery: function ViewCommittedBillingRunComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(devextreme_angular_1.DxDataGridComponent, true);
    } if (rf & 2) {
        var _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dataGrid = _t.first);
    } }, decls: 77, vars: 27, consts: [[1, "view-committed-billing-run"], [1, "page-header"], [1, "col-xs-9"], [1, "col-xs-3", "return-link"], ["uiSref", "viewBillingRuns"], [1, "col-xs-8", "main-area"], [1, "col-xs-12"], [1, "control-label", "v-align-middle"], [1, "col-xs-12", "resident-selection"], [1, "col-xs-3"], [1, "row"], [1, "col-xs-12", "charge-summary-table"], ["id", "gridContainer", 3, "dataSource", "columns", "width", "showBorders", "showRowLines", "allowColumnResizing", "columnAutoWidth"], [3, "enabled", "pageSize"], [3, "visible"], ["dataField", "facilityName", "alignment", "left", "dataType", "string", "caption", "FACILITY", 3, "allowSorting", 4, "ngIf"], ["cssClass", "resident-column", "dataField", "residentName", "alignment", "left", "dataType", "string", "cellTemplate", "residentTemplate", "caption", "RESIDENT"], [4, "dxTemplate", "dxTemplateOf"], ["dataField", "crid", "alignment", "left", "dataType", "string", "caption", "CRID"], ["dataField", "debtorId", "alignment", "left", "dataType", "string", "caption", "DEBTOR ID"], ["dataField", "subTotal", "alignment", "left", "dataType", "number", "caption", "TOTAL CHARGES"], ["type", "currency", 3, "precision"], [1, "col-xs-4"], [1, "col-xs-12", "billing-run-info-panel"], [1, "margin-top-15"], [1, "col-xs-12", "committed-notice"], [1, "glyphicon", "glyphicon-ok"], [1, "committed-text"], ["dataField", "facilityName", "alignment", "left", "dataType", "string", "caption", "FACILITY", 3, "allowSorting"], [1, "resident-name", 3, "ngClass", "title"], ["class", "col-xs-4 departed-label", 4, "ngIf"], [1, "col-xs-4", "departed-label"]], template: function ViewCommittedBillingRunComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "h1");
        i0.ɵɵtext(4, "Committed billing run");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "div", 3);
        i0.ɵɵelementStart(6, "a", 4);
        i0.ɵɵtext(7, "Return to billing runs list");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(8, "div");
        i0.ɵɵelementStart(9, "div", 5);
        i0.ɵɵelementStart(10, "div", 6);
        i0.ɵɵelementStart(11, "label", 7);
        i0.ɵɵtext(12, "Resident selection");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(13, "div", 8);
        i0.ɵɵelementStart(14, "div", 9);
        i0.ɵɵelementStart(15, "div", 10);
        i0.ɵɵelementStart(16, "span");
        i0.ɵɵelementStart(17, "strong");
        i0.ɵɵtext(18, "Facilities");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(19, "div", 10);
        i0.ɵɵtext(20);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(21, "div", 9);
        i0.ɵɵelementStart(22, "div", 10);
        i0.ɵɵelementStart(23, "span");
        i0.ɵɵelementStart(24, "strong");
        i0.ɵɵtext(25, "Care type");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(26, "div", 10);
        i0.ɵɵtext(27);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(28, "div", 9);
        i0.ɵɵelementStart(29, "div", 10);
        i0.ɵɵelementStart(30, "span");
        i0.ɵɵelementStart(31, "strong");
        i0.ɵɵtext(32, "Fee type");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(33, "div", 10);
        i0.ɵɵtext(34);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(35, "div", 9);
        i0.ɵɵelementStart(36, "div", 10);
        i0.ɵɵelementStart(37, "span");
        i0.ɵɵelementStart(38, "strong");
        i0.ɵɵtext(39, "Resident in this run");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(40, "div", 10);
        i0.ɵɵtext(41);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(42, "div", 11);
        i0.ɵɵelementStart(43, "dx-data-grid", 12);
        i0.ɵɵelement(44, "dxo-paging", 13);
        i0.ɵɵelement(45, "dxo-filter-row", 14);
        i0.ɵɵtemplate(46, ViewCommittedBillingRunComponent_dxi_column_46_Template, 1, 1, "dxi-column", 15);
        i0.ɵɵelementStart(47, "dxi-column", 16);
        i0.ɵɵtemplate(48, ViewCommittedBillingRunComponent_div_48_Template, 4, 7, "div", 17);
        i0.ɵɵelementEnd();
        i0.ɵɵelement(49, "dxi-column", 18);
        i0.ɵɵelement(50, "dxi-column", 19);
        i0.ɵɵelementStart(51, "dxi-column", 20);
        i0.ɵɵelement(52, "dxo-format", 21);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(53, "div", 22);
        i0.ɵɵelementStart(54, "div", 23);
        i0.ɵɵelementStart(55, "strong");
        i0.ɵɵtext(56);
        i0.ɵɵelementEnd();
        i0.ɵɵelement(57, "div", 24);
        i0.ɵɵtext(58);
        i0.ɵɵelement(59, "div", 24);
        i0.ɵɵelementStart(60, "label", 7);
        i0.ɵɵelementStart(61, "strong");
        i0.ɵɵtext(62, "Charge to date");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(63, "div");
        i0.ɵɵtext(64);
        i0.ɵɵpipe(65, "date");
        i0.ɵɵelementEnd();
        i0.ɵɵelement(66, "div", 24);
        i0.ɵɵelementStart(67, "label", 7);
        i0.ɵɵelementStart(68, "strong");
        i0.ɵɵtext(69, "Total charges");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(70, "div");
        i0.ɵɵtext(71);
        i0.ɵɵpipe(72, "currency");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(73, "div", 25);
        i0.ɵɵelementStart(74, "span", 26);
        i0.ɵɵelementStart(75, "span", 27);
        i0.ɵɵtext(76, "Committed");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(20);
        i0.ɵɵtextInterpolate(ctx.selectedFacilitiesStr);
        i0.ɵɵadvance(7);
        i0.ɵɵtextInterpolate(ctx.selectedCareTypesStr);
        i0.ɵɵadvance(7);
        i0.ɵɵtextInterpolate(ctx.selectedFeeTypesStr);
        i0.ɵɵadvance(7);
        i0.ɵɵtextInterpolate(ctx.billingRunResidents.length);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("dataSource", ctx.billingRunResidents)("columns", i0.ɵɵpureFunction0(26, _c1))("width", 750)("showBorders", true)("showRowLines", true)("allowColumnResizing", true);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("enabled", true)("pageSize", 50);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("visible", true);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.selectedFacilities.length > 1);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("dxTemplateOf", "residentTemplate");
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("precision", 2);
        i0.ɵɵadvance(4);
        i0.ɵɵtextInterpolate(ctx.billingRun.name);
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate1(" ", ctx.billingRun.description, " ");
        i0.ɵɵadvance(6);
        i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(65, 20, ctx.billingRun.chargeToDateUtc, "dd MMM yyyy"));
        i0.ɵɵadvance(7);
        i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(72, 23, ctx.billingRun.totalCharge, "$"));
    } }, directives: [i3.AnchorUISref, i3.UISref, i4.DxDataGridComponent, i5.DxoPagingComponent, i5.DxoFilterRowComponent, i6.NgIf, i5.DxiColumnComponent, i7.DxTemplateDirective, i5.DxoFormatComponent, i6.NgClass], pipes: [i6.DatePipe, i6.CurrencyPipe], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ViewCommittedBillingRunComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'view-committed-billing-run',
                templateUrl: './viewCommittedBillingRun.component.html'
            }]
    }], function () { return [{ type: i1.StateService }, { type: i1.UIRouter }, { type: i2.BillingService }, { type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['facility.service']
            }] }, { type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['notification.service']
            }] }]; }, { dataGrid: [{
            type: core_1.ViewChild,
            args: [devextreme_angular_1.DxDataGridComponent, { static: false }]
        }] }); })();
