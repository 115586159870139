"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
require("underscore");
const i0 = require("@angular/core");
class OrganisationService {
    constructor(dataService) {
        this.dataService = dataService;
        this.getOutOfServiceBeds = (bedId) => {
            return this.dataService.get(`accommodations/out-of-service-beds`, true, { bedId }, true);
        };
        this.getBedHistory = (bedId) => {
            return this.dataService.get(`accommodations/bed-history`, true, { bedId }, false);
        };
        this.getRACAccommodationTypes = (facilityId) => {
            return this.dataService.get("accommodations/rac-types", true, { facilityId }, false);
        };
    }
}
exports.OrganisationService = OrganisationService;
OrganisationService.ɵfac = function OrganisationService_Factory(t) { return new (t || OrganisationService)(i0.ɵɵinject('dataService')); };
OrganisationService.ɵprov = i0.ɵɵdefineInjectable({ token: OrganisationService, factory: OrganisationService.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(OrganisationService, [{
        type: core_1.Injectable
    }], function () { return [{ type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['dataService']
            }] }]; }, null); })();
