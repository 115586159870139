"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const core_2 = require("@uirouter/core");
require("rxjs");
const billing_service_1 = require("../billing.service");
const i0 = require("@angular/core");
const i1 = require("@uirouter/core");
const i2 = require("../billing.service");
const i3 = require("@angular/common");
const i4 = require("@uirouter/angular");
function CreateBillingRunComponent_h1_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "h1");
    i0.ɵɵtext(1, "Add details");
    i0.ɵɵelementEnd();
} }
function CreateBillingRunComponent_h1_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "h1");
    i0.ɵɵtext(1, "Selection criteria");
    i0.ɵɵelementEnd();
} }
function CreateBillingRunComponent_h1_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "h1");
    i0.ɵɵtext(1, "Confirm residents");
    i0.ɵɵelementEnd();
} }
function CreateBillingRunComponent_h1_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "h1");
    i0.ɵɵtext(1, "Review and commit");
    i0.ɵɵelementEnd();
} }
const _c0 = function (a0) { return { "active": a0 }; };
if (!/localhost/.test(document.location.host)) {
    core_1.enableProdMode();
}
class CreateBillingRunComponent {
    constructor(uiRouter, billingService) {
        this.uiRouter = uiRouter;
        this.billingService = billingService;
        this.currentStateName = uiRouter.globals.current.name;
        this.billingRunId = uiRouter.globals.params.id;
    }
    //bind currentStateName with the Observable property in the service
    ngOnInit() {
        this.stateSubscription = this.billingService.currentState.subscribe(x => this.currentStateName = x);
    }
    ngOnDestroy() {
        this.stateSubscription.unsubscribe();
    }
}
exports.CreateBillingRunComponent = CreateBillingRunComponent;
CreateBillingRunComponent.ɵfac = function CreateBillingRunComponent_Factory(t) { return new (t || CreateBillingRunComponent)(i0.ɵɵdirectiveInject(i1.UIRouter), i0.ɵɵdirectiveInject(i2.BillingService)); };
CreateBillingRunComponent.ɵcmp = i0.ɵɵdefineComponent({ type: CreateBillingRunComponent, selectors: [["create-billing-run"]], decls: 23, vars: 16, consts: [["nav-publisher", "billing", 1, "create-billing-run"], [1, "page-header"], [1, "col-xs-4"], [4, "ngIf"], [1, "col-xs-8"], [1, "col-xs-12"], [1, "nav", "navbar-nav"], [3, "ngClass"], ["ui-view", ""]], template: function CreateBillingRunComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵtemplate(3, CreateBillingRunComponent_h1_3_Template, 2, 0, "h1", 3);
        i0.ɵɵtemplate(4, CreateBillingRunComponent_h1_4_Template, 2, 0, "h1", 3);
        i0.ɵɵtemplate(5, CreateBillingRunComponent_h1_5_Template, 2, 0, "h1", 3);
        i0.ɵɵtemplate(6, CreateBillingRunComponent_h1_6_Template, 2, 0, "h1", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelement(7, "div", 4);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(8, "div", 5);
        i0.ɵɵelementStart(9, "ul", 6);
        i0.ɵɵelementStart(10, "li", 7);
        i0.ɵɵelementStart(11, "span");
        i0.ɵɵtext(12, "1.Add details");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(13, "li", 7);
        i0.ɵɵelementStart(14, "span");
        i0.ɵɵtext(15, "2.Selection criteria");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(16, "li", 7);
        i0.ɵɵelementStart(17, "span");
        i0.ɵɵtext(18, "3.Confirm residents");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(19, "li", 7);
        i0.ɵɵelementStart(20, "span");
        i0.ɵɵtext(21, "4.Review and commit");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelement(22, "div", 8);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.currentStateName == "createBillingRun.addDetails");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.currentStateName == "createBillingRun.selectionCriteria");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.currentStateName == "createBillingRun.confirmResidents");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.currentStateName == "createBillingRun.reviewAndCommit");
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(8, _c0, ctx.currentStateName == "createBillingRun.addDetails"));
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(10, _c0, ctx.currentStateName == "createBillingRun.selectionCriteria"));
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(12, _c0, ctx.currentStateName == "createBillingRun.confirmResidents"));
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(14, _c0, ctx.currentStateName == "createBillingRun.reviewAndCommit"));
    } }, directives: [i3.NgIf, i3.NgClass, i4.UIView], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CreateBillingRunComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'create-billing-run',
                templateUrl: './createBillingRun.component.html'
            }]
    }], function () { return [{ type: i1.UIRouter }, { type: i2.BillingService }]; }, null); })();
