"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const core_2 = require("@uirouter/core");
require("../dtos/simple-enquiry-dto");
const enquiry_accessibility_requirement_enum_1 = require("../enums/enquiry-accessibility-requirement.enum");
const lead_card_1 = require("../models/lead-card");
const i0 = require("@angular/core");
const i1 = require("@uirouter/core");
const i2 = require("@angular/common");
const i3 = require("./enquiry-lead-card.component");
class ILULeadCardComponent {
    constructor($state) {
        this.$state = $state;
        this.onDelete = new core_1.EventEmitter();
        this.onArchive = new core_1.EventEmitter();
        this.bindings = {
            leadCard: "<",
        };
        this.isGrabbing = false;
    }
    ngOnChanges() {
        this.enquiry = this.leadCard.enquiry;
        this.createDate = this.leadCard.enquiry.createdOnUtc;
        this.unitAccessibilityRequirement = this.leadCard.enquiry.accessibilityRequirement;
    }
    viewEnquiry(enquiryId, residentId) {
        this.$state.go("viewEnquiry", { "enquiryId": enquiryId, "residentId": residentId });
    }
    handleOnDelete(enquiry) {
        this.onDelete.emit(enquiry);
    }
    handleOnArchive(enquiry) {
        this.onArchive.emit(enquiry);
    }
}
exports.ILULeadCardComponent = ILULeadCardComponent;
ILULeadCardComponent.ɵfac = function ILULeadCardComponent_Factory(t) { return new (t || ILULeadCardComponent)(i0.ɵɵdirectiveInject(i1.StateService)); };
ILULeadCardComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ILULeadCardComponent, selectors: [["ilu-lead-card"]], inputs: { leadCard: "leadCard", unitAccessibilityRequirement: "unitAccessibilityRequirement", canDeleteLeads: "canDeleteLeads" }, outputs: { onDelete: "onDelete", onArchive: "onArchive" }, features: [i0.ɵɵNgOnChangesFeature], decls: 4, vars: 2, consts: [["data-automation", "leadCard", "ngMousedown", "isGrabbing = true", "ngMouseup", "isGrabbing = false", "ngClass", "{'cursor-grabbing': isGrabbing  ,'cursor-default': !isGrabbing}", 1, "dna-r", "ilu-lead-card"], [3, "leadCard", "canDeleteLeads", "onDelete", "onArchive"]], template: function ILULeadCardComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtext(1, "\n    ");
        i0.ɵɵelementStart(2, "enquiry-lead-card", 1);
        i0.ɵɵlistener("onDelete", function ILULeadCardComponent_Template_enquiry_lead_card_onDelete_2_listener($event) { return ctx.handleOnDelete($event); })("onArchive", function ILULeadCardComponent_Template_enquiry_lead_card_onArchive_2_listener($event) { return ctx.handleOnArchive($event); });
        i0.ɵɵelementEnd();
        i0.ɵɵtext(3, "\n");
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("leadCard", ctx.leadCard)("canDeleteLeads", ctx.canDeleteLeads);
    } }, directives: [i2.NgClass, i3.EnquiryLeadCardComponent], styles: [".accessibility-summary-content[_ngcontent-%COMP%] {\n  margin-top: 10px;\n}\n.accessibility-summary-content[_ngcontent-%COMP%]   .detail-icon[_ngcontent-%COMP%] {\n  margin-right: 4px;\n}\n.accessibility-summary-content[_ngcontent-%COMP%]   .detail-text[_ngcontent-%COMP%] {\n  vertical-align: top;\n}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ILULeadCardComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'ilu-lead-card',
                templateUrl: './ilu-lead-card.component.html',
                styleUrls: ['./ilu-lead-card.component.less'],
                preserveWhitespaces: true
            }]
    }], function () { return [{ type: i1.StateService }]; }, { leadCard: [{
            type: core_1.Input
        }], unitAccessibilityRequirement: [{
            type: core_1.Input
        }], canDeleteLeads: [{
            type: core_1.Input
        }], onDelete: [{
            type: core_1.Output
        }], onArchive: [{
            type: core_1.Output
        }] }); })();
