"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const i0 = require("@angular/core");
const i1 = require("@angular/common");
function LoadingAssignBedComponent_span_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "span", 6);
} }
function LoadingAssignBedComponent_span_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "span", 6);
} }
function LoadingAssignBedComponent_span_15_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "span", 6);
} }
function LoadingAssignBedComponent_span_21_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "span", 6);
} }
const _c0 = function (a0) { return { "roommates-no-locations": a0 }; };
/**
 * Blank loading bed information.
 */
class LoadingAssignBedComponent {
}
exports.LoadingAssignBedComponent = LoadingAssignBedComponent;
LoadingAssignBedComponent.ɵfac = function LoadingAssignBedComponent_Factory(t) { return new (t || LoadingAssignBedComponent)(); };
LoadingAssignBedComponent.ɵcmp = i0.ɵɵdefineComponent({ type: LoadingAssignBedComponent, selectors: [["loading-assign-bed"]], inputs: { facilityHasLocations: "facilityHasLocations" }, decls: 24, vars: 16, consts: [[1, "loading-assign-bed"], [1, "template", "room"], [1, "template", "bed"], ["class", "template location", 4, "ngIf"], [1, "template", "roomType"], [1, "template", "roommates", 3, "ngClass"], [1, "template", "location"]], template: function LoadingAssignBedComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelement(1, "span", 1);
        i0.ɵɵelement(2, "span", 2);
        i0.ɵɵtemplate(3, LoadingAssignBedComponent_span_3_Template, 1, 0, "span", 3);
        i0.ɵɵelement(4, "span", 4);
        i0.ɵɵelement(5, "span", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(6, "div", 0);
        i0.ɵɵelement(7, "span", 1);
        i0.ɵɵelement(8, "span", 2);
        i0.ɵɵtemplate(9, LoadingAssignBedComponent_span_9_Template, 1, 0, "span", 3);
        i0.ɵɵelement(10, "span", 4);
        i0.ɵɵelement(11, "span", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(12, "div", 0);
        i0.ɵɵelement(13, "span", 1);
        i0.ɵɵelement(14, "span", 2);
        i0.ɵɵtemplate(15, LoadingAssignBedComponent_span_15_Template, 1, 0, "span", 3);
        i0.ɵɵelement(16, "span", 4);
        i0.ɵɵelement(17, "span", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(18, "div", 0);
        i0.ɵɵelement(19, "span", 1);
        i0.ɵɵelement(20, "span", 2);
        i0.ɵɵtemplate(21, LoadingAssignBedComponent_span_21_Template, 1, 0, "span", 3);
        i0.ɵɵelement(22, "span", 4);
        i0.ɵɵelement(23, "span", 5);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.facilityHasLocations);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(8, _c0, !ctx.facilityHasLocations));
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("ngIf", ctx.facilityHasLocations);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(10, _c0, !ctx.facilityHasLocations));
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("ngIf", ctx.facilityHasLocations);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(12, _c0, !ctx.facilityHasLocations));
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("ngIf", ctx.facilityHasLocations);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(14, _c0, !ctx.facilityHasLocations));
    } }, directives: [i1.NgIf, i1.NgClass], styles: [".loading-assign-bed[_ngcontent-%COMP%] {\n  border-bottom: 1px solid #D2D2D2;\n}\n.loading-assign-bed[_ngcontent-%COMP%]   .room[_ngcontent-%COMP%] {\n  width: 130px;\n  margin: 15px 16px 15px 16px;\n}\n.loading-assign-bed[_ngcontent-%COMP%]   .bed[_ngcontent-%COMP%] {\n  width: 72px;\n  margin: 15px 16px 15px 16px;\n}\n.loading-assign-bed[_ngcontent-%COMP%]   .location[_ngcontent-%COMP%] {\n  width: 213px;\n  margin: 15px 16px 15px 16px;\n}\n.loading-assign-bed[_ngcontent-%COMP%]   .roomType[_ngcontent-%COMP%] {\n  width: 222px;\n  margin: 15px 16px 15px 16px;\n}\n.loading-assign-bed[_ngcontent-%COMP%]   .roommates[_ngcontent-%COMP%] {\n  width: 285px;\n  margin: 15px 16px 15px 16px;\n}\n.loading-assign-bed[_ngcontent-%COMP%]   .roommates.roommates-no-locations[_ngcontent-%COMP%] {\n  width: 530px;\n}\n.loading-assign-bed[_ngcontent-%COMP%]   .template[_ngcontent-%COMP%] {\n  background: #D2D2D2;\n  display: inline-block;\n  height: 10px;\n  animation: loading-pulse 2s ease-in-out 0.5s infinite;\n}\n@keyframes loading-pulse {\n  0% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0.4;\n  }\n  100% {\n    opacity: 1;\n  }\n}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(LoadingAssignBedComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'loading-assign-bed',
                styleUrls: ['./loading-assign-bed.component.less'],
                templateUrl: './loading-assign-bed.component.html'
            }]
    }], null, { facilityHasLocations: [{
            type: core_1.Input
        }] }); })();
