"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const common_1 = require("@angular/common");
const forms_1 = require("@angular/forms");
const dnaR_input_component_1 = require("./dnaR-input.component");
const dnaR_currency_directive_1 = require("./dnaR-currency.directive");
const i0 = require("@angular/core");
class DnaRInputModule {
    static forRoot() {
        return {
            ngModule: DnaRInputModule
        };
    }
}
exports.DnaRInputModule = DnaRInputModule;
DnaRInputModule.ɵmod = i0.ɵɵdefineNgModule({ type: DnaRInputModule });
DnaRInputModule.ɵinj = i0.ɵɵdefineInjector({ factory: function DnaRInputModule_Factory(t) { return new (t || DnaRInputModule)(); }, imports: [[
            common_1.CommonModule,
            forms_1.FormsModule,
            forms_1.ReactiveFormsModule
        ]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DnaRInputModule, { declarations: [dnaR_input_component_1.DnaRInput, dnaR_currency_directive_1.DnaRCurrencyInput], imports: [common_1.CommonModule,
        forms_1.FormsModule,
        forms_1.ReactiveFormsModule], exports: [dnaR_input_component_1.DnaRInput] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DnaRInputModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [
                    common_1.CommonModule,
                    forms_1.FormsModule,
                    forms_1.ReactiveFormsModule
                ],
                declarations: [dnaR_input_component_1.DnaRInput, dnaR_currency_directive_1.DnaRCurrencyInput],
                exports: [dnaR_input_component_1.DnaRInput],
                schemas: [
                    core_1.CUSTOM_ELEMENTS_SCHEMA
                ]
            }]
    }], null, null); })();
