"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const core_1 = require("@angular/core");
const static_1 = require("@angular/upgrade/static");
const shared_module_1 = require("../common/shared.module");
const funding_service_1 = require("./services/funding.service");
const residents_service_1 = require("./services/residents.service");
const anacc_calculator_component_1 = require("./anacc-calculator/anacc-calculator.component");
const anacc_daily_rate_component_1 = require("./anacc-daily-rate/anacc-daily-rate.component");
const checkbox_1 = require("@angular/material/checkbox");
const button_toggle_1 = require("@angular/material/button-toggle");
const forms_1 = require("@angular/forms");
const assign_bed_modal_component_1 = require("./assign-bed/assign-bed-modal.component");
const icon_1 = require("@angular/material/icon");
const dnaR_datePicker_module_1 = require("../common/components/dnaR-datePicker/dnaR-datePicker.module");
const dnaR_dropdown_multiSelect_module_1 = require("../common/components/dnaR-dropdown-multiSelect/dnaR-dropdown-multiSelect.module");
const tooltip_1 = require("@angular/material/tooltip");
const resident_profile_photo_component_1 = require("./resident-profile-photo/resident-profile-photo.component");
const loading_assign_bed_component_1 = require("./assign-bed/loading-assign-bed.component");
const i0 = require("@angular/core");
// Downgrade and register items in AngularJs
angular.module('app')
    .directive('anaccCalculator', static_1.downgradeComponent({
    component: anacc_calculator_component_1.AnaccCalculatorComponent,
}))
    .directive('anaccDailyRate', static_1.downgradeComponent({
    component: anacc_daily_rate_component_1.AnaccDailyRateComponent,
}))
    .directive('assignBedModal', static_1.downgradeComponent({
    component: assign_bed_modal_component_1.AssignBedModalComponent
}))
    .factory('fundingService', static_1.downgradeInjectable(funding_service_1.FundingService));
class ResidentsModule {
}
exports.ResidentsModule = ResidentsModule;
ResidentsModule.ɵmod = i0.ɵɵdefineNgModule({ type: ResidentsModule });
ResidentsModule.ɵinj = i0.ɵɵdefineInjector({ factory: function ResidentsModule_Factory(t) { return new (t || ResidentsModule)(); }, providers: [funding_service_1.FundingService, residents_service_1.ResidentsService], imports: [[
            shared_module_1.SharedModule,
            forms_1.FormsModule,
            forms_1.ReactiveFormsModule,
            checkbox_1.MatCheckboxModule,
            button_toggle_1.MatButtonToggleModule,
            icon_1.MatIconModule,
            tooltip_1.MatTooltipModule,
            dnaR_datePicker_module_1.DnaRDatePickerModule,
            dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule
        ]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(ResidentsModule, { declarations: [anacc_calculator_component_1.AnaccCalculatorComponent,
        anacc_daily_rate_component_1.AnaccDailyRateComponent,
        assign_bed_modal_component_1.AssignBedModalComponent,
        resident_profile_photo_component_1.ResidentProfilePhotoComponent,
        loading_assign_bed_component_1.LoadingAssignBedComponent], imports: [shared_module_1.SharedModule,
        forms_1.FormsModule,
        forms_1.ReactiveFormsModule,
        checkbox_1.MatCheckboxModule,
        button_toggle_1.MatButtonToggleModule,
        icon_1.MatIconModule,
        tooltip_1.MatTooltipModule,
        dnaR_datePicker_module_1.DnaRDatePickerModule,
        dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ResidentsModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [
                    shared_module_1.SharedModule,
                    forms_1.FormsModule,
                    forms_1.ReactiveFormsModule,
                    checkbox_1.MatCheckboxModule,
                    button_toggle_1.MatButtonToggleModule,
                    icon_1.MatIconModule,
                    tooltip_1.MatTooltipModule,
                    dnaR_datePicker_module_1.DnaRDatePickerModule,
                    dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule
                ],
                providers: [funding_service_1.FundingService, residents_service_1.ResidentsService],
                declarations: [
                    anacc_calculator_component_1.AnaccCalculatorComponent,
                    anacc_daily_rate_component_1.AnaccDailyRateComponent,
                    assign_bed_modal_component_1.AssignBedModalComponent,
                    resident_profile_photo_component_1.ResidentProfilePhotoComponent,
                    loading_assign_bed_component_1.LoadingAssignBedComponent
                ]
            }]
    }], null, null); })();
