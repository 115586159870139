"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("@uirouter/angular");
const viewBillingRuns_component_1 = require("./billingRun/viewBillingRuns.component");
const createBillingRun_component_1 = require("./billingRun/createBillingRun.component");
const viewCommittedBillingRun_component_1 = require("./billingRun/viewCommittedBillingRun.component");
const addDetails_component_1 = require("./billingRun/addDetails.component");
const selectionCriteria_component_1 = require("./billingRun/selectionCriteria.component");
const confirmResidents_component_1 = require("./billingRun/confirmResidents.component");
const reviewAndCommit_component_1 = require("./billingRun/reviewAndCommit.component");
exports.states = [
    {
        name: "viewBillingRuns",
        url: "/billingRun/viewBillingRuns",
        component: viewBillingRuns_component_1.ViewBillingRunsComponent
    },
    {
        name: "createBillingRun",
        url: "/billingRun/createBillingRun/{id:int}",
        component: createBillingRun_component_1.CreateBillingRunComponent
    },
    {
        name: "createBillingRun.addDetails",
        url: "/addDetails",
        component: addDetails_component_1.AddDetailsComponent
    },
    {
        name: "createBillingRun.selectionCriteria",
        url: "/selectionCriteria",
        component: selectionCriteria_component_1.SelectionCriteriaComponent
    },
    {
        name: "createBillingRun.reviewAndCommit",
        url: "/reviewAndCommit",
        component: reviewAndCommit_component_1.ReviewAndCommitComponent
    },
    {
        name: "createBillingRun.confirmResidents",
        url: "/confirmResidents",
        component: confirmResidents_component_1.ConfirmResidentsComponent
    },
    {
        name: "viewCommittedBillingRun",
        url: "/billingRun/viewCommittedBillingRun/{id:int}",
        component: viewCommittedBillingRun_component_1.ViewCommittedBillingRunComponent
    }
];
