"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
require("../Models");
const i0 = require("@angular/core");
class OrganisationService {
    constructor(dataService) {
        this.dataService = dataService;
        this.getActivatedProdaDevices = () => {
            return this.dataService.get("organisation/activated-proda-devices");
        };
        this.activateProdaDevice = (prodaOrganisationId, deviceName, deviceActivationCode) => {
            return this.dataService.post("organisation/activate-proda-device", {
                prodaOrganisationId: prodaOrganisationId,
                deviceName: deviceName,
                deviceActivationCode: deviceActivationCode
            });
        };
        this.getProdaDeviceStatus = () => {
            return this.dataService.get("organisation/proda-device-status");
        };
    }
}
exports.OrganisationService = OrganisationService;
OrganisationService.ɵfac = function OrganisationService_Factory(t) { return new (t || OrganisationService)(i0.ɵɵinject('dataService')); };
OrganisationService.ɵprov = i0.ɵɵdefineInjectable({ token: OrganisationService, factory: OrganisationService.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(OrganisationService, [{
        type: core_1.Injectable
    }], function () { return [{ type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['dataService']
            }] }]; }, null); })();
