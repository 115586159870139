"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const common_1 = require("@angular/common");
const angular_hybrid_1 = require("@uirouter/angular-hybrid");
const core_1 = require("@angular/core");
const static_1 = require("@angular/upgrade/static");
const platform_browser_1 = require("@angular/platform-browser");
const forms_1 = require("@angular/forms");
const http_1 = require("@angular/common/http");
require("@uirouter/core");
const authentication_module_1 = require("./authentication/authentication.module");
const dnaR_dropdown_multiSelect_module_1 = require("./common/components/dnaR-dropdown-multiSelect/dnaR-dropdown-multiSelect.module");
const reporting_module_1 = require("./reporting/reporting.module");
const independent_living_module_1 = require("./independent-living/independent-living.module");
const billing_module_1 = require("./billing/billing.module");
const admin_module_1 = require("./admin/admin.module");
const users_service_1 = require("./authentication/services/users.service");
const animations_1 = require("@angular/platform-browser/animations");
const snack_bar_1 = require("@angular/material/snack-bar");
const dnaRSnackBar_service_1 = require("./common/dna-r/dnaRSnackBar.service");
const residents_module_1 = require("./residents/residents.module");
const organisation_module_1 = require("./organisation/organisation.module");
const config_1 = require("devextreme/core/config");
const shared_module_1 = require("./common/shared.module");
const dnaR_dropdown_multiSelect_component_1 = require("./common/components/dnaR-dropdown-multiSelect/dnaR-dropdown-multiSelect.component");
const dnaR_checkbox_component_1 = require("./common/components/dnaR-checkbox/dnaR-checkbox.component");
const i0 = require("@angular/core");
const i1 = require("@uirouter/angular-hybrid");
const i2 = require("@angular/upgrade/static");
config_1.default({
    forceIsoDateParsing: true
});
//downgrades
angular.module('app')
    .config(['$urlServiceProvider', ($urlService) => $urlService.deferIntercept()])
    .factory('users.service', static_1.downgradeInjectable(users_service_1.UsersService))
    .directive('dnaRDropdownMultiSelect', static_1.downgradeComponent({ component: dnaR_dropdown_multiSelect_component_1.DnaRDropdownMultiSelect }))
    .directive('dnaRCheckbox', static_1.downgradeComponent({ component: dnaR_checkbox_component_1.DnaRCheckbox }));
class AppModule {
    constructor(upgradeModule) {
        this.upgradeModule = upgradeModule;
    }
    ngDoBootstrap() {
        this.upgradeModule.bootstrap(document.body, ['app'], { strictDi: true });
    }
}
exports.AppModule = AppModule;
AppModule.ɵmod = i0.ɵɵdefineNgModule({ type: AppModule });
AppModule.ɵinj = i0.ɵɵdefineInjector({ factory: function AppModule_Factory(t) { return new (t || AppModule)(i0.ɵɵinject(i2.UpgradeModule)); }, providers: [
        common_1.DatePipe,
        { provide: snack_bar_1.MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: dnaRSnackBar_service_1.DnaRSnackBarService.DEFAULT_OPTIONS },
        {
            provide: 'authentication.service',
            useFactory: ($injector) => $injector.get('authentication.service'),
            deps: ['$injector']
        }
    ], imports: [[
            platform_browser_1.BrowserModule,
            static_1.UpgradeModule,
            angular_hybrid_1.UIRouterUpgradeModule.forRoot(),
            forms_1.FormsModule,
            http_1.HttpClientModule,
            authentication_module_1.AuthenticationModule,
            dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule,
            reporting_module_1.ReportingModule,
            billing_module_1.BillingModule,
            admin_module_1.AdminModule,
            animations_1.BrowserAnimationsModule,
            independent_living_module_1.IndependentLivingModule,
            residents_module_1.ResidentsModule,
            shared_module_1.SharedModule,
            organisation_module_1.OrganisationModule
        ]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(AppModule, { imports: [platform_browser_1.BrowserModule,
        static_1.UpgradeModule, i1.UIRouterUpgradeModule, forms_1.FormsModule,
        http_1.HttpClientModule,
        authentication_module_1.AuthenticationModule,
        dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule,
        reporting_module_1.ReportingModule,
        billing_module_1.BillingModule,
        admin_module_1.AdminModule,
        animations_1.BrowserAnimationsModule,
        independent_living_module_1.IndependentLivingModule,
        residents_module_1.ResidentsModule,
        shared_module_1.SharedModule,
        organisation_module_1.OrganisationModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AppModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [
                    platform_browser_1.BrowserModule,
                    static_1.UpgradeModule,
                    angular_hybrid_1.UIRouterUpgradeModule.forRoot(),
                    forms_1.FormsModule,
                    http_1.HttpClientModule,
                    authentication_module_1.AuthenticationModule,
                    dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule,
                    reporting_module_1.ReportingModule,
                    billing_module_1.BillingModule,
                    admin_module_1.AdminModule,
                    animations_1.BrowserAnimationsModule,
                    independent_living_module_1.IndependentLivingModule,
                    residents_module_1.ResidentsModule,
                    shared_module_1.SharedModule,
                    organisation_module_1.OrganisationModule
                ],
                declarations: [],
                providers: [
                    common_1.DatePipe,
                    { provide: snack_bar_1.MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: dnaRSnackBar_service_1.DnaRSnackBarService.DEFAULT_OPTIONS },
                    {
                        provide: 'authentication.service',
                        useFactory: ($injector) => $injector.get('authentication.service'),
                        deps: ['$injector']
                    }
                ],
                entryComponents: []
            }]
    }], function () { return [{ type: i2.UpgradeModule }]; }, null); })();
