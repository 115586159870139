"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
require("./dnaR-spinner.model");
const dnaR_spinner_mode_enum_1 = require("./enums/dnaR-spinner-mode.enum");
const i0 = require("@angular/core");
const i1 = require("@angular/material/progress-spinner");
class DnaRSpinner {
    constructor() {
        this.dnaRSpinnerMode = dnaR_spinner_mode_enum_1.DnaRSpinnerMode;
        this.defaultSettings = {
            value: 90,
            diameter: 20,
            mode: this.dnaRSpinnerMode.indeterminate,
            strokeWidth: 1.67
        };
    }
    ngOnChanges() {
        if (this.settings != null) {
            this.defaultSettings = {
                value: this.settings.value,
                diameter: this.settings.diameter,
                mode: this.settings.mode,
                strokeWidth: this.settings.strokeWidth
            };
        }
    }
}
exports.DnaRSpinner = DnaRSpinner;
DnaRSpinner.ɵfac = function DnaRSpinner_Factory(t) { return new (t || DnaRSpinner)(); };
DnaRSpinner.ɵcmp = i0.ɵɵdefineComponent({ type: DnaRSpinner, selectors: [["dnaR-spinner"]], inputs: { settings: "settings" }, features: [i0.ɵɵNgOnChangesFeature], decls: 1, vars: 4, consts: [[1, "spinner", 3, "value", "mode", "diameter", "strokeWidth"]], template: function DnaRSpinner_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelement(0, "mat-spinner", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("value", ctx.defaultSettings.value)("mode", ctx.defaultSettings.mode)("diameter", ctx.defaultSettings.diameter)("strokeWidth", ctx.defaultSettings.strokeWidth);
    } }, directives: [i1.MatSpinner], styles: [".spinner[_ngcontent-%COMP%] {\n  margin: 0 auto;\n  display: inline-block;\n  float: left;\n}\n[_nghost-%COMP%]     .mat-progress-spinner circle, .mat-spinner[_ngcontent-%COMP%]   circle[_ngcontent-%COMP%] {\n  stroke: #757575;\n}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DnaRSpinner, [{
        type: core_1.Component,
        args: [{
                selector: 'dnaR-spinner',
                templateUrl: './dnaR-spinner.component.html',
                styleUrls: ['./dnaR-spinner.component.less']
            }]
    }], function () { return []; }, { settings: [{
            type: core_1.Input
        }] }); })();
