"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const facility_dto_1 = require("../../../../dtos/facility-dto");
require("underscore");
require("../../../../../independent-living/dtos/tag-dto");
const i0 = require("@angular/core");
const i1 = require("@angular/common");
const i2 = require("./lead-tags-view-tab.component");
const i3 = require("./lead-tags-edit-tab.component");
function LeadTagsTabComponent_lead_tags_view_tab_1_Template(rf, ctx) { if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "lead-tags-view-tab", 3);
    i0.ɵɵlistener("onEdit", function LeadTagsTabComponent_lead_tags_view_tab_1_Template_lead_tags_view_tab_onEdit_0_listener() { i0.ɵɵrestoreView(_r3); const ctx_r2 = i0.ɵɵnextContext(); return ctx_r2.onEdit(); });
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("facility", ctx_r0.facility)("tags", ctx_r0.tags);
} }
function LeadTagsTabComponent_lead_tags_edit_tab_2_Template(rf, ctx) { if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "lead-tags-edit-tab", 4);
    i0.ɵɵlistener("onDone", function LeadTagsTabComponent_lead_tags_edit_tab_2_Template_lead_tags_edit_tab_onDone_0_listener($event) { i0.ɵɵrestoreView(_r5); const ctx_r4 = i0.ɵɵnextContext(); return ctx_r4.onEditComplete($event); });
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("facility", ctx_r1.facility)("tags", ctx_r1.tags);
} }
/**
 * Displays the lead tags tab.
 */
class LeadTagsTabComponent {
    /**
     * Initializes a new instance of the LeadTagsTabComponent class.
     */
    constructor() {
        /**
         * When true the tab is in edit mode, otherwise tab is in view mode.
         */
        this.isEditting = false;
    }
    /**
     * Switches to edit mode.
     */
    onEdit() {
        // Deep copy of tags to allow discarding of changes on cancel.
        this.editTags = new Array();
        this.editTags = Object.assign(this.editTags, this.tags);
        this.isEditting = true;
    }
    /**
     * Switches to view mode.
     *
     * @param cancelled true if editing was cancelled, otherwise false.
     */
    onEditComplete(cancelled) {
        if (!cancelled && this.editTags) {
            // Update facility if not cancelled.
            this.tags = this.editTags;
        }
        this.isEditting = false;
    }
}
exports.LeadTagsTabComponent = LeadTagsTabComponent;
LeadTagsTabComponent.ɵfac = function LeadTagsTabComponent_Factory(t) { return new (t || LeadTagsTabComponent)(); };
LeadTagsTabComponent.ɵcmp = i0.ɵɵdefineComponent({ type: LeadTagsTabComponent, selectors: [["lead-tags-tab"]], inputs: { facility: "facility", tags: "tags" }, decls: 3, vars: 2, consts: [[1, "lead-tags-tab", "vertical-tab"], [3, "facility", "tags", "onEdit", 4, "ngIf"], [3, "facility", "tags", "onDone", 4, "ngIf"], [3, "facility", "tags", "onEdit"], [3, "facility", "tags", "onDone"]], template: function LeadTagsTabComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, LeadTagsTabComponent_lead_tags_view_tab_1_Template, 1, 2, "lead-tags-view-tab", 1);
        i0.ɵɵtemplate(2, LeadTagsTabComponent_lead_tags_edit_tab_2_Template, 1, 2, "lead-tags-edit-tab", 2);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.isEditting);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.isEditting);
    } }, directives: [i1.NgIf, i2.LeadTagsViewTabComponent, i3.LeadTagsEditTabComponent], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(LeadTagsTabComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'lead-tags-tab',
                templateUrl: './lead-tags-tab.component.html'
            }]
    }], function () { return []; }, { facility: [{
            type: core_1.Input
        }], tags: [{
            type: core_1.Input
        }] }); })();
