"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const funding_service_1 = require("../services/funding.service");
const anacc_varaiable_component_dto_1 = require("../dtos/anacc-varaiable-component-dto");
const forms_1 = require("@angular/forms");
const i0 = require("@angular/core");
const i1 = require("../services/funding.service");
const i2 = require("@angular/forms");
const i3 = require("@angular/common");
const i4 = require("@angular/material/button-toggle");
const i5 = require("@angular/material/checkbox");
function AnaccCalculatorComponent_div_2_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵelementStart(2, "div", 6);
    i0.ɵɵelementStart(3, "div");
    i0.ɵɵelementStart(4, "label", 7);
    i0.ɵɵtext(5, "Entering as palliative care?");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "mat-button-toggle-group", 8);
    i0.ɵɵlistener("change", function AnaccCalculatorComponent_div_2_div_1_Template_mat_button_toggle_group_change_6_listener() { i0.ɵɵrestoreView(_r5); const ctx_r4 = i0.ɵɵnextContext(2); return ctx_r4.calculateUiStage(); });
    i0.ɵɵelementStart(7, "mat-button-toggle", 9);
    i0.ɵɵtext(8, "No");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "mat-button-toggle", 10);
    i0.ɵɵtext(10, "Yes");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function AnaccCalculatorComponent_div_2_div_2_div_15_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, " Mobility required ");
    i0.ɵɵelementEnd();
} }
function AnaccCalculatorComponent_div_2_div_2_div_15_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 18);
    i0.ɵɵtemplate(1, AnaccCalculatorComponent_div_2_div_2_div_15_div_1_Template, 2, 0, "div", 2);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r6.mobility == null ? null : ctx_r6.mobility.errors == null ? null : ctx_r6.mobility.errors.required);
} }
function AnaccCalculatorComponent_div_2_div_2_div_16_Template(rf, ctx) { if (rf & 1) {
    const _r16 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵelementStart(2, "div", 19);
    i0.ɵɵelementStart(3, "mat-checkbox", 20);
    i0.ɵɵlistener("change", function AnaccCalculatorComponent_div_2_div_2_div_16_Template_mat_checkbox_change_3_listener() { i0.ɵɵrestoreView(_r16); const ctx_r15 = i0.ɵɵnextContext(3); return ctx_r15.calculateUiStage(); });
    i0.ɵɵtext(4, "Compounding factors");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function AnaccCalculatorComponent_div_2_div_2_div_17_div_14_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, " Cognitive ability required ");
    i0.ɵɵelementEnd();
} }
function AnaccCalculatorComponent_div_2_div_2_div_17_div_14_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 18);
    i0.ɵɵtemplate(1, AnaccCalculatorComponent_div_2_div_2_div_17_div_14_div_1_Template, 2, 0, "div", 2);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r17 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r17.cognitiveAbility == null ? null : ctx_r17.cognitiveAbility.errors == null ? null : ctx_r17.cognitiveAbility.errors.required);
} }
function AnaccCalculatorComponent_div_2_div_2_div_17_Template(rf, ctx) { if (rf & 1) {
    const _r20 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵelementStart(2, "div", 6);
    i0.ɵɵelementStart(3, "label", 21);
    i0.ɵɵtext(4, "Cognitive ability");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "select", 22);
    i0.ɵɵlistener("change", function AnaccCalculatorComponent_div_2_div_2_div_17_Template_select_change_5_listener() { i0.ɵɵrestoreView(_r20); const ctx_r19 = i0.ɵɵnextContext(3); return ctx_r19.calculateUiStage(); });
    i0.ɵɵelementStart(6, "option", 13);
    i0.ɵɵtext(7, "Select cognitive ability");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "option", 23);
    i0.ɵɵtext(9, "High");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(10, "option", 24);
    i0.ɵɵtext(11, "Medium");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(12, "option", 25);
    i0.ɵɵtext(13, "Low");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(14, AnaccCalculatorComponent_div_2_div_2_div_17_div_14_Template, 2, 1, "div", 17);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r8 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(14);
    i0.ɵɵproperty("ngIf", ctx_r8.submitted || (ctx_r8.cognitiveAbility == null ? null : ctx_r8.cognitiveAbility.touched));
} }
function AnaccCalculatorComponent_div_2_div_2_div_18_Template(rf, ctx) { if (rf & 1) {
    const _r22 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵelementStart(2, "div", 19);
    i0.ɵɵelementStart(3, "div", 19);
    i0.ɵɵelementStart(4, "mat-checkbox", 26);
    i0.ɵɵlistener("change", function AnaccCalculatorComponent_div_2_div_2_div_18_Template_mat_checkbox_change_4_listener() { i0.ɵɵrestoreView(_r22); const ctx_r21 = i0.ɵɵnextContext(3); return ctx_r21.calculateUiStage(); });
    i0.ɵɵtext(5, "Compounding factors");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function AnaccCalculatorComponent_div_2_div_2_div_19_div_12_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, " Function required ");
    i0.ɵɵelementEnd();
} }
function AnaccCalculatorComponent_div_2_div_2_div_19_div_12_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 18);
    i0.ɵɵtemplate(1, AnaccCalculatorComponent_div_2_div_2_div_19_div_12_div_1_Template, 2, 0, "div", 2);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r23 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r23.function == null ? null : ctx_r23.function.errors == null ? null : ctx_r23.function.errors.required);
} }
function AnaccCalculatorComponent_div_2_div_2_div_19_Template(rf, ctx) { if (rf & 1) {
    const _r26 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵelementStart(2, "div", 6);
    i0.ɵɵelementStart(3, "label", 27);
    i0.ɵɵtext(4, "Function");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "select", 28);
    i0.ɵɵlistener("change", function AnaccCalculatorComponent_div_2_div_2_div_19_Template_select_change_5_listener() { i0.ɵɵrestoreView(_r26); const ctx_r25 = i0.ɵɵnextContext(3); return ctx_r25.calculateUiStage(); });
    i0.ɵɵelementStart(6, "option", 13);
    i0.ɵɵtext(7, "Select function");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "option", 23);
    i0.ɵɵtext(9, "High");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(10, "option", 25);
    i0.ɵɵtext(11, "Low");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(12, AnaccCalculatorComponent_div_2_div_2_div_19_div_12_Template, 2, 1, "div", 17);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r10 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(12);
    i0.ɵɵproperty("ngIf", ctx_r10.submitted || (ctx_r10.function == null ? null : ctx_r10.function.touched));
} }
function AnaccCalculatorComponent_div_2_div_2_div_20_Template(rf, ctx) { if (rf & 1) {
    const _r28 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵelementStart(2, "div", 19);
    i0.ɵɵelementStart(3, "mat-checkbox", 29);
    i0.ɵɵlistener("change", function AnaccCalculatorComponent_div_2_div_2_div_20_Template_mat_checkbox_change_3_listener() { i0.ɵɵrestoreView(_r28); const ctx_r27 = i0.ɵɵnextContext(3); return ctx_r27.calculateUiStage(); });
    i0.ɵɵtext(4, "Compounding factors");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function AnaccCalculatorComponent_div_2_div_2_div_21_div_12_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, " Pressure sore risk required ");
    i0.ɵɵelementEnd();
} }
function AnaccCalculatorComponent_div_2_div_2_div_21_div_12_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 18);
    i0.ɵɵtemplate(1, AnaccCalculatorComponent_div_2_div_2_div_21_div_12_div_1_Template, 2, 0, "div", 2);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r29 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r29.pressureSoreRisk == null ? null : ctx_r29.pressureSoreRisk.errors == null ? null : ctx_r29.pressureSoreRisk.errors.required);
} }
function AnaccCalculatorComponent_div_2_div_2_div_21_Template(rf, ctx) { if (rf & 1) {
    const _r32 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵelementStart(2, "div", 6);
    i0.ɵɵelementStart(3, "label", 30);
    i0.ɵɵtext(4, "Pressure sore risk");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "select", 31);
    i0.ɵɵlistener("change", function AnaccCalculatorComponent_div_2_div_2_div_21_Template_select_change_5_listener() { i0.ɵɵrestoreView(_r32); const ctx_r31 = i0.ɵɵnextContext(3); return ctx_r31.calculateUiStage(); });
    i0.ɵɵelementStart(6, "option", 13);
    i0.ɵɵtext(7, "Select pressure sore risk");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "option", 23);
    i0.ɵɵtext(9, "High");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(10, "option", 25);
    i0.ɵɵtext(11, "Low");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(12, AnaccCalculatorComponent_div_2_div_2_div_21_div_12_Template, 2, 1, "div", 17);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r12 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(12);
    i0.ɵɵproperty("ngIf", ctx_r12.submitted || (ctx_r12.pressureSoreRisk == null ? null : ctx_r12.pressureSoreRisk.touched));
} }
function AnaccCalculatorComponent_div_2_div_2_div_22_Template(rf, ctx) { if (rf & 1) {
    const _r34 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵelementStart(2, "div", 19);
    i0.ɵɵelementStart(3, "mat-checkbox", 32);
    i0.ɵɵlistener("change", function AnaccCalculatorComponent_div_2_div_2_div_22_Template_mat_checkbox_change_3_listener() { i0.ɵɵrestoreView(_r34); const ctx_r33 = i0.ɵɵnextContext(3); return ctx_r33.calculateUiStage(); });
    i0.ɵɵtext(4, "Compounding factors");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
function AnaccCalculatorComponent_div_2_div_2_Template(rf, ctx) { if (rf & 1) {
    const _r36 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "div", 4);
    i0.ɵɵelementStart(2, "div", 5);
    i0.ɵɵelementStart(3, "div", 6);
    i0.ɵɵelementStart(4, "label", 11);
    i0.ɵɵtext(5, "Mobility");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "select", 12);
    i0.ɵɵlistener("change", function AnaccCalculatorComponent_div_2_div_2_Template_select_change_6_listener() { i0.ɵɵrestoreView(_r36); const ctx_r35 = i0.ɵɵnextContext(2); return ctx_r35.calculateUiStage(); });
    i0.ɵɵelementStart(7, "option", 13);
    i0.ɵɵtext(8, "Select mobility");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "option", 14);
    i0.ɵɵtext(10, "Independent");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "option", 15);
    i0.ɵɵtext(12, "Assisted");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(13, "option", 16);
    i0.ɵɵtext(14, "Not mobile");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(15, AnaccCalculatorComponent_div_2_div_2_div_15_Template, 2, 1, "div", 17);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(16, AnaccCalculatorComponent_div_2_div_2_div_16_Template, 5, 0, "div", 3);
    i0.ɵɵtemplate(17, AnaccCalculatorComponent_div_2_div_2_div_17_Template, 15, 1, "div", 3);
    i0.ɵɵtemplate(18, AnaccCalculatorComponent_div_2_div_2_div_18_Template, 6, 0, "div", 3);
    i0.ɵɵtemplate(19, AnaccCalculatorComponent_div_2_div_2_div_19_Template, 13, 1, "div", 3);
    i0.ɵɵtemplate(20, AnaccCalculatorComponent_div_2_div_2_div_20_Template, 5, 0, "div", 3);
    i0.ɵɵtemplate(21, AnaccCalculatorComponent_div_2_div_2_div_21_Template, 13, 1, "div", 3);
    i0.ɵɵtemplate(22, AnaccCalculatorComponent_div_2_div_2_div_22_Template, 5, 0, "div", 3);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(15);
    i0.ɵɵproperty("ngIf", ctx_r3.submitted || (ctx_r3.mobility == null ? null : ctx_r3.mobility.touched));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r3.stage && ctx_r3.stage === 11);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r3.stage && ctx_r3.stage >= 20 && ctx_r3.stage < 30);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r3.stage && ctx_r3.stage == 21);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r3.stage && ctx_r3.stage >= 30 && ctx_r3.stage < 40);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r3.stage && ctx_r3.stage == 31);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r3.stage && ctx_r3.stage >= 32 && ctx_r3.stage < 40);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r3.stage && ctx_r3.stage == 33);
} }
function AnaccCalculatorComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, AnaccCalculatorComponent_div_2_div_1_Template, 11, 0, "div", 3);
    i0.ɵɵtemplate(2, AnaccCalculatorComponent_div_2_div_2_Template, 23, 8, "div", 2);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.skipPalliativeQuestion);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.stage && ctx_r0.stage >= 10 && ctx_r0.stage < 100);
} }
function AnaccCalculatorComponent_div_3_div_15_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, " Mobility required ");
    i0.ɵɵelementEnd();
} }
function AnaccCalculatorComponent_div_3_div_15_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 18);
    i0.ɵɵtemplate(1, AnaccCalculatorComponent_div_3_div_15_div_1_Template, 2, 0, "div", 2);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r37 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r37.respiteMobility == null ? null : ctx_r37.respiteMobility.errors == null ? null : ctx_r37.respiteMobility.errors.required);
} }
function AnaccCalculatorComponent_div_3_Template(rf, ctx) { if (rf & 1) {
    const _r40 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "div", 4);
    i0.ɵɵelementStart(2, "div", 5);
    i0.ɵɵelementStart(3, "div", 6);
    i0.ɵɵelementStart(4, "label", 33);
    i0.ɵɵtext(5, "Mobility");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "select", 34);
    i0.ɵɵlistener("change", function AnaccCalculatorComponent_div_3_Template_select_change_6_listener() { i0.ɵɵrestoreView(_r40); const ctx_r39 = i0.ɵɵnextContext(); return ctx_r39.calculateUiStage(); });
    i0.ɵɵelementStart(7, "option", 13);
    i0.ɵɵtext(8, "Select mobility");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "option", 14);
    i0.ɵɵtext(10, "Independent");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "option", 15);
    i0.ɵɵtext(12, "Assisted");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(13, "option", 35);
    i0.ɵɵtext(14, "Limited");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(15, AnaccCalculatorComponent_div_3_div_15_Template, 2, 1, "div", 17);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(15);
    i0.ɵɵproperty("ngIf", ctx_r1.submitted || (ctx_r1.respiteMobility == null ? null : ctx_r1.respiteMobility.touched));
} }
/**
 * The famed AN-ACC Class Calculator.
 */
class AnaccCalculatorComponent {
    /**
     * Initializes a new instance of the AnaccCalculatorComponent class.
     *
     * @param fundingService - The funding service.
     */
    constructor(fundingService, fb) {
        this.fundingService = fundingService;
        this.fb = fb;
        /**
         * Should the palliative question be skipped?.
         *
         * @remarks
         * Set true to skip palliative question, otherwise palliative question will not be skipped.
         */
        this.skipPalliativeQuestion = false;
        /**
         * Sumbitted state of the containing control.
         *
         * @remarks
         * In Resman showing validation messages relies in part a form's sumbitted state.
         */
        this.submitted = false;
        /**
         * Is the component loading.
         */
        this.isLoading = true;
        this.form = this.fb.group({
            palliativeOnEntry: ["", forms_1.Validators.required],
            mobility: ["", forms_1.Validators.required],
            mobilityCompoundingFactors: [""],
            cognitiveAbility: ["", forms_1.Validators.required],
            cognitiveAbilityCompoundingFactors: [""],
            function: ["", forms_1.Validators.required],
            functionCompoundingFactors: [""],
            pressureSoreRisk: ["", forms_1.Validators.required],
            pressureSoreRiskCompoundingFactors: [""],
            respiteMobility: ["", forms_1.Validators.required],
        });
        this.onTouched = () => { };
        /**
         * Set the default form values for current AN-ACC variable component.
         *
         * @param anaccClass The AN-ACC class.
         */
        this.setDefaultForAnaccClass = (anaccClass) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28;
            (_a = this.palliativeOnEntry) === null || _a === void 0 ? void 0 : _a.setValue("");
            (_b = this.mobility) === null || _b === void 0 ? void 0 : _b.setValue('');
            this.mobilityCompoundingFactors.setValue(false);
            (_c = this.cognitiveAbility) === null || _c === void 0 ? void 0 : _c.setValue('');
            (_d = this.cognitiveAbilityCompoundingFactors) === null || _d === void 0 ? void 0 : _d.setValue(false);
            (_e = this.function) === null || _e === void 0 ? void 0 : _e.setValue('');
            (_f = this.functionCompoundingFactors) === null || _f === void 0 ? void 0 : _f.setValue(false);
            (_g = this.pressureSoreRisk) === null || _g === void 0 ? void 0 : _g.setValue('');
            (_h = this.pressureSoreRiskCompoundingFactors) === null || _h === void 0 ? void 0 : _h.setValue(false);
            (_j = this.respiteMobility) === null || _j === void 0 ? void 0 : _j.setValue('');
            switch (anaccClass) {
                case "1":
                    (_k = this.palliativeOnEntry) === null || _k === void 0 ? void 0 : _k.setValue("Yes");
                    break;
                case "2":
                    (_l = this.palliativeOnEntry) === null || _l === void 0 ? void 0 : _l.setValue("No");
                    (_m = this.mobility) === null || _m === void 0 ? void 0 : _m.setValue("Independent");
                    break;
                case "3":
                    (_o = this.palliativeOnEntry) === null || _o === void 0 ? void 0 : _o.setValue("No");
                    (_p = this.mobility) === null || _p === void 0 ? void 0 : _p.setValue("Independent");
                    this.mobilityCompoundingFactors.setValue(true);
                    break;
                case "4":
                    (_q = this.palliativeOnEntry) === null || _q === void 0 ? void 0 : _q.setValue("No");
                    (_r = this.mobility) === null || _r === void 0 ? void 0 : _r.setValue("Assisted");
                    (_s = this.cognitiveAbility) === null || _s === void 0 ? void 0 : _s.setValue("High");
                    break;
                case "5":
                    (_t = this.palliativeOnEntry) === null || _t === void 0 ? void 0 : _t.setValue("No");
                    (_u = this.mobility) === null || _u === void 0 ? void 0 : _u.setValue("Assisted");
                    (_v = this.cognitiveAbility) === null || _v === void 0 ? void 0 : _v.setValue("High");
                    (_w = this.cognitiveAbilityCompoundingFactors) === null || _w === void 0 ? void 0 : _w.setValue(true);
                    break;
                case "6":
                    (_x = this.palliativeOnEntry) === null || _x === void 0 ? void 0 : _x.setValue("No");
                    (_y = this.mobility) === null || _y === void 0 ? void 0 : _y.setValue("Assisted");
                    (_z = this.cognitiveAbility) === null || _z === void 0 ? void 0 : _z.setValue("Medium");
                    break;
                case "7":
                    (_0 = this.palliativeOnEntry) === null || _0 === void 0 ? void 0 : _0.setValue("No");
                    (_1 = this.mobility) === null || _1 === void 0 ? void 0 : _1.setValue("Assisted");
                    (_2 = this.cognitiveAbility) === null || _2 === void 0 ? void 0 : _2.setValue("Medium");
                    this.cognitiveAbilityCompoundingFactors.setValue(true);
                    break;
                case "8":
                    (_3 = this.palliativeOnEntry) === null || _3 === void 0 ? void 0 : _3.setValue("No");
                    (_4 = this.mobility) === null || _4 === void 0 ? void 0 : _4.setValue("Assisted");
                    (_5 = this.cognitiveAbility) === null || _5 === void 0 ? void 0 : _5.setValue("Low");
                    break;
                case "9":
                    (_6 = this.palliativeOnEntry) === null || _6 === void 0 ? void 0 : _6.setValue("No");
                    (_7 = this.mobility) === null || _7 === void 0 ? void 0 : _7.setValue("Not mobile");
                    (_8 = this.function) === null || _8 === void 0 ? void 0 : _8.setValue("High");
                    break;
                case "10":
                    (_9 = this.palliativeOnEntry) === null || _9 === void 0 ? void 0 : _9.setValue("No");
                    (_10 = this.mobility) === null || _10 === void 0 ? void 0 : _10.setValue("Not mobile");
                    (_11 = this.function) === null || _11 === void 0 ? void 0 : _11.setValue("High");
                    (_12 = this.functionCompoundingFactors) === null || _12 === void 0 ? void 0 : _12.setValue(true);
                    break;
                case "11":
                    (_13 = this.palliativeOnEntry) === null || _13 === void 0 ? void 0 : _13.setValue("No");
                    (_14 = this.mobility) === null || _14 === void 0 ? void 0 : _14.setValue("Not mobile");
                    (_15 = this.function) === null || _15 === void 0 ? void 0 : _15.setValue("Low");
                    (_16 = this.pressureSoreRisk) === null || _16 === void 0 ? void 0 : _16.setValue("Low");
                    break;
                case "12":
                    (_17 = this.palliativeOnEntry) === null || _17 === void 0 ? void 0 : _17.setValue("No");
                    (_18 = this.mobility) === null || _18 === void 0 ? void 0 : _18.setValue("Not mobile");
                    (_19 = this.function) === null || _19 === void 0 ? void 0 : _19.setValue("Low");
                    (_20 = this.pressureSoreRisk) === null || _20 === void 0 ? void 0 : _20.setValue("High");
                    break;
                case "13":
                    (_21 = this.palliativeOnEntry) === null || _21 === void 0 ? void 0 : _21.setValue("No");
                    (_22 = this.mobility) === null || _22 === void 0 ? void 0 : _22.setValue("Not mobile");
                    (_23 = this.function) === null || _23 === void 0 ? void 0 : _23.setValue("Low");
                    (_24 = this.pressureSoreRisk) === null || _24 === void 0 ? void 0 : _24.setValue("High");
                    (_25 = this.pressureSoreRiskCompoundingFactors) === null || _25 === void 0 ? void 0 : _25.setValue(true);
                    break;
                case "101":
                    (_26 = this.respiteMobility) === null || _26 === void 0 ? void 0 : _26.setValue('Independent');
                    break;
                case "102":
                    (_27 = this.respiteMobility) === null || _27 === void 0 ? void 0 : _27.setValue('Assisted');
                    break;
                case "103":
                    (_28 = this.respiteMobility) === null || _28 === void 0 ? void 0 : _28.setValue('Limited');
                    break;
            }
        };
        /**
         * Calculates the current UI stage, and AN-ACC Variable component, for the current AN-ACC calculator state.
         * The calculated AN-ACC Variable component value will be emit via the anaccVariableComponentChanged event.
         */
        this.calculateUiStage = () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
            if (this.careType === "Permanent") {
                this.stage = 1;
                this.updateAnaccVariableComponent(null);
                if (this.skipPalliativeQuestion || ((_a = this.palliativeOnEntry) === null || _a === void 0 ? void 0 : _a.value) === "No" || ((_b = this.palliativeOnEntry) === null || _b === void 0 ? void 0 : _b.value) === "Yes") {
                    if (((_c = this.palliativeOnEntry) === null || _c === void 0 ? void 0 : _c.value) === "Yes") {
                        this.stage = 1;
                        this.updateAnaccVariableComponent("1");
                    }
                    else {
                        this.stage = 10;
                        if (((_d = this.mobility) === null || _d === void 0 ? void 0 : _d.value) === "Independent") {
                            this.stage = 11;
                            if ((_e = this.mobilityCompoundingFactors) === null || _e === void 0 ? void 0 : _e.value) {
                                this.updateAnaccVariableComponent("3");
                            }
                            else {
                                this.updateAnaccVariableComponent("2");
                            }
                        }
                        else if (((_f = this.mobility) === null || _f === void 0 ? void 0 : _f.value) === "Assisted") {
                            this.stage = 20;
                            if (((_g = this.cognitiveAbility) === null || _g === void 0 ? void 0 : _g.value) === "High") {
                                this.stage = 21;
                                if ((_h = this.cognitiveAbilityCompoundingFactors) === null || _h === void 0 ? void 0 : _h.value) {
                                    this.updateAnaccVariableComponent("5");
                                }
                                else {
                                    this.updateAnaccVariableComponent("4");
                                }
                            }
                            else if (((_j = this.cognitiveAbility) === null || _j === void 0 ? void 0 : _j.value) === "Medium") {
                                this.stage = 21;
                                if ((_k = this.cognitiveAbilityCompoundingFactors) === null || _k === void 0 ? void 0 : _k.value) {
                                    this.updateAnaccVariableComponent("7");
                                }
                                else {
                                    this.updateAnaccVariableComponent("6");
                                }
                            }
                            else if (((_l = this.cognitiveAbility) === null || _l === void 0 ? void 0 : _l.value) === "Low") {
                                this.updateAnaccVariableComponent("8");
                            }
                        }
                        else if (((_m = this.mobility) === null || _m === void 0 ? void 0 : _m.value) === "Not mobile") {
                            this.stage = 30;
                            if (((_o = this.function) === null || _o === void 0 ? void 0 : _o.value) === "High") {
                                this.stage = 31;
                                if ((_p = this.functionCompoundingFactors) === null || _p === void 0 ? void 0 : _p.value) {
                                    this.updateAnaccVariableComponent("10");
                                }
                                else {
                                    this.updateAnaccVariableComponent("9");
                                }
                            }
                            else if (((_q = this.function) === null || _q === void 0 ? void 0 : _q.value) === "Low") {
                                this.stage = 32;
                                if (((_r = this.pressureSoreRisk) === null || _r === void 0 ? void 0 : _r.value) === "High") {
                                    this.stage = 33;
                                    if ((_s = this.pressureSoreRiskCompoundingFactors) === null || _s === void 0 ? void 0 : _s.value) {
                                        this.updateAnaccVariableComponent("13");
                                    }
                                    else {
                                        this.updateAnaccVariableComponent("12");
                                    }
                                }
                                else if (((_t = this.pressureSoreRisk) === null || _t === void 0 ? void 0 : _t.value) === "Low") {
                                    this.updateAnaccVariableComponent("11");
                                }
                            }
                        }
                    }
                }
            }
            else if (this.careType === "Respite") {
                this.stage = 100;
                if (((_u = this.respiteMobility) === null || _u === void 0 ? void 0 : _u.value) === "Independent") {
                    this.updateAnaccVariableComponent("101");
                }
                else if (((_v = this.respiteMobility) === null || _v === void 0 ? void 0 : _v.value) === "Assisted") {
                    this.updateAnaccVariableComponent("102");
                }
                else if (((_w = this.respiteMobility) === null || _w === void 0 ? void 0 : _w.value) === "Limited") {
                    this.updateAnaccVariableComponent("103");
                }
            }
        };
        this.stage = 0;
        this.anaccVariableComponentChanged = new core_1.EventEmitter();
    }
    /**
     * Whether the AN-ACC calculator is disabled.
     */
    get disabled() {
        return this.form.disabled;
    }
    set disabled(value) {
        if (value) {
            this.form.disable();
        }
        else {
            this.form.enable();
        }
    }
    get palliativeOnEntry() {
        return this.form.get("palliativeOnEntry");
    }
    get mobility() {
        return this.form.get("mobility");
    }
    get mobilityCompoundingFactors() {
        return this.form.get("mobilityCompoundingFactors");
    }
    get cognitiveAbility() {
        return this.form.get("cognitiveAbility");
    }
    get cognitiveAbilityCompoundingFactors() {
        return this.form.get("cognitiveAbilityCompoundingFactors");
    }
    get function() {
        return this.form.get("function");
    }
    get functionCompoundingFactors() {
        return this.form.get("functionCompoundingFactors");
    }
    get pressureSoreRisk() {
        return this.form.get("pressureSoreRisk");
    }
    get pressureSoreRiskCompoundingFactors() {
        return this.form.get("pressureSoreRiskCompoundingFactors");
    }
    get respiteMobility() {
        return this.form.get("respiteMobility");
    }
    /**
     * @inheritDoc
     */
    ngOnInit() {
        this.isLoading = true;
        this.fundingService.getAnaccVariableComponents(true).then(result => {
            var _a;
            this.isLoading = false;
            this.anaccClasses = result;
            this.setDefaultForAnaccClass((_a = this.anaccVariableComponent) === null || _a === void 0 ? void 0 : _a.anaccClass);
            this.calculateUiStage();
        });
    }
    /**
     * @inheritDoc
     */
    ngOnChanges(changes) {
        var _a, _b, _c;
        if (((_b = (_a = changes === null || changes === void 0 ? void 0 : changes.anaccVariableComponent) === null || _a === void 0 ? void 0 : _a.currentValue) === null || _b === void 0 ? void 0 : _b.anaccClass) == ((_c = this.anaccVariableComponent) === null || _c === void 0 ? void 0 : _c.anaccClass)) {
            // Avoid the posibility of entering an infinite loops
            return;
        }
        if (changes.anaccVariableComponent && this.anaccVariableComponent) {
            this.setDefaultForAnaccClass(this.anaccVariableComponent.anaccClass);
        }
        this.calculateUiStage();
    }
    /**
     * Emits the anaccVariableComponentChanged event.
     *
     * @param anaccClass - The AN-ACC class value
     *
     * @remarks
     * This will lookup the AN-ACC Variable component for the provided AN-ACC class to include in the anaccVariableComponentChanged event.
     * If anaccClass is null or undefined then it will emit the anaccVariableComponentChanged with a null value.
     */
    updateAnaccVariableComponent(anaccClass) {
        var _a, _b;
        let emitAnaccVariableComponent = null;
        if (anaccClass) {
            emitAnaccVariableComponent = (_b = (_a = this.anaccClasses) === null || _a === void 0 ? void 0 : _a.find((i) => i.anaccClass == anaccClass)) !== null && _b !== void 0 ? _b : new anacc_varaiable_component_dto_1.AnaccVariableComponentDto({ anaccClass });
        }
        if (!this.isLoading) {
            // Only emit changes only after the component has finished loading.
            // Otherwise can get into a loop.
            this.anaccVariableComponentChanged.emit(emitAnaccVariableComponent);
        }
    }
    /**
     * @inheritDoc
     */
    validate(control) {
        if (this.form.valid) {
            return null;
        }
        return { invalidForm: { valid: false, message: "AN-ACC calculator is invalid" } };
    }
    /**
     * @inheritDoc
     */
    writeValue(val) {
        if (val) {
            this.form.setValue(val, { emitEvent: false });
        }
    }
    /**
     * @inheritDoc
     */
    registerOnChange(fn) {
        this.form.valueChanges.subscribe(fn);
    }
    /**
     * @inheritDoc
     */
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    /**
     * @inheritDoc
     */
    setDisabledState(isDisabled) {
        this.disabled = isDisabled;
    }
}
exports.AnaccCalculatorComponent = AnaccCalculatorComponent;
AnaccCalculatorComponent.ɵfac = function AnaccCalculatorComponent_Factory(t) { return new (t || AnaccCalculatorComponent)(i0.ɵɵdirectiveInject(i1.FundingService), i0.ɵɵdirectiveInject(i2.FormBuilder)); };
AnaccCalculatorComponent.ɵcmp = i0.ɵɵdefineComponent({ type: AnaccCalculatorComponent, selectors: [["anacc-calculator"]], inputs: { careType: "careType", anaccVariableComponent: "anaccVariableComponent", skipPalliativeQuestion: "skipPalliativeQuestion", submitted: "submitted", disabled: "disabled" }, outputs: { anaccVariableComponentChanged: "anaccVariableComponentChanged" }, features: [i0.ɵɵProvidersFeature([
            {
                provide: forms_1.NG_VALUE_ACCESSOR,
                useExisting: core_1.forwardRef(() => AnaccCalculatorComponent),
                multi: true,
            },
            {
                provide: forms_1.NG_VALIDATORS,
                useExisting: core_1.forwardRef(() => AnaccCalculatorComponent),
                multi: true,
            },
        ]), i0.ɵɵNgOnChangesFeature], decls: 4, vars: 3, consts: [[1, "anacc-calculator"], [3, "formGroup"], [4, "ngIf"], ["class", "row", 4, "ngIf"], [1, "row"], [1, "col-xs-12"], [1, "form-group"], [1, "control-label"], ["formControlName", "palliativeOnEntry", 3, "change"], ["value", "No"], ["value", "Yes"], ["for", "mobility", 1, "control-label"], ["id", "mobility", "formControlName", "mobility", 1, "form-control", "input-lg", 3, "change"], ["value", "", "disabled", "", "selected", ""], ["value", "Independent"], ["value", "Assisted"], ["value", "Not mobile"], ["class", "text-danger", 4, "ngIf"], [1, "text-danger"], [1, "form-check"], ["formControlName", "mobilityCompoundingFactors", 3, "change"], ["for", "cognitiveAbility", 1, "control-label"], ["formControlName", "cognitiveAbility", "id", "cognitiveAbility", 1, "form-control", "input-lg", 3, "change"], ["value", "High"], ["value", "Medium"], ["value", "Low"], ["formControlName", "cognitiveAbilityCompoundingFactors", 3, "change"], ["for", "function", 1, "control-label"], ["formControlName", "function", "id", "function", 1, "form-control", "input-lg", 3, "change"], ["formControlName", "functionCompoundingFactors", 3, "change"], ["for", "pressureSoreRisk", 1, "control-label"], ["formControlName", "pressureSoreRisk", "id", "pressureSoreRisk", 1, "form-control", "input-lg", 3, "change"], ["formControlName", "pressureSoreRiskCompoundingFactors", 3, "change"], ["for", "respiteMobility", 1, "control-label"], ["formControlName", "respiteMobility", "id", "respiteMobility", 1, "form-control", "input-lg", 3, "change"], ["value", "Limited"]], template: function AnaccCalculatorComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵtemplate(2, AnaccCalculatorComponent_div_2_Template, 3, 2, "div", 2);
        i0.ɵɵtemplate(3, AnaccCalculatorComponent_div_3_Template, 16, 1, "div", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("formGroup", ctx.form);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.stage && ctx.stage > 0 && ctx.stage < 100);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.stage && ctx.stage >= 100 && ctx.stage < 200);
    } }, directives: [i2.NgControlStatusGroup, i2.FormGroupDirective, i3.NgIf, i4.MatButtonToggleGroup, i2.NgControlStatus, i2.FormControlName, i4.MatButtonToggle, i2.SelectControlValueAccessor, i2.NgSelectOption, i2.ɵangular_packages_forms_forms_x, i5.MatCheckbox], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AnaccCalculatorComponent, [{
        type: core_1.Component,
        args: [{
                selector: "anacc-calculator",
                templateUrl: "./anacc-calculator.component.html",
                providers: [
                    {
                        provide: forms_1.NG_VALUE_ACCESSOR,
                        useExisting: core_1.forwardRef(() => AnaccCalculatorComponent),
                        multi: true,
                    },
                    {
                        provide: forms_1.NG_VALIDATORS,
                        useExisting: core_1.forwardRef(() => AnaccCalculatorComponent),
                        multi: true,
                    },
                ],
            }]
    }], function () { return [{ type: i1.FundingService }, { type: i2.FormBuilder }]; }, { careType: [{
            type: core_1.Input
        }], anaccVariableComponent: [{
            type: core_1.Input
        }], skipPalliativeQuestion: [{
            type: core_1.Input
        }], submitted: [{
            type: core_1.Input
        }], disabled: [{
            type: core_1.Input
        }], anaccVariableComponentChanged: [{
            type: core_1.Output
        }] }); })();
