"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const ILU_status_enum_1 = require("../../enums/ILU-status.enum");
const independent_living_service_1 = require("../../services/independent-living.service");
const dnaRSnackBar_service_1 = require("../../../common/dna-r/dnaRSnackBar.service");
const rxjs_1 = require("rxjs");
require("../dto/tags-save-dto");
const tag_enum_1 = require("../../enums/tag.enum");
require("../../dtos/tag-dto");
const i0 = require("@angular/core");
const i1 = require("../../services/independent-living.service");
const i2 = require("../../../common/dna-r/dnaRSnackBar.service");
const i3 = require("@angular/common");
const i4 = require("@angular/material/button-toggle");
const i5 = require("../../../common/components/dnaR-dropdown-multiSelect/dnaR-dropdown-multiSelect.component");
function ILUStatusComponent_a_7_Template(rf, ctx) { if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 9);
    i0.ɵɵlistener("click", function ILUStatusComponent_a_7_Template_a_click_0_listener() { i0.ɵɵrestoreView(_r5); const ctx_r4 = i0.ɵɵnextContext(); return ctx_r4.editClick(); });
    i0.ɵɵtext(1, "Edit");
    i0.ɵɵelementEnd();
} }
function ILUStatusComponent_div_9_Template(rf, ctx) { if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 10);
    i0.ɵɵelementStart(1, "div", 11);
    i0.ɵɵelementStart(2, "mat-button-toggle-group", 12, 13);
    i0.ɵɵlistener("change", function ILUStatusComponent_div_9_Template_mat_button_toggle_group_change_2_listener() { i0.ɵɵrestoreView(_r8); const _r6 = i0.ɵɵreference(3); const ctx_r7 = i0.ɵɵnextContext(); return ctx_r7.iluStatusChange(_r6.value); });
    i0.ɵɵelementStart(4, "mat-button-toggle", 14);
    i0.ɵɵtext(5, "Lead");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "mat-button-toggle", 15);
    i0.ɵɵtext(7, "Waiting");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "mat-button-toggle", 16);
    i0.ɵɵtext(9, "Confirmed");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(10, "div", 17);
    i0.ɵɵelementStart(11, "div", 18);
    i0.ɵɵelementStart(12, "label", 19);
    i0.ɵɵtext(13, "Tags");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(14, "div", 20);
    i0.ɵɵelementStart(15, "dnaR-dropdown-multiSelect", 21);
    i0.ɵɵlistener("onSelectionChange", function ILUStatusComponent_div_9_Template_dnaR_dropdown_multiSelect_onSelectionChange_15_listener($event) { i0.ɵɵrestoreView(_r8); const ctx_r9 = i0.ɵɵnextContext(); return ctx_r9.tagsSelectionChanged($event); });
    i0.ɵɵpipe(16, "async");
    i0.ɵɵpipe(17, "async");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(18, "div", 22);
    i0.ɵɵelement(19, "div", 23);
    i0.ɵɵelementStart(20, "div", 24);
    i0.ɵɵelementStart(21, "button", 25);
    i0.ɵɵlistener("click", function ILUStatusComponent_div_9_Template_button_click_21_listener() { i0.ɵɵrestoreView(_r8); const ctx_r10 = i0.ɵɵnextContext(); return ctx_r10.cancelClick(); });
    i0.ɵɵtext(22, "Cancel");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(23, "div", 26);
    i0.ɵɵelementStart(24, "button", 27);
    i0.ɵɵlistener("click", function ILUStatusComponent_div_9_Template_button_click_24_listener() { i0.ɵɵrestoreView(_r8); const ctx_r11 = i0.ɵɵnextContext(); return ctx_r11.saveClick(); });
    i0.ɵɵtext(25, "Save");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("value", ctx_r1.selectedIlutatus);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("value", ctx_r1.ILUStatusEnum.Lead);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("value", ctx_r1.ILUStatusEnum.Waiting);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("value", ctx_r1.ILUStatusEnum.Confirmed);
    i0.ɵɵadvance(7);
    i0.ɵɵproperty("placeholder", "Select tag/s")("settings", ctx_r1.dropdownSettings)("data", i0.ɵɵpipeBind1(16, 8, ctx_r1.TagsList$))("initialSelectedItems", i0.ɵɵpipeBind1(17, 10, ctx_r1.InitialSelectedTagIds$));
} }
function ILUStatusComponent_div_10_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 28);
    i0.ɵɵelement(1, "span", 29);
    i0.ɵɵelementStart(2, "span", 30);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r2.getIluStatusText());
} }
function ILUStatusComponent_div_11_span_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 33);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const item_r13 = ctx.$implicit;
    i0.ɵɵproperty("ngClass", "label-badge label-badge-enquiry-status isCustom-" + item_r13.isCustom);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(item_r13.name);
} }
function ILUStatusComponent_div_11_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 31);
    i0.ɵɵtemplate(1, ILUStatusComponent_div_11_span_1_Template, 2, 2, "span", 32);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(2, 1, ctx_r3.CurrentTags$));
} }
class ILUStatusComponent {
    constructor(independentLivingService, snackBarService, authenticationService) {
        this.independentLivingService = independentLivingService;
        this.snackBarService = snackBarService;
        this.authenticationService = authenticationService;
        this.isEditMode = false;
        this.ILUStatusEnum = ILU_status_enum_1.ILUStatusEnum;
        this.isReadOnly = false;
        this.dropdownSettings = {
            singleSelection: false,
            headerField: 'header',
            idField: 'id',
            textField: 'name',
            itemsShowLimit: 1,
            maxHeight: 500,
            addAllOption: false,
            enableCheckAll: false
        };
        /* Since we are loading the below from DB then we use observable / BehaviorSubject as we need to wait for the load */
        /* Set initial value to null */
        this.TagsList$ = new rxjs_1.BehaviorSubject(null);
        this.InitialSelectedTagIds$ = new rxjs_1.BehaviorSubject(null);
        this.CurrentTags$ = new rxjs_1.BehaviorSubject([]);
        this.setIsReadOnly = () => {
            this.authenticationService.userHasPermissionDeferred((x) => {
                return x.leadsDetails.canModify;
            }).then(() => {
                this.isReadOnly = this.isReadOnlyComponent ? true : false;
            }, () => {
                this.isReadOnly = true;
            });
        };
    }
    ngOnInit() {
        this.loadSelectedTags();
    }
    ngOnChanges() {
        this.setIsReadOnly();
        this.updatedResidencyId = this.residencyId;
        switch (this.currentIluStatus) {
            case ILU_status_enum_1.ILUStatusEnum.Confirmed:
                this.selectedIlutatus = ILU_status_enum_1.ILUStatusEnum.Confirmed;
                break;
            case ILU_status_enum_1.ILUStatusEnum.Waiting:
                this.selectedIlutatus = ILU_status_enum_1.ILUStatusEnum.Waiting;
                break;
            default:
                this.selectedIlutatus = ILU_status_enum_1.ILUStatusEnum.Lead;
        }
        ;
        this.getCurrentTags();
    }
    getIluStatusText() {
        return ILU_status_enum_1.ILUStatusEnum[this.selectedIlutatus];
    }
    iluStatusChange(selectedIlutatus) {
        this.selectedIlutatus = selectedIlutatus;
    }
    editClick() {
        this.isEditMode = true;
    }
    cancelClick() {
        this.isEditMode = false;
        this.selectedIlutatus = this.currentIluStatus;
        this.getCurrentTags();
    }
    saveClick() {
        let tagsSaveDto = {
            ResidencyId: this.residencyId,
            SelectedTags: this.InitialSelectedTagIds$.value
        };
        /*check if there is a unit assigned to this enquiry and if so then include the
        /*Unit assigned tag as this is not a selectable option but should be displayed
        /*in view mode so without this the Unit assigned tag would not be included on save: */
        this.independentLivingService.getIlaTags(this.residencyId).then((result) => {
            const unitAssignedIndex = result.map(i => i.name).indexOf(tag_enum_1.TagEnum.UnitAssigned);
            if (unitAssignedIndex > -1) {
                tagsSaveDto.SelectedTags.push(result[unitAssignedIndex].id);
            }
        }, () => {
            this.snackBarService.failure('Status couldn\'t be saved');
        }).then(() => {
            this.independentLivingService.updatePreAdmissionIluStatus(this.updatedResidencyId, this.selectedIlutatus).then(() => {
                this.independentLivingService.saveIlaTags(tagsSaveDto).then(() => {
                    this.snackBarService.success('Status saved');
                    this.getCurrentTags();
                    //this is required to update tags shown in the tags dropdown, particularly if a hidden tags is unselected
                    //so that it's not shown in the dropdown after saving
                    this.loadSelectedTags();
                    this.isEditMode = false;
                }, () => {
                    this.snackBarService.failure('Status couldn\'t be saved');
                });
            }, () => {
                this.snackBarService.failure('Status couldn\'t be saved');
            });
        });
    }
    removeUnitAssignedTag(tags) {
        //remove Unit assigned from list of tag options as this is not user selectable:
        const index = tags.map(i => i.name).indexOf(tag_enum_1.TagEnum.UnitAssigned);
        if (index > -1) {
            tags.splice(index, 1);
        }
        return tags;
    }
    loadSelectedTags() {
        this.independentLivingService.getAllTagsForFacility().then((result) => {
            this.independentLivingService.getIlaTags(this.residencyId).then((currentTags) => {
                //Only load tags that are not hidden or that are hidden and assigned to this enquiry:
                result = result.filter(x => !x.isHidden || currentTags.find(t => t.id == x.id) != undefined);
                result = this.removeUnitAssignedTag(result);
                let tags = [];
                tags = this.setTagsHeader(result);
                this.TagsList$.next(tags);
            });
        });
    }
    tagsSelectionChanged(selectedItems) {
        var SelectedTagIds = [];
        selectedItems.forEach(item => { SelectedTagIds.push(item.id); });
        this.InitialSelectedTagIds$.next(SelectedTagIds);
    }
    getCurrentTags() {
        //get all tags for the ILA:
        this.independentLivingService.getIlaTags(this.residencyId).then((result) => __awaiter(this, void 0, void 0, function* () {
            let tags = [];
            tags = this.setTagsHeader(result);
            yield this.setInitialSelectedTags(tags);
            //populate tags shown in status view mode:
            const contractProvidedIndex = tags.map(i => i.name).indexOf(tag_enum_1.TagEnum.ContractProvided);
            const contractSignedIndex = tags.map(i => i.name).indexOf(tag_enum_1.TagEnum.ContractSigned);
            if (contractProvidedIndex > -1 && contractSignedIndex > -1) {
                tags.splice(contractProvidedIndex, 1);
            }
            this.CurrentTags$.next(tags);
        }));
    }
    /* Format tags with appropriate header to be used in dropdown: */
    setTagsHeader(unorderedTags) {
        let tags = [];
        unorderedTags.forEach(tag => {
            tags.push({ header: tag.isCustom ? 'Custom' : '', id: tag.id, name: tag.name, isDeleted: tag.isDeleted, isCustom: tag.isCustom });
        });
        return tags;
    }
    /*populate only user selected tags (i.e. exclude unit assigned tag if applicable)
    /*shown in tags selection dropdown: */
    setInitialSelectedTags(currentTags) {
        let updatedCurrentTags = [];
        this.InitialSelectedTagIds$ = new rxjs_1.BehaviorSubject(null);
        currentTags.forEach(currentTag => {
            updatedCurrentTags.push(currentTag);
        });
        updatedCurrentTags = this.removeUnitAssignedTag(updatedCurrentTags);
        var selectedTagIds = [];
        updatedCurrentTags.forEach(currentTag => {
            selectedTagIds.push(currentTag.id);
        });
        this.InitialSelectedTagIds$.next(selectedTagIds);
    }
}
exports.ILUStatusComponent = ILUStatusComponent;
ILUStatusComponent.ɵfac = function ILUStatusComponent_Factory(t) { return new (t || ILUStatusComponent)(i0.ɵɵdirectiveInject(i1.IndependentLivingService), i0.ɵɵdirectiveInject(i2.DnaRSnackBarService), i0.ɵɵdirectiveInject('authentication.service')); };
ILUStatusComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ILUStatusComponent, selectors: [["ilu-status"]], inputs: { residencyId: "residencyId", currentIluStatus: "currentIluStatus", isReadOnlyComponent: "isReadOnlyComponent" }, features: [i0.ɵɵNgOnChangesFeature], decls: 12, vars: 4, consts: [[1, "lead-status"], [1, "section-top"], [1, "section-header"], [1, "header-text"], [1, "edit-link"], ["data-automation", "EditStatusLink", 3, "click", 4, "ngIf"], ["class", "section-detail", 4, "ngIf"], ["class", "status-view", 4, "ngIf"], ["class", "status-label-row", 4, "ngIf"], ["data-automation", "EditStatusLink", 3, "click"], [1, "section-detail"], ["data-automation", "EditIluStatus", 1, "col-xs-12", "noSidePadding"], [3, "value", "change"], ["statusGroup", "matButtonToggleGroup"], [1, "col-xs-3", "noSidePadding", "override-button", 3, "value"], [1, "col-xs-4", "noSidePadding", "override-button", 3, "value"], [1, "col-xs-5", "noSidePadding", "override-button", 3, "value"], [1, "status-tags-container"], [1, "col-xs-12", "noSidePadding", "status-tags-label"], ["for", "tags", 1, "control-label"], [1, "col-xs-12", "noSidePadding", "status-tags-multi-select"], ["name", "tags", "data-automation", "tags", 3, "placeholder", "settings", "data", "initialSelectedItems", "onSelectionChange"], [1, "col-xs-12", "noSidePadding", "margin-top-15", "margin-bottom-15"], [1, "col-xs-4", "noSidePadding"], [1, "col-xs-4", "footer-left-button"], ["type", "reset", 1, "btn", "btn-warning", "btn-block", 3, "click"], [1, "col-xs-4", "noSidePadding", "footer-right-button"], ["type", "submit", 1, "btn", "btn-primary", "btn-block", 3, "click"], [1, "status-view"], [1, "icon", "icon-ilu-fill"], [1, "text"], [1, "status-label-row"], ["data-automation", "EnquiryTagsList", 3, "ngClass", 4, "ngFor", "ngForOf"], ["data-automation", "EnquiryTagsList", 3, "ngClass"]], template: function ILUStatusComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "div", 3);
        i0.ɵɵelementStart(4, "span");
        i0.ɵɵtext(5, "Status");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(6, "div", 4);
        i0.ɵɵtemplate(7, ILUStatusComponent_a_7_Template, 2, 0, "a", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelement(8, "hr");
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(9, ILUStatusComponent_div_9_Template, 26, 12, "div", 6);
        i0.ɵɵtemplate(10, ILUStatusComponent_div_10_Template, 4, 1, "div", 7);
        i0.ɵɵtemplate(11, ILUStatusComponent_div_11_Template, 3, 3, "div", 8);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(7);
        i0.ɵɵproperty("ngIf", !ctx.isEditMode && !ctx.isReadOnly);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.isEditMode);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.isEditMode);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.isEditMode);
    } }, directives: [i3.NgIf, i4.MatButtonToggleGroup, i4.MatButtonToggle, i5.DnaRDropdownMultiSelect, i3.NgForOf, i3.NgClass], pipes: [i3.AsyncPipe], styles: [".lead-status[_ngcontent-%COMP%]   .footer-left-button[_ngcontent-%COMP%] {\n  padding: 0px 5px 10px 0px;\n}\n.lead-status[_ngcontent-%COMP%]   .footer-right-button[_ngcontent-%COMP%] {\n  padding: 0px 0px 10px 5px;\n}\n.noSidePadding[_ngcontent-%COMP%] {\n  padding-right: 0;\n  padding-left: 0;\n}\n.section-detail[_ngcontent-%COMP%] {\n  padding: 17px 0;\n}\n  .mat-button-toggle-label-content {\n  padding: 6px 14px!important;\n  font-weight: 600;\n  width: 100%;\n}\n.status-view[_ngcontent-%COMP%] {\n  display: block;\n  line-height: 20px;\n  margin: 11px 0;\n}\n.status-view[_ngcontent-%COMP%]   span[_ngcontent-%COMP%] {\n  line-height: 20px;\n  float: left;\n}\n.status-view[_ngcontent-%COMP%]   .text[_ngcontent-%COMP%] {\n  margin-left: 5px;\n}\n.status-label-row[_ngcontent-%COMP%] {\n  display: flex;\n  width: 224px;\n  align-items: flex-start;\n  gap: 4px;\n  flex-wrap: wrap;\n  padding-top: 14px;\n}\n.status-tags-container[_ngcontent-%COMP%] {\n  display: block;\n}\n.status-tags-container[_ngcontent-%COMP%]   .status-tags-label[_ngcontent-%COMP%] {\n  padding-top: 18px;\n  padding-bottom: 5px;\n}\n.status-tags-container[_ngcontent-%COMP%]   .status-tags-multi-select[_ngcontent-%COMP%] {\n  padding-bottom: 7px;\n}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ILUStatusComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'ilu-status',
                templateUrl: './ILU-status.component.html',
                styleUrls: ['./ILU-status.component.less'],
            }]
    }], function () { return [{ type: i1.IndependentLivingService }, { type: i2.DnaRSnackBarService }, { type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['authentication.service']
            }] }]; }, { residencyId: [{
            type: core_1.Input
        }], currentIluStatus: [{
            type: core_1.Input
        }], isReadOnlyComponent: [{
            type: core_1.Input
        }] }); })();
