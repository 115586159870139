"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const core_2 = require("@uirouter/core");
const billing_service_1 = require("../billing.service");
const Models_1 = require("../Models");
const i0 = require("@angular/core");
const i1 = require("@uirouter/core");
const i2 = require("../billing.service");
const i3 = require("@angular/forms");
const i4 = require("devextreme-angular");
const i5 = require("devextreme-angular/ui/nested");
const i6 = require("@angular/common");
function AddDetailsComponent_div_19_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 21);
    i0.ɵɵtext(1, "This cannot be empty");
    i0.ɵɵelementEnd();
} }
function AddDetailsComponent_div_19_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, AddDetailsComponent_div_19_div_1_Template, 2, 0, "div", 20);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵnextContext();
    const _r1 = i0.ɵɵreference(18);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", _r1.errors.required);
} }
if (!/localhost/.test(document.location.host)) {
    core_1.enableProdMode();
}
class AddDetailsComponent {
    constructor($state, uiRouter, billingService, notificationService) {
        this.$state = $state;
        this.uiRouter = uiRouter;
        this.billingService = billingService;
        this.notificationService = notificationService;
        this.billingRun = new Models_1.BillingRunDto();
        this.onChargeToDateValueChanged = (e) => {
            if (e.value != null) {
                const selectedDate = new Date(e.value); //get the selected date      
                //Convert the selected date to utc midnight
                var utcDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0, 0));
                e.component.option('value', utcDate); // re-assign the modified date      
            }
        };
        this.backToBillingRunsList = () => {
            this.$state.go("viewBillingRuns");
        };
        this.next = (isFormValid) => {
            if (isFormValid) {
                this.billingRun.chargeToDateUtc = this.chargeToDateUtc;
                this.billingRun.name = this.billingRunName;
                this.billingRun.description = this.description;
                this.billingRun.status = Models_1.BillingRunStatus.SelectionCriteria;
                this.billingService.saveBillingRun(this.billingRun).then((response) => {
                    this.$state.go("createBillingRun.selectionCriteria", { id: response.result });
                    this.billingService.notifyStateChange("createBillingRun.selectionCriteria");
                }, () => {
                    this.notificationService.error("Unexpected error while saving the billing run.");
                });
            }
        };
        this.billingRunId = this.uiRouter.globals.params.id;
        this.billingService.notifyStateChange("createBillingRun.addDetails");
    }
    ngOnInit() {
        if (this.billingRunId != 0) {
            this.billingService.getBillingRun(this.billingRunId).then((response) => {
                this.billingRun = response;
                this.billingRunName = response.name;
                this.chargeToDateUtc = response.chargeToDateUtc;
                this.description = response.description;
            }, () => {
                this.notificationService.error("Unexpected error while getting the billing run.");
            });
        }
    }
}
exports.AddDetailsComponent = AddDetailsComponent;
AddDetailsComponent.ɵfac = function AddDetailsComponent_Factory(t) { return new (t || AddDetailsComponent)(i0.ɵɵdirectiveInject(i1.StateService), i0.ɵɵdirectiveInject(i1.UIRouter), i0.ɵɵdirectiveInject(i2.BillingService), i0.ɵɵdirectiveInject('notification.service')); };
AddDetailsComponent.ɵcmp = i0.ɵɵdefineComponent({ type: AddDetailsComponent, selectors: [["add-details"]], decls: 32, vars: 8, consts: [[1, "add-details"], ["novalidate", "", 3, "ngSubmit"], ["addDetailsForm", "ngForm"], [1, "col-xs-8", "main-area"], [1, "col-xs-12"], [1, "col-xs-6", "charge-to-date"], ["for", "chargeToDate", 1, "control-label", "v-align-middle"], ["name", "dateBox", "type", "date", "placeholder", "dd/mm/yyyy", "displayFormat", "dd/MM/yyyy", 3, "value", "useMaskBehavior", "height", "width", "valueChange", "onValueChanged"], ["type", "required", "message", "This cannot be empty"], [1, "comment-label", "v-align-middle"], [1, "col-xs-6"], ["for", "billingRunName", 1, "control-label", "v-align-middle"], ["required", "", "type", "text", "name", "billingRunName", "maxlength", "50", 1, "form-control", "input-lg", 3, "ngModel", "ngModelChange"], ["nameVar", "ngModel"], [4, "ngIf"], [1, "col-xs-12", "margin-top-40"], ["type", "text", "name", "description", "maxlength", "250", 1, "form-control", "input-lg", 3, "ngModel", "ngModelChange"], [1, "col-xs-4", "navigation-btn"], ["text", "Next", "type", "success", 3, "useSubmitBehavior"], ["type", "button", 1, "btn", "btn-secondary", "btn-lg", "pull-right", 3, "click"], ["class", "text-danger", 4, "ngIf"], [1, "text-danger"]], template: function AddDetailsComponent_Template(rf, ctx) { if (rf & 1) {
        const _r4 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "form", 1, 2);
        i0.ɵɵlistener("ngSubmit", function AddDetailsComponent_Template_form_ngSubmit_1_listener() { i0.ɵɵrestoreView(_r4); const _r0 = i0.ɵɵreference(2); return ctx.next(_r0.form.valid); });
        i0.ɵɵelementStart(3, "div", 3);
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵelementStart(5, "div", 5);
        i0.ɵɵelementStart(6, "label", 6);
        i0.ɵɵtext(7, "*Charge to date");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(8, "dx-date-box", 7);
        i0.ɵɵlistener("valueChange", function AddDetailsComponent_Template_dx_date_box_valueChange_8_listener($event) { return ctx.chargeToDateUtc = $event; })("onValueChanged", function AddDetailsComponent_Template_dx_date_box_onValueChanged_8_listener($event) { return ctx.onChargeToDateValueChanged($event); });
        i0.ɵɵelementStart(9, "dx-validator");
        i0.ɵɵelement(10, "dxi-validation-rule", 8);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(11, "label", 9);
        i0.ɵɵtext(12, "Charge to date will be the last day billed.");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(13, "div", 10);
        i0.ɵɵelementStart(14, "label", 11);
        i0.ɵɵtext(15, "*Name");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(16, "div");
        i0.ɵɵelementStart(17, "input", 12, 13);
        i0.ɵɵlistener("ngModelChange", function AddDetailsComponent_Template_input_ngModelChange_17_listener($event) { return ctx.billingRunName = $event; });
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(19, AddDetailsComponent_div_19_Template, 2, 1, "div", 14);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(20, "div", 15);
        i0.ɵɵelementStart(21, "label", 6);
        i0.ɵɵtext(22, "Description");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(23, "div");
        i0.ɵɵelementStart(24, "textarea", 16);
        i0.ɵɵlistener("ngModelChange", function AddDetailsComponent_Template_textarea_ngModelChange_24_listener($event) { return ctx.description = $event; });
        i0.ɵɵtext(25, "                    ");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(26, "label", 9);
        i0.ɵɵtext(27, "This is optional.");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(28, "div", 17);
        i0.ɵɵelement(29, "dx-button", 18);
        i0.ɵɵelementStart(30, "button", 19);
        i0.ɵɵlistener("click", function AddDetailsComponent_Template_button_click_30_listener() { return ctx.backToBillingRunsList(); });
        i0.ɵɵtext(31, "Back");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        const _r0 = i0.ɵɵreference(2);
        const _r1 = i0.ɵɵreference(18);
        i0.ɵɵadvance(8);
        i0.ɵɵproperty("value", ctx.chargeToDateUtc)("useMaskBehavior", true)("height", 46)("width", 350);
        i0.ɵɵadvance(9);
        i0.ɵɵproperty("ngModel", ctx.billingRunName);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", _r1.errors && (_r0.submitted || _r1.touched));
        i0.ɵɵadvance(5);
        i0.ɵɵproperty("ngModel", ctx.description);
        i0.ɵɵadvance(5);
        i0.ɵɵproperty("useSubmitBehavior", true);
    } }, directives: [i3.ɵangular_packages_forms_forms_y, i3.NgControlStatusGroup, i3.NgForm, i4.DxDateBoxComponent, i4.DxValidatorComponent, i5.DxiValidationRuleComponent, i3.DefaultValueAccessor, i3.RequiredValidator, i3.MaxLengthValidator, i3.NgControlStatus, i3.NgModel, i6.NgIf, i4.DxButtonComponent], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AddDetailsComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'add-details',
                templateUrl: './addDetails.component.html'
            }]
    }], function () { return [{ type: i1.StateService }, { type: i1.UIRouter }, { type: i2.BillingService }, { type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['notification.service']
            }] }]; }, null); })();
