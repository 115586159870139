"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("@uirouter/angular");
const account_settings_component_1 = require("./account-settings/account-settings.component");
exports.states = [
    {
        name: "accountSettings",
        url: "/accountSettings",
        component: account_settings_component_1.AccountSettingsComponent
    }
];
