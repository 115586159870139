"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const angular_hybrid_1 = require("@uirouter/angular-hybrid");
const core_1 = require("@angular/core");
const static_1 = require("@angular/upgrade/static");
const shared_module_1 = require("../common/shared.module");
const platform_browser_1 = require("@angular/platform-browser");
const ilu_leads_dashboard_component_1 = require("./dashboard/ilu-leads-dashboard.component");
const ilu_leads_dashboard_column_component_1 = require("./dashboard/ilu-leads-dashboard-column.component");
const ilu_lead_card_component_1 = require("./dashboard/ilu-lead-card.component");
const independent_living_service_1 = require("./services/independent-living.service");
const mobility_requirements_component_1 = require("./mobility-requirements/mobility-requirements.component");
const radio_1 = require("@angular/material/radio");
const snack_bar_1 = require("@angular/material/snack-bar");
const accommodation_preferences_component_1 = require("./accommodation-preferences/components/accommodation-preferences.component");
const ILU_status_component_1 = require("./ilu-status/components/ILU-status.component");
const independent_living_states_1 = require("./independent-living.states");
const drag_drop_1 = require("@angular/cdk/drag-drop");
const dnaR_dropdown_multiSelect_module_1 = require("../common/components/dnaR-dropdown-multiSelect/dnaR-dropdown-multiSelect.module");
const dnaR_action_modal_component_1 = require("../common/components/dnaR-action-modal/dnaR-action-modal.component");
const accommodation_preferences_service_1 = require("./services/accommodation-preferences.service");
const ilu_lead_card_dropdown_component_1 = require("./dashboard/ilu-lead-card-dropdown.component");
const menu_1 = require("@angular/material/menu");
const button_1 = require("@angular/material/button");
const dialog_1 = require("@angular/material/dialog");
const tooltip_1 = require("@angular/material/tooltip");
const icon_1 = require("@angular/material/icon");
const button_toggle_1 = require("@angular/material/button-toggle");
const archived_leads_component_1 = require("./archived-leads/components/archived-leads.component");
const lead_card_component_1 = require("./archived-leads/components/lead-card.component");
const loading_lead_card_component_1 = require("./archived-leads/components/loading-lead-card.component");
const enquiry_lead_card_component_1 = require("./dashboard/enquiry-lead-card.component");
const prospectName_pipe_1 = require("./pipes/prospectName.pipe");
const i0 = require("@angular/core");
const i1 = require("@uirouter/angular-hybrid");
// Downgrade and register items in AngularJs
angular.module('app')
    .directive('mobilityRequirements', static_1.downgradeComponent({ component: mobility_requirements_component_1.MobilityRequirementsComponent }))
    .directive('accommodationPreferences', static_1.downgradeComponent({ component: accommodation_preferences_component_1.AccommodationPreferencesComponent }))
    .directive('iluStatus', static_1.downgradeComponent({ component: ILU_status_component_1.ILUStatusComponent }));
class IndependentLivingModule {
}
exports.IndependentLivingModule = IndependentLivingModule;
IndependentLivingModule.ɵmod = i0.ɵɵdefineNgModule({ type: IndependentLivingModule });
IndependentLivingModule.ɵinj = i0.ɵɵdefineInjector({ factory: function IndependentLivingModule_Factory(t) { return new (t || IndependentLivingModule)(); }, providers: [independent_living_service_1.IndependentLivingService, accommodation_preferences_service_1.AccommodationPreferencesService], imports: [[
            shared_module_1.SharedModule,
            platform_browser_1.BrowserModule,
            radio_1.MatRadioModule,
            snack_bar_1.MatSnackBarModule,
            button_1.MatButtonModule,
            icon_1.MatIconModule,
            tooltip_1.MatTooltipModule,
            dialog_1.MatDialogModule,
            dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule,
            drag_drop_1.DragDropModule,
            menu_1.MatMenuModule,
            button_toggle_1.MatButtonToggleModule,
            angular_hybrid_1.UIRouterUpgradeModule.forChild({ states: independent_living_states_1.states })
        ]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(IndependentLivingModule, { declarations: [mobility_requirements_component_1.MobilityRequirementsComponent,
        accommodation_preferences_component_1.AccommodationPreferencesComponent,
        ILU_status_component_1.ILUStatusComponent,
        ilu_leads_dashboard_component_1.ILULeadsDashboardComponent,
        ilu_leads_dashboard_column_component_1.ILULeadsDashboardColumnComponent,
        ilu_lead_card_component_1.ILULeadCardComponent,
        ilu_lead_card_dropdown_component_1.IluLeadCardDropdownComponent,
        dnaR_action_modal_component_1.DnaRActionModalComponent,
        archived_leads_component_1.ArchivedLeadsComponent,
        lead_card_component_1.LeadCardComponent,
        loading_lead_card_component_1.LoadingLeadCardComponent,
        enquiry_lead_card_component_1.EnquiryLeadCardComponent,
        prospectName_pipe_1.ProspectName], imports: [shared_module_1.SharedModule,
        platform_browser_1.BrowserModule,
        radio_1.MatRadioModule,
        snack_bar_1.MatSnackBarModule,
        button_1.MatButtonModule,
        icon_1.MatIconModule,
        tooltip_1.MatTooltipModule,
        dialog_1.MatDialogModule,
        dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule,
        drag_drop_1.DragDropModule,
        menu_1.MatMenuModule,
        button_toggle_1.MatButtonToggleModule, i1.UIRouterUpgradeModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(IndependentLivingModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [
                    shared_module_1.SharedModule,
                    platform_browser_1.BrowserModule,
                    radio_1.MatRadioModule,
                    snack_bar_1.MatSnackBarModule,
                    button_1.MatButtonModule,
                    icon_1.MatIconModule,
                    tooltip_1.MatTooltipModule,
                    dialog_1.MatDialogModule,
                    dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule,
                    drag_drop_1.DragDropModule,
                    menu_1.MatMenuModule,
                    button_toggle_1.MatButtonToggleModule,
                    angular_hybrid_1.UIRouterUpgradeModule.forChild({ states: independent_living_states_1.states })
                ],
                providers: [independent_living_service_1.IndependentLivingService, accommodation_preferences_service_1.AccommodationPreferencesService],
                declarations: [
                    mobility_requirements_component_1.MobilityRequirementsComponent,
                    accommodation_preferences_component_1.AccommodationPreferencesComponent,
                    ILU_status_component_1.ILUStatusComponent,
                    ilu_leads_dashboard_component_1.ILULeadsDashboardComponent,
                    ilu_leads_dashboard_column_component_1.ILULeadsDashboardColumnComponent,
                    ilu_lead_card_component_1.ILULeadCardComponent,
                    ilu_lead_card_dropdown_component_1.IluLeadCardDropdownComponent,
                    dnaR_action_modal_component_1.DnaRActionModalComponent,
                    archived_leads_component_1.ArchivedLeadsComponent,
                    lead_card_component_1.LeadCardComponent,
                    loading_lead_card_component_1.LoadingLeadCardComponent,
                    enquiry_lead_card_component_1.EnquiryLeadCardComponent,
                    prospectName_pipe_1.ProspectName
                ]
            }]
    }], null, null); })();
