"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("@uirouter/angular");
const facility_details_tab_group_component_1 = require("./facility/facility-details/components/facility-details-tab-group/facility-details-tab-group.component");
exports.states = [
    {
        name: "facilityDetails",
        url: "/facility/{id:int}",
        component: facility_details_tab_group_component_1.FacilityDetailsTabGroupComponent,
        resolve: {
            authorized: ["authentication.service", function (auth) {
                    return auth.userHasPermissionDeferred(function (x) { return x.facilityDetails.canAction; });
                }]
        }
    }
];
