"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
/**
 * An injectable provider for the window global object.
 *
 * Handy for mocking purposes.ie Ha ha. You're just like the WINDOW InjectionToken.
 */
exports.WINDOW = new core_1.InjectionToken('Window', {
    providedIn: 'root',
    factory() {
        return window;
    }
});
