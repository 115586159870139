"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
require("./Models");
const i0 = require("@angular/core");
class AdminService {
    constructor(dataService) {
        this.dataService = dataService;
        this.getOrganisation = () => {
            return this.dataService.get("admin/organisation", true);
        };
        this.putOrganisation = (organisation) => {
            return this.dataService.put("admin/organisation/" + organisation.id, organisation);
        };
    }
}
exports.AdminService = AdminService;
AdminService.ɵfac = function AdminService_Factory(t) { return new (t || AdminService)(i0.ɵɵinject('dataService')); };
AdminService.ɵprov = i0.ɵɵdefineInjectable({ token: AdminService, factory: AdminService.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AdminService, [{
        type: core_1.Injectable
    }], function () { return [{ type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['dataService']
            }] }]; }, null); })();
