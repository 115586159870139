"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const platform_browser_1 = require("@angular/platform-browser");
const forms_1 = require("@angular/forms");
const viewCommittedBillingRun_component_1 = require("./billingRun/viewCommittedBillingRun.component");
const shared_module_1 = require("../common/shared.module");
const angular_hybrid_1 = require("@uirouter/angular-hybrid");
const devextreme_angular_1 = require("devextreme-angular");
const billing_service_1 = require("./billing.service");
const billing_states_1 = require("./billing.states");
const viewBillingRuns_component_1 = require("./billingRun/viewBillingRuns.component");
const createBillingRun_component_1 = require("./billingRun/createBillingRun.component");
const addDetails_component_1 = require("./billingRun/addDetails.component");
const selectionCriteria_component_1 = require("./billingRun/selectionCriteria.component");
const confirmResidents_component_1 = require("./billingRun/confirmResidents.component");
const reviewAndCommit_component_1 = require("./billingRun/reviewAndCommit.component");
const i0 = require("@angular/core");
const i1 = require("@uirouter/angular-hybrid");
class BillingModule {
}
exports.BillingModule = BillingModule;
BillingModule.ɵmod = i0.ɵɵdefineNgModule({ type: BillingModule });
BillingModule.ɵinj = i0.ɵɵdefineInjector({ factory: function BillingModule_Factory(t) { return new (t || BillingModule)(); }, providers: [billing_service_1.BillingService], imports: [[
            shared_module_1.SharedModule,
            platform_browser_1.BrowserModule,
            forms_1.FormsModule,
            devextreme_angular_1.DxFormModule,
            devextreme_angular_1.DxButtonModule,
            devextreme_angular_1.DxDataGridModule,
            devextreme_angular_1.DxDateBoxModule,
            devextreme_angular_1.DxDropDownBoxModule,
            devextreme_angular_1.DxListModule,
            devextreme_angular_1.DxValidatorModule,
            angular_hybrid_1.UIRouterUpgradeModule.forChild({ states: billing_states_1.states })
        ]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(BillingModule, { declarations: [viewBillingRuns_component_1.ViewBillingRunsComponent,
        createBillingRun_component_1.CreateBillingRunComponent,
        viewCommittedBillingRun_component_1.ViewCommittedBillingRunComponent,
        addDetails_component_1.AddDetailsComponent,
        selectionCriteria_component_1.SelectionCriteriaComponent,
        confirmResidents_component_1.ConfirmResidentsComponent,
        reviewAndCommit_component_1.ReviewAndCommitComponent], imports: [shared_module_1.SharedModule,
        platform_browser_1.BrowserModule,
        forms_1.FormsModule,
        devextreme_angular_1.DxFormModule,
        devextreme_angular_1.DxButtonModule,
        devextreme_angular_1.DxDataGridModule,
        devextreme_angular_1.DxDateBoxModule,
        devextreme_angular_1.DxDropDownBoxModule,
        devextreme_angular_1.DxListModule,
        devextreme_angular_1.DxValidatorModule, i1.UIRouterUpgradeModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(BillingModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [
                    shared_module_1.SharedModule,
                    platform_browser_1.BrowserModule,
                    forms_1.FormsModule,
                    devextreme_angular_1.DxFormModule,
                    devextreme_angular_1.DxButtonModule,
                    devextreme_angular_1.DxDataGridModule,
                    devextreme_angular_1.DxDateBoxModule,
                    devextreme_angular_1.DxDropDownBoxModule,
                    devextreme_angular_1.DxListModule,
                    devextreme_angular_1.DxValidatorModule,
                    angular_hybrid_1.UIRouterUpgradeModule.forChild({ states: billing_states_1.states })
                ],
                providers: [billing_service_1.BillingService],
                declarations: [
                    viewBillingRuns_component_1.ViewBillingRunsComponent,
                    createBillingRun_component_1.CreateBillingRunComponent,
                    viewCommittedBillingRun_component_1.ViewCommittedBillingRunComponent,
                    addDetails_component_1.AddDetailsComponent,
                    selectionCriteria_component_1.SelectionCriteriaComponent,
                    confirmResidents_component_1.ConfirmResidentsComponent,
                    reviewAndCommit_component_1.ReviewAndCommitComponent
                ]
            }]
    }], null, null); })();
