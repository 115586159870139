"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const facility_dto_1 = require("../../../../dtos/facility-dto");
require("underscore");
const i0 = require("@angular/core");
const i1 = require("@angular/common");
const i2 = require("./facility-details-view-tab.component");
const i3 = require("./facility-details-edit-tab.component");
function FacilityDetailsTabComponent_facility_details_view_tab_1_Template(rf, ctx) { if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "facility-details-view-tab", 3);
    i0.ɵɵlistener("onEdit", function FacilityDetailsTabComponent_facility_details_view_tab_1_Template_facility_details_view_tab_onEdit_0_listener() { i0.ɵɵrestoreView(_r3); const ctx_r2 = i0.ɵɵnextContext(); return ctx_r2.onEdit(); });
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("facility", ctx_r0.facility);
} }
function FacilityDetailsTabComponent_facility_details_edit_tab_2_Template(rf, ctx) { if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "facility-details-edit-tab", 4);
    i0.ɵɵlistener("onDone", function FacilityDetailsTabComponent_facility_details_edit_tab_2_Template_facility_details_edit_tab_onDone_0_listener($event) { i0.ɵɵrestoreView(_r5); const ctx_r4 = i0.ɵɵnextContext(); return ctx_r4.onEditComplete($event); });
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("facility", ctx_r1.editFacility);
} }
/**
 * Displays the facility details tab.
 */
class FacilityDetailsTabComponent {
    /**
     * Initializes a new instance of the FacilityDetailsTabComponent class.
     */
    constructor() {
        /**
         * When true the tab is in edit mode, otherwise tab is in view mode.
         */
        this.isEditting = false;
    }
    /**
     * Switches to edit mode.
     */
    onEdit() {
        // Deep copy of facility to allow discarding of changes on cancel.
        this.editFacility = new facility_dto_1.FacilityDto();
        this.editFacility = Object.assign(this.editFacility, this.facility);
        this.isEditting = true;
    }
    /**
     * Switches to view mode.
     *
     * @param cancelled true if editing was cancelled, otherwise false.
     */
    onEditComplete(cancelled) {
        if (!cancelled && this.editFacility) {
            // Update facility if not cancelled.
            this.facility = this.editFacility;
        }
        this.isEditting = false;
    }
}
exports.FacilityDetailsTabComponent = FacilityDetailsTabComponent;
FacilityDetailsTabComponent.ɵfac = function FacilityDetailsTabComponent_Factory(t) { return new (t || FacilityDetailsTabComponent)(); };
FacilityDetailsTabComponent.ɵcmp = i0.ɵɵdefineComponent({ type: FacilityDetailsTabComponent, selectors: [["facility-details-tab"]], inputs: { facility: "facility" }, decls: 3, vars: 2, consts: [[1, "facility-details-tab", "vertical-tab"], [3, "facility", "onEdit", 4, "ngIf"], [3, "facility", "onDone", 4, "ngIf"], [3, "facility", "onEdit"], [3, "facility", "onDone"]], template: function FacilityDetailsTabComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, FacilityDetailsTabComponent_facility_details_view_tab_1_Template, 1, 1, "facility-details-view-tab", 1);
        i0.ɵɵtemplate(2, FacilityDetailsTabComponent_facility_details_edit_tab_2_Template, 1, 1, "facility-details-edit-tab", 2);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.isEditting);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.isEditting);
    } }, directives: [i1.NgIf, i2.FacilityDetailsViewTabComponent, i3.FacilityDetailsEditTabComponent], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(FacilityDetailsTabComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'facility-details-tab',
                templateUrl: './facility-details-tab.component.html'
            }]
    }], function () { return []; }, { facility: [{
            type: core_1.Input
        }] }); })();
