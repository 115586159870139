"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
//Angular dependancies
require("@angular/compiler");
require("@angular/common");
require("@angular/core");
require("@angular/forms");
require("@angular/platform-browser");
require("@angular/platform-browser-dynamic");
require("@angular/upgrade/static");
require("@angular/router");
require("@uirouter/angular-hybrid");
