"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BillingRunStatus;
(function (BillingRunStatus) {
    BillingRunStatus[BillingRunStatus["AddDetails"] = 0] = "AddDetails";
    BillingRunStatus[BillingRunStatus["SelectionCriteria"] = 1] = "SelectionCriteria";
    BillingRunStatus[BillingRunStatus["ConfirmResidents"] = 2] = "ConfirmResidents";
    BillingRunStatus[BillingRunStatus["ReviewAndCommit"] = 3] = "ReviewAndCommit";
    BillingRunStatus[BillingRunStatus["Committed"] = 4] = "Committed";
})(BillingRunStatus = exports.BillingRunStatus || (exports.BillingRunStatus = {}));
var BillingRecurrenceInterval;
(function (BillingRecurrenceInterval) {
    BillingRecurrenceInterval[BillingRecurrenceInterval["Daily"] = 1] = "Daily";
    BillingRecurrenceInterval[BillingRecurrenceInterval["Weekly"] = 2] = "Weekly";
    BillingRecurrenceInterval[BillingRecurrenceInterval["Fortnightly"] = 3] = "Fortnightly";
    BillingRecurrenceInterval[BillingRecurrenceInterval["Monthly"] = 4] = "Monthly";
    BillingRecurrenceInterval[BillingRecurrenceInterval["Yearly"] = 5] = "Yearly";
})(BillingRecurrenceInterval = exports.BillingRecurrenceInterval || (exports.BillingRecurrenceInterval = {}));
var CareType;
(function (CareType) {
    CareType["Permanent"] = "Permanent";
    CareType["Respite"] = "Respite";
    CareType["Private"] = "Private";
    CareType["ILU"] = "Independent Living";
})(CareType = exports.CareType || (exports.CareType = {}));
var FeeType;
(function (FeeType) {
    FeeType["BasicDailyCareFee"] = "Basic Daily Care Fee";
    FeeType["PrivateDailyCareFee"] = "Private Daily Care Fee";
    FeeType["MeansTestedCareFee"] = "Means Tested Care Fee";
    FeeType["IncomeTestedCareFee"] = "Income Tested Care Fee";
    FeeType["DailyAccommodationContribute"] = "Daily Accommodation Contribution";
    FeeType["DailyAccommodationPayment"] = "Daily Accommodation Payment";
    FeeType["Pre2014AccomodationChargeDailyAmount"] = "Accommodation Charge Daily Amount (Pre 2014)";
    FeeType["Pre2014PeriodicInterestDailyAmountFee"] = "Periodic Interest Daily Amount (Pre 2014)";
    FeeType["OptionalService"] = "Optional services";
    FeeType["SundryCharge"] = "Sundry charges";
    FeeType["Hardship"] = "Hardship";
})(FeeType = exports.FeeType || (exports.FeeType = {}));
class FacilityDto {
}
exports.FacilityDto = FacilityDto;
class BillingRunInfo {
}
exports.BillingRunInfo = BillingRunInfo;
class BillingRunResident {
}
exports.BillingRunResident = BillingRunResident;
class BillingRunDto {
}
exports.BillingRunDto = BillingRunDto;
class BillingRunResidencyChargeDto {
}
exports.BillingRunResidencyChargeDto = BillingRunResidencyChargeDto;
