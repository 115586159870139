"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const animations_1 = require("@angular/animations");
const core_1 = require("@angular/core");
const windows_provider_1 = require("../../provider/windows.provider");
const i0 = require("@angular/core");
/**
 * Component to provide a "back to top" button for a page.
 */
class DnaRBackToTop {
    /**
     * Initializes a new instance of the MobilityRequirementsComponent class.
     *
     * @param window The provider for the window global object.
     */
    constructor(window) {
        this.window = window;
        /**
         * true if the scroll is at the top browser window, otherwise false.
         */
        this.atTop = true;
    }
    /**
     * Browser window scroll event listener.
     *
     * @param e Scroll event arguments.
     */
    onWindowScroll(e) {
        this.atTop = e.target['scrollingElement'].scrollTop <= 0;
    }
    /**
     * Scroll to the top of the browser window, smoothly.
     */
    backToTop() {
        this.window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}
exports.DnaRBackToTop = DnaRBackToTop;
DnaRBackToTop.ɵfac = function DnaRBackToTop_Factory(t) { return new (t || DnaRBackToTop)(i0.ɵɵdirectiveInject(windows_provider_1.WINDOW)); };
DnaRBackToTop.ɵcmp = i0.ɵɵdefineComponent({ type: DnaRBackToTop, selectors: [["dnaR-back-to-top"]], hostBindings: function DnaRBackToTop_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("scroll", function DnaRBackToTop_scroll_HostBindingHandler($event) { return ctx.onWindowScroll($event); }, false, i0.ɵɵresolveWindow);
    } }, decls: 3, vars: 1, consts: [["value", "Back to top", 1, "dna-r", "back-to-top", "btn", "btn-primary", 3, "click"], [1, "icon", "icon-upward-outline", "icon-neutral-white"]], template: function DnaRBackToTop_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "button", 0);
        i0.ɵɵlistener("click", function DnaRBackToTop_Template_button_click_0_listener() { return ctx.backToTop(); });
        i0.ɵɵtext(1, " Back to top ");
        i0.ɵɵelement(2, "i", 1);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵproperty("@atTop", ctx.atTop ? "hidden" : "visible");
    } }, encapsulation: 2, data: { animation: [
            animations_1.trigger('atTop', [
                animations_1.state('visible', animations_1.style({
                    opacity: 1,
                    display: 'inline-block' // Existing style for the AngularJs 'Back To Top' button has display: none. Include this to not disturb things while AngularJs 'Back To Top' button exists.
                })),
                animations_1.state('hidden', animations_1.style({
                    opacity: 0
                })),
                animations_1.transition('visible => hidden', [
                    animations_1.animate('400ms')
                ]),
                animations_1.transition('hidden => visible', [
                    animations_1.animate('400ms')
                ]),
            ]),
        ] } });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DnaRBackToTop, [{
        type: core_1.Component,
        args: [{
                templateUrl: './dnaR-back-to-top.component.html',
                selector: 'dnaR-back-to-top',
                animations: [
                    animations_1.trigger('atTop', [
                        animations_1.state('visible', animations_1.style({
                            opacity: 1,
                            display: 'inline-block' // Existing style for the AngularJs 'Back To Top' button has display: none. Include this to not disturb things while AngularJs 'Back To Top' button exists.
                        })),
                        animations_1.state('hidden', animations_1.style({
                            opacity: 0
                        })),
                        animations_1.transition('visible => hidden', [
                            animations_1.animate('400ms')
                        ]),
                        animations_1.transition('hidden => visible', [
                            animations_1.animate('400ms')
                        ]),
                    ]),
                ]
            }]
    }], function () { return [{ type: Window, decorators: [{
                type: core_1.Inject,
                args: [windows_provider_1.WINDOW]
            }] }]; }, { onWindowScroll: [{
            type: core_1.HostListener,
            args: ['window:scroll', ['$event']]
        }] }); })();
