"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const residents_service_1 = require("../services/residents.service");
const i0 = require("@angular/core");
const i1 = require("../services/residents.service");
class ResidentProfilePhotoComponent {
    constructor(residentsService) {
        this.residentsService = residentsService;
    }
    ngOnChanges() {
        this.loadProfileImage();
    }
    loadProfileImage() {
        if (this.residentId) {
            this.residentsService.getPhoto(this.residentId).then((result) => {
                this.image = result.photo;
            }, () => {
                this.image = "../../../../images/unknown-user.jpg";
            });
        }
        else {
            this.image = "../../../../images/unknown-user.jpg";
        }
    }
}
exports.ResidentProfilePhotoComponent = ResidentProfilePhotoComponent;
ResidentProfilePhotoComponent.ɵfac = function ResidentProfilePhotoComponent_Factory(t) { return new (t || ResidentProfilePhotoComponent)(i0.ɵɵdirectiveInject(i1.ResidentsService)); };
ResidentProfilePhotoComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ResidentProfilePhotoComponent, selectors: [["resident-profile-photo"]], inputs: { residentId: "residentId" }, features: [i0.ɵɵNgOnChangesFeature], decls: 1, vars: 1, consts: [[1, "profile-photo", 3, "src"]], template: function ResidentProfilePhotoComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelement(0, "img", 0);
    } if (rf & 2) {
        i0.ɵɵpropertyInterpolate("src", ctx.image, i0.ɵɵsanitizeUrl);
    } }, styles: [".profile-photo[_ngcontent-%COMP%] {\n  display: inline-block;\n  width: 32px !important;\n  height: 32px;\n}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ResidentProfilePhotoComponent, [{
        type: core_1.Component,
        args: [{
                templateUrl: 'resident-profile-photo.component.html',
                styleUrls: ['./resident-profile-photo.component.less'],
                selector: 'resident-profile-photo',
            }]
    }], function () { return [{ type: i1.ResidentsService }]; }, { residentId: [{
            type: core_1.Input
        }] }); })();
