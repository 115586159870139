"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const dnaRSnackBar_service_1 = require("../../../../../common/dna-r/dnaRSnackBar.service");
const facility_service_1 = require("../../../../../organisation/services/facility.service");
const core_2 = require("@uirouter/core");
require("../../../../../organisation/dtos/facility-dto");
const i0 = require("@angular/core");
const i1 = require("../../../../../organisation/services/facility.service");
const i2 = require("@uirouter/core");
const i3 = require("../../../../../common/dna-r/dnaRSnackBar.service");
const i4 = require("@angular/common");
const i5 = require("../../../../../common/components/dnaR-back-to-top/dnaR-back-to-top.component");
const i6 = require("@angular/material/tabs");
const i7 = require("../facility-details-tab/facility-details-tab.component");
const i8 = require("../lead-tags-tab/lead-tags-tab.component");
function FacilityDetailsTabGroupComponent_div_1_mat_tab_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-tab", 8);
    i0.ɵɵelement(1, "lead-tags-tab", 9);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("facility", ctx_r1.facility)("tags", ctx_r1.tags);
} }
function FacilityDetailsTabGroupComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "div", 2);
    i0.ɵɵelementStart(2, "h1");
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "a", 3);
    i0.ɵɵtext(5, "Back to facilities");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "mat-tab-group", 4);
    i0.ɵɵelementStart(7, "mat-tab", 5);
    i0.ɵɵelement(8, "facility-details-tab", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(9, FacilityDetailsTabGroupComponent_div_1_mat_tab_9_Template, 2, 2, "mat-tab", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r0.facility.agedCareServiceName);
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("facility", ctx_r0.facility);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r0.facility.facilityType === "IndependentLiving");
} }
/**
 * Displays the facility details tab group.
 */
class FacilityDetailsTabGroupComponent {
    /**
     * Initializes a new instance of the ArchivedLeadsComponent class.
     *
     * @param independentLivingService - The @see IndependentLivingService service.
     * @param route - The @see ActivatedRoute object.
     * @param snackBar - The @see DnaRSnackBarService service.
     */
    constructor(facilityService, uiRouter, snackBarService) {
        this.facilityService = facilityService;
        this.uiRouter = uiRouter;
        this.snackBarService = snackBarService;
    }
    /**
     * @inheritdoc
     */
    ngOnInit() {
        this.loadFacility(Number(this.uiRouter.globals.params.id));
    }
    /**
     * Load the facility's details.
     */
    loadFacility(facilityId) {
        this.facilityService.getFacility(facilityId).then(result => {
            this.facility = result;
        }, () => {
            this.snackBarService.failure("Unexpected error while loading facility");
        });
    }
}
exports.FacilityDetailsTabGroupComponent = FacilityDetailsTabGroupComponent;
FacilityDetailsTabGroupComponent.ɵfac = function FacilityDetailsTabGroupComponent_Factory(t) { return new (t || FacilityDetailsTabGroupComponent)(i0.ɵɵdirectiveInject(i1.FacilityService), i0.ɵɵdirectiveInject(i2.UIRouter), i0.ɵɵdirectiveInject(i3.DnaRSnackBarService)); };
FacilityDetailsTabGroupComponent.ɵcmp = i0.ɵɵdefineComponent({ type: FacilityDetailsTabGroupComponent, selectors: [["ng-component"]], decls: 3, vars: 1, consts: [[1, "facility-details-tab-group", "dna-r"], [4, "ngIf"], [1, "page-header", 2, "display", "flex", "justify-content", "space-between"], ["href", "/#/facilities", "data-automation", "back-to-facility", 1, "btn", "btn-small", "btn-secondary"], ["animationDuration", "0ms", 1, "vertical-tabs"], ["label", "Facility details"], [3, "facility"], ["label", "Lead tags", 4, "ngIf"], ["label", "Lead tags"], [3, "facility", "tags"]], template: function FacilityDetailsTabGroupComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, FacilityDetailsTabGroupComponent_div_1_Template, 10, 3, "div", 1);
        i0.ɵɵelement(2, "dnaR-back-to-top");
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.facility);
    } }, directives: [i4.NgIf, i5.DnaRBackToTop, i6.MatTabGroup, i6.MatTab, i7.FacilityDetailsTabComponent, i8.LeadTagsTabComponent], styles: [".facility-details-tab-group[_ngcontent-%COMP%] {\n  width: 1160px;\n}\n.facility-details-tab-group[_ngcontent-%COMP%]   .page-header[_ngcontent-%COMP%] {\n  display: flex;\n  justify-content: space-between;\n  align-items: baseline;\n}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(FacilityDetailsTabGroupComponent, [{
        type: core_1.Component,
        args: [{
                templateUrl: './facility-details-tab-group.component.html',
                styleUrls: ['./facility-details-tab-group.component.less'],
            }]
    }], function () { return [{ type: i1.FacilityService }, { type: i2.UIRouter }, { type: i3.DnaRSnackBarService }]; }, null); })();
