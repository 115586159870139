"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
require("@angular/material-moment-adapter");
const core_2 = require("@angular/material/core");
const i0 = require("@angular/core");
exports.DATEPICKER_FORMATS = {
    parse: {
        dateInput: { day: 'numeric', month: 'short', year: 'numeric' },
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' }
    }
};
exports.MonthsList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const DateSeperators = [' ', '/', '-'];
exports.DefaultSettings = {
    placeholder: 'DD-MMM-YYYY',
    yearsBackTo: 110,
    yearsUpTo: 10,
    isRequired: false,
    requiredError: 'Date value is required.',
    formatError: "Wrong format",
    duplicateError: "Duplicate date"
};
class AppDateAdapter extends core_2.NativeDateAdapter {
    getDayOfWeekNames(style) {
        return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    }
    replaceAll(string, search, replace) {
        let parts = string.split(search);
        let validParts = parts.filter(x => x !== '');
        if (validParts.length > 1) {
            return validParts.join(replace);
        }
        return validParts;
    }
    // parses date input as user types it
    parse(value) {
        if (typeof value === 'string') {
            value = value.trim();
            const tempSeperator = '-';
            DateSeperators.forEach((seperator) => {
                value = value.replaceAll(seperator, tempSeperator);
            });
            // clean up multiple seperators found
            value = this.replaceAll(value, tempSeperator, tempSeperator);
            let str;
            if (value.indexOf(tempSeperator) > -1) {
                str = value.split(tempSeperator);
            }
            if (str && str[2] && str[1] && str[0]) {
                let year = str[2].length == 1
                    ? undefined
                    : str[2].length == 2
                        ? Number('20' + str[2])
                        : Number(str[2]);
                let monthStrSearch = str[1].toLowerCase();
                let monthstr = exports.MonthsList.find(x => x.toLowerCase() == monthStrSearch);
                let monthStrIndex = exports.MonthsList.indexOf(monthstr);
                let month = Number(str[1])
                    ? Number(str[1]) - 1
                    : monthStrIndex >= 0
                        ? monthStrIndex
                        : undefined;
                let day = Number(str[0]);
                if (year !== undefined && month !== undefined && day !== undefined) {
                    return new Date(year, month, day);
                }
            }
        }
        return null;
    }
    // Return formatted date of inputed date string
    format(date, displayFormat) {
        if (displayFormat === 'input') {
            let day = date.getDate().toString();
            day = +day < 10 ? '0' + day : day;
            let month = exports.MonthsList[date.getMonth()];
            let year = date.getFullYear();
            return `${day}-${month}-${year}`;
        }
        return date.toDateString();
    }
}
exports.AppDateAdapter = AppDateAdapter;
AppDateAdapter.ɵfac = function AppDateAdapter_Factory(t) { return ɵAppDateAdapter_BaseFactory(t || AppDateAdapter); };
AppDateAdapter.ɵprov = i0.ɵɵdefineInjectable({ token: AppDateAdapter, factory: AppDateAdapter.ɵfac });
const ɵAppDateAdapter_BaseFactory = i0.ɵɵgetInheritedFactory(AppDateAdapter);
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AppDateAdapter, [{
        type: core_1.Injectable
    }], null, null); })();
