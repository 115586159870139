"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const dnaR_input_type_enum_1 = require("../dnaR-input/enums/dnaR-input-type.enum");
exports.DefaultSettings = {
    placeholder: '',
    format: '',
    isRequired: false,
    requiredError: 'Input value is required.',
    inputType: dnaR_input_type_enum_1.DnaRInputType.text,
    formatError: "Wrong format",
    decimalPlaces: 2,
    disallowedValues: [''],
    isTooLong: false,
    tooLongError: "Input is too long",
    duplicateInputError: "Input must be unique"
};
