"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const i0 = require("@angular/core");
class ClickOutsideDirective {
    constructor(_elementRef) {
        this._elementRef = _elementRef;
        this.clickOutside = new core_1.EventEmitter();
    }
    onClick(event, targetElement) {
        if (!targetElement) {
            return;
        }
        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.clickOutside.emit(event);
        }
    }
}
exports.ClickOutsideDirective = ClickOutsideDirective;
ClickOutsideDirective.ɵfac = function ClickOutsideDirective_Factory(t) { return new (t || ClickOutsideDirective)(i0.ɵɵdirectiveInject(i0.ElementRef)); };
ClickOutsideDirective.ɵdir = i0.ɵɵdefineDirective({ type: ClickOutsideDirective, selectors: [["", "clickOutside", ""]], hostBindings: function ClickOutsideDirective_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("click", function ClickOutsideDirective_click_HostBindingHandler($event) { return ctx.onClick($event, $event.target); }, false, i0.ɵɵresolveDocument);
    } }, outputs: { clickOutside: "clickOutside" } });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ClickOutsideDirective, [{
        type: core_1.Directive,
        args: [{
                selector: '[clickOutside]'
            }]
    }], function () { return [{ type: i0.ElementRef }]; }, { clickOutside: [{
            type: core_1.Output
        }], onClick: [{
            type: core_1.HostListener,
            args: ['document:click', ['$event', '$event.target']]
        }] }); })();
