"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const common_1 = require("@angular/common");
const datepicker_1 = require("@angular/material/datepicker");
const dnaR_datePicker_component_1 = require("./dnaR-datePicker.component");
const core_2 = require("@angular/material/core");
const form_field_1 = require("@angular/material/form-field");
const input_1 = require("@angular/material/input");
const dnaR_datePicker_header_component_1 = require("./dnaR-datePicker-header.component");
const dnaR_dropdown_multiSelect_module_1 = require("../dnaR-dropdown-multiSelect/dnaR-dropdown-multiSelect.module");
const material_moment_adapter_1 = require("@angular/material-moment-adapter");
const forms_1 = require("@angular/forms");
const http_1 = require("@angular/common/http");
const animations_1 = require("@angular/platform-browser/animations");
const i0 = require("@angular/core");
class DnaRDatePickerModule {
    static forRoot() {
        return {
            ngModule: DnaRDatePickerModule
        };
    }
}
exports.DnaRDatePickerModule = DnaRDatePickerModule;
DnaRDatePickerModule.ɵmod = i0.ɵɵdefineNgModule({ type: DnaRDatePickerModule });
DnaRDatePickerModule.ɵinj = i0.ɵɵdefineInjector({ factory: function DnaRDatePickerModule_Factory(t) { return new (t || DnaRDatePickerModule)(); }, imports: [[
            common_1.CommonModule,
            forms_1.FormsModule,
            forms_1.ReactiveFormsModule,
            http_1.HttpClientModule,
            animations_1.BrowserAnimationsModule,
            dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule,
            datepicker_1.MatDatepickerModule,
            core_2.MatNativeDateModule,
            form_field_1.MatFormFieldModule,
            input_1.MatInputModule,
            material_moment_adapter_1.MomentDateModule
        ]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DnaRDatePickerModule, { declarations: [dnaR_datePicker_component_1.DnaRDatePicker, dnaR_datePicker_header_component_1.DnaRDatePickerHeaderComponent], imports: [common_1.CommonModule,
        forms_1.FormsModule,
        forms_1.ReactiveFormsModule,
        http_1.HttpClientModule,
        animations_1.BrowserAnimationsModule,
        dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule,
        datepicker_1.MatDatepickerModule,
        core_2.MatNativeDateModule,
        form_field_1.MatFormFieldModule,
        input_1.MatInputModule,
        material_moment_adapter_1.MomentDateModule], exports: [dnaR_datePicker_component_1.DnaRDatePicker, dnaR_datePicker_header_component_1.DnaRDatePickerHeaderComponent] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DnaRDatePickerModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [
                    common_1.CommonModule,
                    forms_1.FormsModule,
                    forms_1.ReactiveFormsModule,
                    http_1.HttpClientModule,
                    animations_1.BrowserAnimationsModule,
                    dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule,
                    datepicker_1.MatDatepickerModule,
                    core_2.MatNativeDateModule,
                    form_field_1.MatFormFieldModule,
                    input_1.MatInputModule,
                    material_moment_adapter_1.MomentDateModule
                ],
                declarations: [dnaR_datePicker_component_1.DnaRDatePicker, dnaR_datePicker_header_component_1.DnaRDatePickerHeaderComponent],
                exports: [dnaR_datePicker_component_1.DnaRDatePicker, dnaR_datePicker_header_component_1.DnaRDatePickerHeaderComponent],
                schemas: [
                    core_1.CUSTOM_ELEMENTS_SCHEMA
                ]
            }]
    }], null, null); })();
