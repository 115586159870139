"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const i0 = require("@angular/core");
class UsersService {
    constructor(dataService) {
        this.dataService = dataService;
        this.changePassword = (oldPassword, newPassword) => {
            return this.dataService.post("users/change-password", { oldPassword: oldPassword, newPassword: newPassword });
        };
        this.generateForgotPasswordLink = (email) => {
            return this.dataService.postAnonymous("users/generate-forgot-password-link?email=" + email, null);
        };
        this.resetPassword = (newPassword, salt) => {
            return this.dataService.postAnonymous("users/reset-password?newPassword=" + newPassword + "&salt=" + salt, null);
        };
        this.validateForgotPasswordLink = (salt) => {
            return this.dataService.getAnonymous("users/validate-forgot-password-link?salt=" + salt, false, null);
        };
    }
}
exports.UsersService = UsersService;
UsersService.ɵfac = function UsersService_Factory(t) { return new (t || UsersService)(i0.ɵɵinject('dataService')); };
UsersService.ɵprov = i0.ɵɵdefineInjectable({ token: UsersService, factory: UsersService.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(UsersService, [{
        type: core_1.Injectable
    }], function () { return [{ type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['dataService']
            }] }]; }, null); })();
