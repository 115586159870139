"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const i0 = require("@angular/core");
class ShowHidePasswordDirective {
    constructor(renderer, el) {
        this.renderer = renderer;
        this.el = el;
    }
    ngOnInit() {
        this.renderer.addClass(this.el.nativeElement, "show-hide-password");
        this.renderer.setAttribute(this.el.nativeElement, "type", "password");
        this.renderer.setAttribute(this.el.nativeElement, "autocomplete", "new-password"); //The Autocomplete off is not respected by browsers, but new-password sometimes is.
        this.showHideSpan = this.getShowHideToggleSpan();
        this.el.nativeElement.insertAdjacentElement('afterend', this.showHideSpan);
    }
    getShowHideToggleSpan() {
        const span = document.createElement('span');
        span.setAttribute('class', 'show-hide-switch');
        span.innerText = 'show';
        span.addEventListener("click", this.showHidePassword.bind(this));
        return span;
    }
    showHidePassword() {
        if (this.showHideSpan.innerText === "show") {
            this.showHideSpan.innerText = "hide";
            this.renderer.setAttribute(this.el.nativeElement, "type", "text");
        }
        else {
            this.showHideSpan.innerText = "show";
            this.renderer.setAttribute(this.el.nativeElement, "type", "password");
        }
    }
}
exports.ShowHidePasswordDirective = ShowHidePasswordDirective;
ShowHidePasswordDirective.ɵfac = function ShowHidePasswordDirective_Factory(t) { return new (t || ShowHidePasswordDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef)); };
ShowHidePasswordDirective.ɵdir = i0.ɵɵdefineDirective({ type: ShowHidePasswordDirective, selectors: [["", "showHidePassword", ""]] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ShowHidePasswordDirective, [{
        type: core_1.Directive,
        args: [{
                selector: '[showHidePassword]'
            }]
    }], function () { return [{ type: i0.Renderer2 }, { type: i0.ElementRef }]; }, null); })();
