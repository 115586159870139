"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
require("path");
const i0 = require("@angular/core");
class DnaRCurrencyInput {
    constructor(el, renderer) {
        this.el = el;
        this.renderer = renderer;
        this.noneCurrencyChars = new RegExp('[^0-9\.]', 'g'); // we're going to remove all non digit except for dot (.)
    }
    ngOnInit() {
        this.format(this.el.nativeElement.value); // format any initial values
    }
    onInput(e) {
        this.format(e);
    }
    ;
    onPaste(event) {
        event.preventDefault();
        if (event && event.clipboardData) {
            this.format(event.clipboardData.getData('text/plain'));
        }
    }
    format(val) {
        // 1. remove all unwanted chars
        const numbersOnly = String(val).replace(this.noneCurrencyChars, '');
        // 2. format the number (add commas)
        const result = this.numberWithCommas(numbersOnly);
        // 3. replace the input value with formatted numbers
        this.renderer.setProperty(this.el.nativeElement, 'value', result);
    }
    numberWithCommas(x) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }
}
exports.DnaRCurrencyInput = DnaRCurrencyInput;
DnaRCurrencyInput.ɵfac = function DnaRCurrencyInput_Factory(t) { return new (t || DnaRCurrencyInput)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2)); };
DnaRCurrencyInput.ɵdir = i0.ɵɵdefineDirective({ type: DnaRCurrencyInput, selectors: [["", "dnaRCurrencyInput", ""]], hostBindings: function DnaRCurrencyInput_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("input", function DnaRCurrencyInput_input_HostBindingHandler($event) { return ctx.onInput($event.target.value); })("paste", function DnaRCurrencyInput_paste_HostBindingHandler($event) { return ctx.onPaste($event); });
    } }, features: [i0.ɵɵProvidersFeature([])] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DnaRCurrencyInput, [{
        type: core_1.Directive,
        args: [{
                selector: '[dnaRCurrencyInput]',
                providers: []
            }]
    }], function () { return [{ type: i0.ElementRef }, { type: i0.Renderer2 }]; }, { onInput: [{
            type: core_1.HostListener,
            args: ['input', ["$event.target.value"]]
        }], onPaste: [{
            type: core_1.HostListener,
            args: ['paste', ['$event']]
        }] }); })();
