"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("underscore");
const core_1 = require("@angular/core");
const core_2 = require("@uirouter/core");
const Models_1 = require("../Models");
const billing_service_1 = require("../billing.service");
const data_source_1 = require("devextreme/data/data_source");
const array_store_1 = require("devextreme/data/array_store");
const i0 = require("@angular/core");
const i1 = require("@uirouter/core");
const i2 = require("../billing.service");
const i3 = require("@angular/forms");
const i4 = require("@angular/common");
const i5 = require("devextreme-angular");
const i6 = require("devextreme-angular/ui/nested");
const i7 = require("devextreme-angular/core");
const _c0 = function () { return ["name"]; };
const _c1 = function () { return { mode: "multiple", showCheckBoxesMode: "always" }; };
function SelectionCriteriaComponent_dx_drop_down_box_7_div_3_Template(rf, ctx) { if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "dx-data-grid", 21);
    i0.ɵɵlistener("onSelectionChanged", function SelectionCriteriaComponent_dx_drop_down_box_7_div_3_Template_dx_data_grid_onSelectionChanged_1_listener($event) { i0.ɵɵrestoreView(_r10); const ctx_r9 = i0.ɵɵnextContext(2); return ctx_r9.onFacilitiesSelectionChanged($event); })("selectedRowKeysChange", function SelectionCriteriaComponent_dx_drop_down_box_7_div_3_Template_dx_data_grid_selectedRowKeysChange_1_listener($event) { i0.ɵɵrestoreView(_r10); const ctx_r11 = i0.ɵɵnextContext(2); return ctx_r11.selectedFacilities = $event; });
    i0.ɵɵelement(2, "dxi-column", 22);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r7 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("dataSource", ctx_r7.facilities)("columns", i0.ɵɵpureFunction0(4, _c0))("selection", i0.ɵɵpureFunction0(5, _c1))("selectedRowKeys", ctx_r7.selectedFacilities);
} }
function SelectionCriteriaComponent_dx_drop_down_box_7_Template(rf, ctx) { if (rf & 1) {
    const _r13 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "dx-drop-down-box", 18);
    i0.ɵɵlistener("valueChange", function SelectionCriteriaComponent_dx_drop_down_box_7_Template_dx_drop_down_box_valueChange_0_listener($event) { i0.ɵɵrestoreView(_r13); const ctx_r12 = i0.ɵɵnextContext(); return ctx_r12.selectedFacilities = $event; });
    i0.ɵɵelementStart(1, "dx-validator");
    i0.ɵɵelement(2, "dxi-validation-rule", 19);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, SelectionCriteriaComponent_dx_drop_down_box_7_div_3_Template, 3, 6, "div", 20);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("value", ctx_r1.selectedFacilities)("width", 600)("dataSource", ctx_r1.facilitiesSource);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("dxTemplateOf", "content");
} }
function SelectionCriteriaComponent_span_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 23);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r2.facilities[0].name);
} }
function SelectionCriteriaComponent_dx_drop_down_box_12_Template(rf, ctx) { if (rf & 1) {
    const _r15 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "dx-drop-down-box", 24);
    i0.ɵɵlistener("valueChange", function SelectionCriteriaComponent_dx_drop_down_box_12_Template_dx_drop_down_box_valueChange_0_listener($event) { i0.ɵɵrestoreView(_r15); const ctx_r14 = i0.ɵɵnextContext(); return ctx_r14.selectedCareTypes = $event; });
    i0.ɵɵelementStart(1, "dx-validator");
    i0.ɵɵelement(2, "dxi-validation-rule", 19);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "dx-list", 25);
    i0.ɵɵlistener("onSelectionChanged", function SelectionCriteriaComponent_dx_drop_down_box_12_Template_dx_list_onSelectionChanged_3_listener($event) { i0.ɵɵrestoreView(_r15); const ctx_r16 = i0.ɵɵnextContext(); return ctx_r16.onCareTypesSelectionChanged($event); })("selectedItemsChange", function SelectionCriteriaComponent_dx_drop_down_box_12_Template_dx_list_selectedItemsChange_3_listener($event) { i0.ɵɵrestoreView(_r15); const ctx_r17 = i0.ɵɵnextContext(); return ctx_r17.selectedCareTypes = $event; });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("value", ctx_r3.selectedCareTypes)("width", 600)("dataSource", ctx_r3.careTypes);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("dataSource", ctx_r3.careTypes)("showSelectionControls", true)("selectedItems", ctx_r3.selectedCareTypes);
} }
function SelectionCriteriaComponent_span_13_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 23);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r4.careTypes[0]);
} }
function SelectionCriteriaComponent_dx_drop_down_box_17_Template(rf, ctx) { if (rf & 1) {
    const _r19 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "dx-drop-down-box", 24);
    i0.ɵɵlistener("valueChange", function SelectionCriteriaComponent_dx_drop_down_box_17_Template_dx_drop_down_box_valueChange_0_listener($event) { i0.ɵɵrestoreView(_r19); const ctx_r18 = i0.ɵɵnextContext(); return ctx_r18.selectedFeeTypes = $event; });
    i0.ɵɵelementStart(1, "dx-validator");
    i0.ɵɵelement(2, "dxi-validation-rule", 19);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "dx-list", 26);
    i0.ɵɵlistener("selectedItemsChange", function SelectionCriteriaComponent_dx_drop_down_box_17_Template_dx_list_selectedItemsChange_3_listener($event) { i0.ɵɵrestoreView(_r19); const ctx_r20 = i0.ɵɵnextContext(); return ctx_r20.selectedFeeTypes = $event; });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext();
    i0.ɵɵproperty("value", ctx_r5.selectedFeeTypes)("width", 600)("dataSource", ctx_r5.feeTypes);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("dataSource", ctx_r5.feeTypes)("showSelectionControls", true)("selectedItems", ctx_r5.selectedFeeTypes);
} }
function SelectionCriteriaComponent_span_18_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 23);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r6 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r6.feeTypes[0]);
} }
if (!/localhost/.test(document.location.host)) {
    core_1.enableProdMode();
}
class SelectionCriteriaComponent {
    constructor($state, uiRouter, billingService, facilityService, notificationService) {
        this.$state = $state;
        this.uiRouter = uiRouter;
        this.billingService = billingService;
        this.facilityService = facilityService;
        this.notificationService = notificationService;
        this.billingRun = new Models_1.BillingRunDto();
        this.facilities = [];
        this.selectedFacilities = [];
        this.careTypes = [];
        this.selectedCareTypes = [];
        this.feeTypes = [];
        this.selectedFeeTypes = [];
        this.initialSelectedCareTypesChanged = false;
        this.initialiseFacilities = () => {
            this.facilities = this.facilityService.activeUserFacilities;
            this.facilitiesSource = new data_source_1.default({
                store: new array_store_1.default({ data: this.facilities, key: "id" })
            });
            if (this.facilities.length == 1) {
                this.selectedFacilities = [];
                this.selectedFacilities.push(this.facilities[0]);
                this.initialiseCareTypes();
            }
        };
        this.initialiseCareTypes = () => {
            this.careTypes = [];
            let isResidentialFacilitySelected = _.some(this.selectedFacilities, x => { return x.facilityType == "AgeCare"; });
            if (isResidentialFacilitySelected) {
                this.careTypes.push(Models_1.CareType.Permanent);
                this.careTypes.push(Models_1.CareType.Respite);
                this.careTypes.push(Models_1.CareType.Private);
            }
            let isIluFacilitySelected = _.some(this.selectedFacilities, x => { return x.facilityType == "IndependentLiving"; });
            if (isIluFacilitySelected) {
                this.careTypes.push(Models_1.CareType.ILU);
            }
            if (this.careTypes.length == 1) {
                this.selectedCareTypes = [];
                this.selectedCareTypes.push(this.careTypes[0]);
                this.initialiseFeeTypes();
            }
        };
        this.initialiseFeeTypes = () => {
            this.feeTypes = this.billingService.getAllFeeTypesBySelectedCareTypes(this.selectedCareTypes);
            if (this.feeTypes.length == 1) {
                this.selectedFeeTypes = [];
                this.selectedFeeTypes.push(this.feeTypes[0]);
            }
        };
        this.onFacilitiesSelectionChanged = ($event) => {
            this.selectedCareTypes = [];
            this.initialiseCareTypes();
            this.selectedFeeTypes = [];
            this.initialiseFeeTypes();
            this.billingRun.residencyIds = [];
        };
        this.onCareTypesSelectionChanged = ($event) => {
            if (this.initialSelectedCareTypesChanged) {
                this.selectedFeeTypes = [];
                this.initialiseFeeTypes();
                this.billingRun.residencyIds = [];
            }
        };
        this.back = () => {
            this.billingRun.facilityIds = this.selectedFacilities.map(x => x.id);
            this.billingRun.careTypes = this.selectedCareTypes.map(x => Object.keys(Models_1.CareType).find(key => Models_1.CareType[key] === x));
            this.billingRun.feeTypes = this.selectedFeeTypes.map(x => Object.keys(Models_1.FeeType).find(key => Models_1.FeeType[key] === x));
            this.billingRun.status = Models_1.BillingRunStatus.AddDetails;
            this.billingService.saveBillingRun(this.billingRun).then((response) => {
                this.$state.go("createBillingRun.addDetails");
                this.billingService.notifyStateChange("createBillingRun.addDetails");
            }, () => {
                this.notificationService.error("Unexpected error while saving the billing run.");
            });
        };
        this.next = (isFormValid) => {
            if (isFormValid) {
                this.billingRun.facilityIds = this.selectedFacilities.map(x => x.id);
                this.billingRun.careTypes = this.selectedCareTypes.map(x => Object.keys(Models_1.CareType).find(key => Models_1.CareType[key] === x));
                this.billingRun.feeTypes = this.selectedFeeTypes.map(x => Object.keys(Models_1.FeeType).find(key => Models_1.FeeType[key] === x));
                this.billingRun.status = Models_1.BillingRunStatus.ConfirmResidents;
                this.billingService.saveBillingRun(this.billingRun).then((response) => {
                    this.$state.go("createBillingRun.confirmResidents");
                    this.billingService.notifyStateChange("createBillingRun.confirmResidents");
                }, () => {
                    this.notificationService.error("Unexpected error while saving the billing run.");
                });
            }
        };
        this.billingRunId = this.uiRouter.globals.params.id;
        this.billingService.notifyStateChange("createBillingRun.selectionCriteria");
    }
    ngOnInit() {
        this.billingService.getBillingRun(this.billingRunId).then((response) => {
            this.billingRun = response;
            this.initialiseFacilities();
            if (response.facilityIds != null)
                this.selectedFacilities = this.facilities.filter(x => _.some(response.facilityIds, y => { return y == x.id; }));
            this.initialiseCareTypes();
            if (response.careTypes != null)
                this.selectedCareTypes = response.careTypes.map(x => Models_1.CareType[x]);
            //wait for onCareTypesSelectionChanged event, to avoid the selectedFeeTypes from API to be wiped out.
            setTimeout(() => {
                this.initialSelectedCareTypesChanged = true;
                this.initialiseFeeTypes();
                if (response.feeTypes != null)
                    this.selectedFeeTypes = response.feeTypes.map(x => Models_1.FeeType[x]);
            }, 100);
        }, () => {
            this.notificationService.error("Unexpected error while getting the billing run.");
        });
    }
}
exports.SelectionCriteriaComponent = SelectionCriteriaComponent;
SelectionCriteriaComponent.ɵfac = function SelectionCriteriaComponent_Factory(t) { return new (t || SelectionCriteriaComponent)(i0.ɵɵdirectiveInject(i1.StateService), i0.ɵɵdirectiveInject(i1.UIRouter), i0.ɵɵdirectiveInject(i2.BillingService), i0.ɵɵdirectiveInject('facility.service'), i0.ɵɵdirectiveInject('notification.service')); };
SelectionCriteriaComponent.ɵcmp = i0.ɵɵdefineComponent({ type: SelectionCriteriaComponent, selectors: [["selection-criteria"]], decls: 36, vars: 13, consts: [[1, "selection-criteria"], ["novalidate", "", 3, "ngSubmit"], ["selectionCriteriaForm", "ngForm"], [1, "col-xs-8", "main-area"], [1, "col-xs-12"], ["for", "facility", 1, "control-label", "v-align-middle"], ["displayExpr", "name", 3, "value", "width", "dataSource", "valueChange", 4, "ngIf"], ["class", "readonly-selection", 4, "ngIf"], [1, "col-xs-12", "margin-top-40"], ["for", "careType", 1, "control-label", "v-align-middle"], [3, "value", "width", "dataSource", "valueChange", 4, "ngIf"], [1, "col-xs-4"], [1, "navigation-btn"], ["text", "Next", "type", "success", 3, "useSubmitBehavior"], ["type", "button", 1, "btn", "btn-secondary", "btn-lg", "pull-right", 3, "click"], [1, "col-xs-12", "billing-run-details-panel"], [1, "margin-top-15"], [1, "control-label", "v-align-middle"], ["displayExpr", "name", 3, "value", "width", "dataSource", "valueChange"], ["type", "required", "message", "This cannot be empty"], [4, "dxTemplate", "dxTemplateOf"], [3, "dataSource", "columns", "selection", "selectedRowKeys", "onSelectionChanged", "selectedRowKeysChange"], ["dataField", "name", "caption", "Facility name"], [1, "readonly-selection"], [3, "value", "width", "dataSource", "valueChange"], ["selectionMode", "all", 3, "dataSource", "showSelectionControls", "selectedItems", "onSelectionChanged", "selectedItemsChange"], ["selectionMode", "all", 3, "dataSource", "showSelectionControls", "selectedItems", "selectedItemsChange"]], template: function SelectionCriteriaComponent_Template(rf, ctx) { if (rf & 1) {
        const _r21 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "form", 1, 2);
        i0.ɵɵlistener("ngSubmit", function SelectionCriteriaComponent_Template_form_ngSubmit_1_listener() { i0.ɵɵrestoreView(_r21); const _r0 = i0.ɵɵreference(2); return ctx.next(_r0.form.valid); });
        i0.ɵɵelementStart(3, "div", 3);
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵelementStart(5, "label", 5);
        i0.ɵɵtext(6, "Facility");
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(7, SelectionCriteriaComponent_dx_drop_down_box_7_Template, 4, 4, "dx-drop-down-box", 6);
        i0.ɵɵtemplate(8, SelectionCriteriaComponent_span_8_Template, 2, 1, "span", 7);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(9, "div", 8);
        i0.ɵɵelementStart(10, "label", 9);
        i0.ɵɵtext(11, "Care type");
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(12, SelectionCriteriaComponent_dx_drop_down_box_12_Template, 4, 6, "dx-drop-down-box", 10);
        i0.ɵɵtemplate(13, SelectionCriteriaComponent_span_13_Template, 2, 1, "span", 7);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(14, "div", 8);
        i0.ɵɵelementStart(15, "label", 9);
        i0.ɵɵtext(16, "Fee type/s");
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(17, SelectionCriteriaComponent_dx_drop_down_box_17_Template, 4, 6, "dx-drop-down-box", 10);
        i0.ɵɵtemplate(18, SelectionCriteriaComponent_span_18_Template, 2, 1, "span", 7);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(19, "div", 11);
        i0.ɵɵelementStart(20, "div", 12);
        i0.ɵɵelement(21, "dx-button", 13);
        i0.ɵɵelementStart(22, "button", 14);
        i0.ɵɵlistener("click", function SelectionCriteriaComponent_Template_button_click_22_listener() { return ctx.back(); });
        i0.ɵɵtext(23, "Back");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(24, "div", 15);
        i0.ɵɵelementStart(25, "strong");
        i0.ɵɵtext(26);
        i0.ɵɵelementEnd();
        i0.ɵɵelement(27, "div", 16);
        i0.ɵɵtext(28);
        i0.ɵɵelement(29, "div", 16);
        i0.ɵɵelementStart(30, "label", 17);
        i0.ɵɵelementStart(31, "strong");
        i0.ɵɵtext(32, "Charge to date");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(33, "div");
        i0.ɵɵtext(34);
        i0.ɵɵpipe(35, "date");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(7);
        i0.ɵɵproperty("ngIf", ctx.facilities.length > 1);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.facilities.length == 1);
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("ngIf", ctx.careTypes.length > 1 || ctx.careTypes.length == 0);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.careTypes.length == 1);
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("ngIf", ctx.feeTypes.length > 1 || ctx.feeTypes.length == 0);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.feeTypes.length == 1);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("useSubmitBehavior", true);
        i0.ɵɵadvance(5);
        i0.ɵɵtextInterpolate(ctx.billingRun.name);
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate1(" ", ctx.billingRun.description, " ");
        i0.ɵɵadvance(6);
        i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(35, 10, ctx.billingRun.chargeToDateUtc, "dd MMM yyyy"));
    } }, directives: [i3.ɵangular_packages_forms_forms_y, i3.NgControlStatusGroup, i3.NgForm, i4.NgIf, i5.DxButtonComponent, i5.DxDropDownBoxComponent, i5.DxValidatorComponent, i6.DxiValidationRuleComponent, i7.DxTemplateDirective, i5.DxDataGridComponent, i6.DxiColumnComponent, i5.DxListComponent], pipes: [i4.DatePipe], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(SelectionCriteriaComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'selection-criteria',
                templateUrl: './selectionCriteria.component.html'
            }]
    }], function () { return [{ type: i1.StateService }, { type: i1.UIRouter }, { type: i2.BillingService }, { type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['facility.service']
            }] }, { type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['notification.service']
            }] }]; }, null); })();
