"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@uirouter/core");
const core_2 = require("@angular/core");
const __NgCli_bootstrap_1 = require("./app.module");
const __NgCli_bootstrap_2 = require("@angular/platform-browser");
const isLocal = /localhost/.test(document.location.host);
!isLocal && core_2.enableProdMode();
__NgCli_bootstrap_2.platformBrowser().bootstrapModule(__NgCli_bootstrap_1.AppModule).then(platformRef => {
    // get() the UIRouter instance from DI to initialize the router
    const urlService = platformRef.injector.get(core_1.UIRouter).urlService;
    // Instruct UIRouter to listen to URL changes
    function startUIRouter() {
        urlService.listen();
        urlService.sync();
    }
    platformRef.injector.get(core_2.NgZone).run(startUIRouter);
});
