"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const i0 = require("@angular/core");
class EnquiryAccessibilityRequirementDescription {
    transform(value) {
        if (!value) {
            return 'Unknown';
        }
        if (value === 'None') {
            return 'No requirements';
        }
        if (value === 'AccessibleUnit') {
            return 'Accessible unit required';
        }
        return value;
    }
}
exports.EnquiryAccessibilityRequirementDescription = EnquiryAccessibilityRequirementDescription;
EnquiryAccessibilityRequirementDescription.ɵfac = function EnquiryAccessibilityRequirementDescription_Factory(t) { return new (t || EnquiryAccessibilityRequirementDescription)(); };
EnquiryAccessibilityRequirementDescription.ɵpipe = i0.ɵɵdefinePipe({ name: "enquiryAccessibilityRequirementDescription", type: EnquiryAccessibilityRequirementDescription, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(EnquiryAccessibilityRequirementDescription, [{
        type: core_1.Pipe,
        args: [{ name: 'enquiryAccessibilityRequirementDescription' }]
    }], null, null); })();
