"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
require("../dtos/anacc-varaiable-component-dto");
require("../dtos/anacc-fixed-component-dto");
require("../dtos/anacc-fixed-component-funding-rate-dto");
require("angular");
const i0 = require("@angular/core");
/**
 * Performs funding related API requests.
 */
class FundingService {
    /**
     * Initializes a new instance of the FundingService class.
     *
     * @param dataService The data service.
     */
    constructor(dataService) {
        this.dataService = dataService;
        /**
         * Get AN-ACC Variable Components (aka AN-ACC classes) with the current rates.
         * Current rates are intended for indicative purposes.
         *
         * @param excludeDefaults Optionally exclude default AN-ACC Variable Components.
         * @param excludePalliative Optionally exclude palliative AN-ACC Variable Components.
         *
         * @returns An array of AN-ACC Variable Components.
         */
        this.getAnaccVariableComponents = (excludeDefaults = false, excludePalliative = false) => {
            return this.dataService.get('funding/anacc-variable-component', false, {
                excludeDefaults,
                excludePalliative
            });
        };
        /**
         * Get AN-ACC Fixed Components (aka MMM classifications).
         *
         * @returns An array of AN-ACC Fixed Components.
         */
        this.getAnaccFixedComponents = () => {
            return this.dataService.get('funding/anacc-fixed-component');
        };
        /**
         * Get AN-ACC Fixed Component (aka MMM classification), with the current funding rates, for the currently selected facility.
         * Rates are intended for indicative purposes.
         *
         * @returns The AN-ACC Fixed Component funding rates for the currently selected facility
         */
        this.getAnaccFixedComponentForCurrentFacility = () => {
            return this.dataService.get('funding/anacc-fixed-component/current');
        };
    }
}
exports.FundingService = FundingService;
FundingService.ɵfac = function FundingService_Factory(t) { return new (t || FundingService)(i0.ɵɵinject('dataService')); };
FundingService.ɵprov = i0.ɵɵdefineInjectable({ token: FundingService, factory: FundingService.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(FundingService, [{
        type: core_1.Injectable
    }], function () { return [{ type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['dataService']
            }] }]; }, null); })();
