"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const dialog_1 = require("@angular/material/dialog");
const facility_dto_1 = require("../../dtos/facility-dto");
const _ = require("underscore");
const i0 = require("@angular/core");
const i1 = require("@angular/material/dialog");
const i2 = require("@angular/material/button");
const i3 = require("@angular/material/tooltip");
const i4 = require("@angular/material/icon");
const i5 = require("@angular/forms");
const i6 = require("@angular/common");
function ValueWithEffectiveDateDialogComponent_th_12_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "th");
    i0.ɵɵelement(1, "i", 10);
    i0.ɵɵtext(2, "Precentage");
    i0.ɵɵelementEnd();
} }
function ValueWithEffectiveDateDialogComponent_th_13_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "th");
    i0.ɵɵelement(1, "i", 10);
    i0.ɵɵtext(2, "Amount");
    i0.ɵɵelementEnd();
} }
function ValueWithEffectiveDateDialogComponent_th_14_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "th");
    i0.ɵɵelement(1, "i", 10);
    i0.ɵɵtext(2, "Rate");
    i0.ɵɵelementEnd();
} }
function ValueWithEffectiveDateDialogComponent_tr_20_td_1_div_3_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, "Amount required");
    i0.ɵɵelementEnd();
} }
function ValueWithEffectiveDateDialogComponent_tr_20_td_1_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 29);
    i0.ɵɵtemplate(1, ValueWithEffectiveDateDialogComponent_tr_20_td_1_div_3_div_1_Template, 2, 0, "div", 9);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵnextContext();
    const _r11 = i0.ɵɵreference(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", _r11 == null ? null : _r11.errors == null ? null : _r11.errors.required);
} }
function ValueWithEffectiveDateDialogComponent_tr_20_td_1_Template(rf, ctx) { if (rf & 1) {
    const _r16 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "td", 21);
    i0.ɵɵelementStart(1, "input", 27, 28);
    i0.ɵɵlistener("ngModelChange", function ValueWithEffectiveDateDialogComponent_tr_20_td_1_Template_input_ngModelChange_1_listener($event) { i0.ɵɵrestoreView(_r16); const valuesWithEffectiveDate_r5 = i0.ɵɵnextContext().$implicit; return valuesWithEffectiveDate_r5.value = $event; });
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, ValueWithEffectiveDateDialogComponent_tr_20_td_1_div_3_Template, 2, 1, "div", 24);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const _r11 = i0.ɵɵreference(2);
    const valuesWithEffectiveDate_r5 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵnextContext();
    const _r0 = i0.ɵɵreference(7);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngModel", valuesWithEffectiveDate_r5.value);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", _r0.submitted || (_r11 == null ? null : _r11.touched));
} }
function ValueWithEffectiveDateDialogComponent_tr_20_td_2_div_3_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, "Amount required");
    i0.ɵɵelementEnd();
} }
function ValueWithEffectiveDateDialogComponent_tr_20_td_2_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 29);
    i0.ɵɵtemplate(1, ValueWithEffectiveDateDialogComponent_tr_20_td_2_div_3_div_1_Template, 2, 0, "div", 9);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵnextContext();
    const _r18 = i0.ɵɵreference(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", _r18 == null ? null : _r18.errors == null ? null : _r18.errors.required);
} }
function ValueWithEffectiveDateDialogComponent_tr_20_td_2_Template(rf, ctx) { if (rf & 1) {
    const _r23 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "td", 21);
    i0.ɵɵelementStart(1, "input", 30, 28);
    i0.ɵɵlistener("ngModelChange", function ValueWithEffectiveDateDialogComponent_tr_20_td_2_Template_input_ngModelChange_1_listener($event) { i0.ɵɵrestoreView(_r23); const valuesWithEffectiveDate_r5 = i0.ɵɵnextContext().$implicit; return valuesWithEffectiveDate_r5.value = $event; });
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, ValueWithEffectiveDateDialogComponent_tr_20_td_2_div_3_Template, 2, 1, "div", 24);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const _r18 = i0.ɵɵreference(2);
    const valuesWithEffectiveDate_r5 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵnextContext();
    const _r0 = i0.ɵɵreference(7);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngModel", valuesWithEffectiveDate_r5.value);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", _r0.submitted || (_r18 == null ? null : _r18.touched));
} }
function ValueWithEffectiveDateDialogComponent_tr_20_td_3_div_1_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, "Amount required");
    i0.ɵɵelementEnd();
} }
function ValueWithEffectiveDateDialogComponent_tr_20_td_3_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 29);
    i0.ɵɵtemplate(1, ValueWithEffectiveDateDialogComponent_tr_20_td_3_div_1_div_1_Template, 2, 0, "div", 9);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r25 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r25.amount == null ? null : ctx_r25.amount.errors == null ? null : ctx_r25.amount.errors.required);
} }
function ValueWithEffectiveDateDialogComponent_tr_20_td_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "td", 21);
    i0.ɵɵtemplate(1, ValueWithEffectiveDateDialogComponent_tr_20_td_3_div_1_Template, 2, 1, "div", 24);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r8 = i0.ɵɵnextContext(2);
    const _r0 = i0.ɵɵreference(7);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", _r0.submitted || (ctx_r8.amount == null ? null : ctx_r8.amount.touched));
} }
function ValueWithEffectiveDateDialogComponent_tr_20_div_9_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, "Effective date required");
    i0.ɵɵelementEnd();
} }
function ValueWithEffectiveDateDialogComponent_tr_20_div_9_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, "Start date must be after the previous record");
    i0.ɵɵelementEnd();
} }
function ValueWithEffectiveDateDialogComponent_tr_20_div_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 29);
    i0.ɵɵtemplate(1, ValueWithEffectiveDateDialogComponent_tr_20_div_9_div_1_Template, 2, 0, "div", 9);
    i0.ɵɵtemplate(2, ValueWithEffectiveDateDialogComponent_tr_20_div_9_div_2_Template, 2, 0, "div", 9);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵnextContext();
    const _r9 = i0.ɵɵreference(7);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", _r9 == null ? null : _r9.errors == null ? null : _r9.errors.required);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", _r9 == null ? null : _r9.errors == null ? null : _r9.errors.dateRange);
} }
function ValueWithEffectiveDateDialogComponent_tr_20_Template(rf, ctx) { if (rf & 1) {
    const _r30 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "tr", 18);
    i0.ɵɵtemplate(1, ValueWithEffectiveDateDialogComponent_tr_20_td_1_Template, 4, 2, "td", 19);
    i0.ɵɵtemplate(2, ValueWithEffectiveDateDialogComponent_tr_20_td_2_Template, 4, 2, "td", 19);
    i0.ɵɵtemplate(3, ValueWithEffectiveDateDialogComponent_tr_20_td_3_Template, 2, 1, "td", 19);
    i0.ɵɵelementStart(4, "td", 20);
    i0.ɵɵelementStart(5, "div", 21);
    i0.ɵɵelementStart(6, "input", 22, 23);
    i0.ɵɵlistener("ngModelChange", function ValueWithEffectiveDateDialogComponent_tr_20_Template_input_ngModelChange_6_listener($event) { i0.ɵɵrestoreView(_r30); const valuesWithEffectiveDate_r5 = ctx.$implicit; return valuesWithEffectiveDate_r5.startDate = $event; });
    i0.ɵɵpipe(8, "date");
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(9, ValueWithEffectiveDateDialogComponent_tr_20_div_9_Template, 3, 2, "div", 24);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(10, "td");
    i0.ɵɵelementStart(11, "button", 25);
    i0.ɵɵlistener("click", function ValueWithEffectiveDateDialogComponent_tr_20_Template_button_click_11_listener() { i0.ɵɵrestoreView(_r30); const valuesWithEffectiveDate_r5 = ctx.$implicit; const ctx_r31 = i0.ɵɵnextContext(); return ctx_r31.removeRow(valuesWithEffectiveDate_r5); });
    i0.ɵɵelement(12, "div", 26);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const valuesWithEffectiveDate_r5 = ctx.$implicit;
    const _r9 = i0.ɵɵreference(7);
    const ctx_r4 = i0.ɵɵnextContext();
    const _r0 = i0.ɵɵreference(7);
    i0.ɵɵpropertyInterpolate("ngModelGroup", valuesWithEffectiveDate_r5._id);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r4.isPercentageValueType());
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r4.isIntegerValueType());
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r4.isCurrencyValueType());
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngModel", i0.ɵɵpipeBind2(8, 6, valuesWithEffectiveDate_r5.startDate, "yyyy-MM-dd"));
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", _r0.submitted || (_r9 == null ? null : _r9.touched));
} }
/**
 *  Maintains a set of @see ValuesWithEffectiveDate within a dialog.
 */
class ValueWithEffectiveDateDialogComponent {
    /**
     * Initializes a new instance of the ValuesWithEffectiveDateDialogComponent class.
     *
     * @param dialogRef - The reference to the @see MatDialogRef<ValuesWithEffectiveDateDialogComponent> dialog.
     * @param data - The provided set of @see ValuesWithEffectiveDate.
     */
    constructor(dialogRef, data) {
        this.dialogRef = dialogRef;
        /**
         * The next unique id for new @see ValuesWithEffectiveDate.
         */
        this.nextUniqueId = 1;
        /**
         * Add a new @see ValuesWithEffectiveDate to the top of the collection.
         */
        this.addNewRow = () => {
            // Template requires an unique identifier for the form group for each record. _id provides this. 
            let newValuesWithEffectiveDateDto = new facility_dto_1.ValuesWithEffectiveDateDto();
            newValuesWithEffectiveDateDto["_id"] = `new_${this.nextUniqueId}`;
            this.nextUniqueId++;
            this.valuesWithEffectiveDates.unshift(newValuesWithEffectiveDateDto);
        };
        /**
         * Removes the provided @see ValuesWithEffectiveDate from the set.
         *
         * @param valuesWithEffectiveDateDto
         */
        this.removeRow = (valuesWithEffectiveDateDto) => {
            const index = _.indexOf(this.valuesWithEffectiveDates, valuesWithEffectiveDateDto);
            this.valuesWithEffectiveDates.splice(index, 1);
        };
        this.title = data.title;
        this.itemLabel = data.itemLabel;
        this.valueType = data.valueType;
        // Deep copy of array to allow discarding of changes on cancel.
        this.valuesWithEffectiveDates = JSON.parse(JSON.stringify(data.valuesWithEffectiveDates));
        // Template requires an unique identifier for the form group for each record. _id provides this. 
        this.valuesWithEffectiveDates.forEach((valuesWithEffectiveDate) => {
            if (valuesWithEffectiveDate.id) {
                valuesWithEffectiveDate["_id"] = valuesWithEffectiveDate.id;
            }
            else {
                valuesWithEffectiveDate["_id"] = `new_${this.nextUniqueId}`;
                this.nextUniqueId++;
            }
        });
    }
    /**
     * @inheritdoc
     */
    ngOnInit() {
        if (this.valuesWithEffectiveDates.length === 0) {
            this.addNewRow();
        }
    }
    /**
     * If the form is valid, close the dialog and passing back the new set of @see ValuesWithEffectiveDate indicating that the changes will be accepted.
     *
     * @param isValid truthy if the form is valid, otherwise falsy.
     */
    save(isValid) {
        if (isValid) {
            this.dialogRef.close(new ValueWithEffectiveDateDialogResult(false, this.valuesWithEffectiveDates));
        }
    }
    /**
     * Close the dialog indicating that no changes.
     */
    cancel() {
        this.dialogRef.close(new ValueWithEffectiveDateDialogResult(true));
    }
    /**
     * Checks if the value type is @see ValueWithEffectiveDateDialogComponentValueType.Percentage.
     *
     * @returns true if the value type is @see ValueWithEffectiveDateDialogComponentValueType.Percentage, otherwise false.
     */
    isPercentageValueType() {
        return this.valueType === ValueWithEffectiveDateDialogComponentValueType.Percentage;
    }
    /**
     * Checks if the value type is @see ValueWithEffectiveDateDialogComponentValueType.Integer.
     *
     * @returns true if the value type is @see ValueWithEffectiveDateDialogComponentValueType.Integer, otherwise false.
     */
    isIntegerValueType() {
        return this.valueType === ValueWithEffectiveDateDialogComponentValueType.Integer;
    }
    /**
     * Checks if the value type is @see ValueWithEffectiveDateDialogComponentValueType.Currency.
     *
     * @returns true if the value type is @see ValueWithEffectiveDateDialogComponentValueType.Currency, otherwise false.
     */
    isCurrencyValueType() {
        return this.valueType === ValueWithEffectiveDateDialogComponentValueType.Currency;
    }
}
exports.ValueWithEffectiveDateDialogComponent = ValueWithEffectiveDateDialogComponent;
ValueWithEffectiveDateDialogComponent.ɵfac = function ValueWithEffectiveDateDialogComponent_Factory(t) { return new (t || ValueWithEffectiveDateDialogComponent)(i0.ɵɵdirectiveInject(i1.MatDialogRef), i0.ɵɵdirectiveInject(dialog_1.MAT_DIALOG_DATA)); };
ValueWithEffectiveDateDialogComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ValueWithEffectiveDateDialogComponent, selectors: [["value-with-effective-date"]], decls: 30, vars: 6, consts: [["mat-dialog-title", "", 1, "dna-r"], [1, "modal-title"], ["mat-icon-button", "", "matTooltip", "Cancel", "matTooltipClass", "mat-tooltip", "aria-label", "close dialog", 1, "close-modal", 3, "click"], [1, "icon", "icon-close-fill"], [1, "dna-r", "value-with-effective-date"], ["novalidate", "", "name", "valueWithEffectiveDateForm"], ["valueWithEffectiveDateForm", "ngForm"], [1, "modal-row", "input-table", "scrollable"], [1, "table"], [4, "ngIf"], [1, "required-field"], [3, "ngModelGroup", 4, "ngFor", "ngForOf"], [1, "add-row-container"], [1, "btn", "btn-small", "btn-clear", "btn-clear-turquoise", 3, "click"], [1, "icon", "icon-add-circle-fill"], [1, "dna-r"], [1, "btn", "btn-warning", 3, "click"], [1, "btn", "btn-primary", 3, "click"], [3, "ngModelGroup"], ["class", "view-value", 4, "ngIf"], [1, "calander-container"], [1, "view-value"], ["type", "date", "id", "startDate", "name", "startDate", 1, "form-control", 3, "ngModel", "ngModelChange"], ["startDate", "ngModel"], ["class", "text-danger", 4, "ngIf"], ["mat-icon-button", "", 1, "delete-value", 3, "click"], [1, "icon", "icon-delete-fill", "icon-neutral-n3"], ["id", "amount", "name", "amount", "type", "number", "min", "0", "max", "100", "step", "0.01", "required", "", 1, "form-control", 3, "ngModel", "ngModelChange"], ["amount", "ngModel"], [1, "text-danger"], ["id", "amount", "name", "amount", "type", "number", "min", "0", "required", "", "maxlength", "5", 1, "form-control", 3, "ngModel", "ngModelChange"]], template: function ValueWithEffectiveDateDialogComponent_Template(rf, ctx) { if (rf & 1) {
        const _r32 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "h3", 1);
        i0.ɵɵtext(2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(3, "button", 2);
        i0.ɵɵlistener("click", function ValueWithEffectiveDateDialogComponent_Template_button_click_3_listener() { return ctx.cancel(); });
        i0.ɵɵelement(4, "mat-icon", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "mat-dialog-content", 4);
        i0.ɵɵelementStart(6, "form", 5, 6);
        i0.ɵɵelementStart(8, "div", 7);
        i0.ɵɵelementStart(9, "table", 8);
        i0.ɵɵelementStart(10, "thead");
        i0.ɵɵelementStart(11, "tr");
        i0.ɵɵtemplate(12, ValueWithEffectiveDateDialogComponent_th_12_Template, 3, 0, "th", 9);
        i0.ɵɵtemplate(13, ValueWithEffectiveDateDialogComponent_th_13_Template, 3, 0, "th", 9);
        i0.ɵɵtemplate(14, ValueWithEffectiveDateDialogComponent_th_14_Template, 3, 0, "th", 9);
        i0.ɵɵelementStart(15, "th");
        i0.ɵɵelement(16, "i", 10);
        i0.ɵɵtext(17, "Effective Date");
        i0.ɵɵelementEnd();
        i0.ɵɵelement(18, "th");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(19, "tbody");
        i0.ɵɵtemplate(20, ValueWithEffectiveDateDialogComponent_tr_20_Template, 13, 9, "tr", 11);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(21, "div", 12);
        i0.ɵɵelementStart(22, "a", 13);
        i0.ɵɵlistener("click", function ValueWithEffectiveDateDialogComponent_Template_a_click_22_listener() { return ctx.addNewRow(); });
        i0.ɵɵelement(23, "div", 14);
        i0.ɵɵtext(24);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(25, "mat-dialog-actions", 15);
        i0.ɵɵelementStart(26, "button", 16);
        i0.ɵɵlistener("click", function ValueWithEffectiveDateDialogComponent_Template_button_click_26_listener() { return ctx.cancel(); });
        i0.ɵɵtext(27, "Cancel");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(28, "button", 17);
        i0.ɵɵlistener("click", function ValueWithEffectiveDateDialogComponent_Template_button_click_28_listener() { i0.ɵɵrestoreView(_r32); const _r0 = i0.ɵɵreference(7); return ctx.save(_r0.valid); });
        i0.ɵɵtext(29, "Done");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate(ctx.title);
        i0.ɵɵadvance(10);
        i0.ɵɵproperty("ngIf", ctx.isPercentageValueType());
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.isIntegerValueType());
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.isCurrencyValueType());
        i0.ɵɵadvance(6);
        i0.ɵɵproperty("ngForOf", ctx.valuesWithEffectiveDates);
        i0.ɵɵadvance(4);
        i0.ɵɵtextInterpolate1(" New ", ctx.itemLabel, " ");
    } }, directives: [i1.MatDialogTitle, i2.MatButton, i3.MatTooltip, i4.MatIcon, i1.MatDialogContent, i5.ɵangular_packages_forms_forms_y, i5.NgControlStatusGroup, i5.NgForm, i6.NgIf, i6.NgForOf, i1.MatDialogActions, i5.NgModelGroup, i5.DefaultValueAccessor, i5.NgControlStatus, i5.NgModel, i5.NumberValueAccessor, i5.RequiredValidator, i5.MaxLengthValidator], pipes: [i6.DatePipe], styles: [".value-with-effective-date[_ngcontent-%COMP%]     .table {\n  background-color: white;\n}\n.value-with-effective-date[_ngcontent-%COMP%]     .add-row-container {\n  margin-top: 16px;\n  text-align: right;\n}\n.value-with-effective-date[_ngcontent-%COMP%]     .delete-value .icon {\n  vertical-align: text-bottom;\n}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ValueWithEffectiveDateDialogComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'value-with-effective-date',
                styleUrls: ['./value-with-effective-date.component.less'],
                templateUrl: './value-with-effective-date.component.html',
            }]
    }], function () { return [{ type: i1.MatDialogRef }, { type: undefined, decorators: [{
                type: core_1.Inject,
                args: [dialog_1.MAT_DIALOG_DATA]
            }] }]; }, null); })();
/**
 * Possible value types.
 */
var ValueWithEffectiveDateDialogComponentValueType;
(function (ValueWithEffectiveDateDialogComponentValueType) {
    ValueWithEffectiveDateDialogComponentValueType[ValueWithEffectiveDateDialogComponentValueType["Percentage"] = 0] = "Percentage";
    ValueWithEffectiveDateDialogComponentValueType[ValueWithEffectiveDateDialogComponentValueType["Integer"] = 1] = "Integer";
    ValueWithEffectiveDateDialogComponentValueType[ValueWithEffectiveDateDialogComponentValueType["Currency"] = 2] = "Currency";
})(ValueWithEffectiveDateDialogComponentValueType = exports.ValueWithEffectiveDateDialogComponentValueType || (exports.ValueWithEffectiveDateDialogComponentValueType = {}));
/**
 * The results of the @see ValuesWithEffectiveDateDialogComponent.
 */
class ValueWithEffectiveDateDialogResult {
    /**
     * Initializes a new instance of the ValueWithEffectiveDateDialogResult class.
     *
     * @param cancelled - true if the dialog was cancelled, otherwise false.
     * @param data - The new set of @see ValuesWithEffectiveDate containing all the changes made be the use in the dialog. null if the dialog was cancelled.
     */
    constructor(cancelled, data = null) {
        this.cancelled = cancelled;
        this.data = data;
    }
}
exports.ValueWithEffectiveDateDialogResult = ValueWithEffectiveDateDialogResult;
