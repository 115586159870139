"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const simple_enquiry_dto_1 = require("../dtos/simple-enquiry-dto");
const dnaR_action_modal_component_1 = require("../../common/components/dnaR-action-modal/dnaR-action-modal.component");
const dialog_1 = require("@angular/material/dialog");
const i0 = require("@angular/core");
const i1 = require("@angular/material/dialog");
const i2 = require("@angular/common");
function IluLeadCardDropdownComponent_li_3_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 2);
    i0.ɵɵlistener("click", function IluLeadCardDropdownComponent_li_3_Template_li_click_0_listener() { i0.ɵɵrestoreView(_r4); const ctx_r3 = i0.ɵɵnextContext(); return ctx_r3.archive(); });
    i0.ɵɵtext(1, "Archive");
    i0.ɵɵelementEnd();
} }
function IluLeadCardDropdownComponent_li_4_Template(rf, ctx) { if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 2);
    i0.ɵɵlistener("click", function IluLeadCardDropdownComponent_li_4_Template_li_click_0_listener() { i0.ɵɵrestoreView(_r6); const ctx_r5 = i0.ɵɵnextContext(); return ctx_r5.openDeleteEnquiryDialog(); });
    i0.ɵɵtext(1, "Delete");
    i0.ɵɵelementEnd();
} }
function IluLeadCardDropdownComponent_li_5_Template(rf, ctx) { if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 2);
    i0.ɵɵlistener("click", function IluLeadCardDropdownComponent_li_5_Template_li_click_0_listener() { i0.ɵɵrestoreView(_r8); const ctx_r7 = i0.ɵɵnextContext(); return ctx_r7.reinstate(); });
    i0.ɵɵtext(1, "Reinstate lead");
    i0.ɵɵelementEnd();
} }
/**
 * Displays a more menu for an ILU enquiry / lead.
 *
 * @description Used for both archive and not archive ILU enquiry / leads.
 */
class IluLeadCardDropdownComponent {
    /**
     * Initializes a new instance of the IluLeadCardDropdownComponent class.
     *
     * @param dialog The material design dialog.
     */
    constructor(dialog) {
        this.dialog = dialog;
        /**
         * Event emitted when the user selects the delete menu item.
         */
        this.onDelete = new core_1.EventEmitter();
        /**
         * Event emitted when the user selects the archive menu item.
         */
        this.onArchive = new core_1.EventEmitter();
        /**
         * Event emitted when the user selects the reinstate menu item.
         */
        this.onReinstateLead = new core_1.EventEmitter();
        /**
         * Displays a delete confirmation dialog.
         */
        this.openDeleteEnquiryDialog = () => {
            const names = simple_enquiry_dto_1.getEnquiryNames(this.enquiry);
            const dialogData = {
                title: "Delete enquiry",
                text: "Are you sure you want to delete the enquiry for " + names + "?",
                action: "Delete",
                actionButtonClass: "btn-primary",
                actionFunction: () => this.delete()
            };
            this.dialog.open(dnaR_action_modal_component_1.DnaRActionModalComponent, {
                panelClass: 'custom-dialog-container',
                width: '456px',
                height: '250px',
                data: dialogData
            });
        };
    }
    /**
     * Returns if an ILU enquiry / lead can be deleted.
     *
     * @returns true if the ILU enquiry can be safely deleted, otherwise false.
     *
     * @description Relies on the value of canDeleteLeads, which is expected to be set using the current user's permissoon, and if the enquiry / lead has been converted.
     */
    canDeleteEnquiry() {
        return this.canDeleteLeads && !this.enquiry.isLeadConverted;
    }
    /**
     * Emits an event notifying that the user selected the delete menu item.
     */
    delete() {
        this.onDelete.emit(this.enquiry);
    }
    /**
     * Emits an event notifying that the user selected the archive menu item.
     */
    archive() {
        this.onArchive.emit(this.enquiry);
    }
    /**
     * Emits an event notifying that the user selected the reinstate menu item.
     */
    reinstate() {
        this.onReinstateLead.emit(this.enquiry);
    }
}
exports.IluLeadCardDropdownComponent = IluLeadCardDropdownComponent;
IluLeadCardDropdownComponent.ɵfac = function IluLeadCardDropdownComponent_Factory(t) { return new (t || IluLeadCardDropdownComponent)(i0.ɵɵdirectiveInject(i1.MatDialog)); };
IluLeadCardDropdownComponent.ɵcmp = i0.ɵɵdefineComponent({ type: IluLeadCardDropdownComponent, selectors: [["ilu-lead-card-dropdown"]], inputs: { enquiry: "enquiry", canDeleteLeads: "canDeleteLeads" }, outputs: { onDelete: "onDelete", onArchive: "onArchive", onReinstateLead: "onReinstateLead" }, decls: 6, vars: 3, consts: [[1, "ilu-more-menu-template"], [3, "click", 4, "ngIf"], [3, "click"]], template: function IluLeadCardDropdownComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div");
        i0.ɵɵelementStart(2, "ul");
        i0.ɵɵtemplate(3, IluLeadCardDropdownComponent_li_3_Template, 2, 0, "li", 1);
        i0.ɵɵtemplate(4, IluLeadCardDropdownComponent_li_4_Template, 2, 0, "li", 1);
        i0.ɵɵtemplate(5, IluLeadCardDropdownComponent_li_5_Template, 2, 0, "li", 1);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", !ctx.enquiry.isArchived);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.canDeleteEnquiry());
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.enquiry.isArchived);
    } }, directives: [i2.NgIf], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(IluLeadCardDropdownComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'ilu-lead-card-dropdown',
                templateUrl: 'ilu-lead-card-dropdown.component.html',
            }]
    }], function () { return [{ type: i1.MatDialog }]; }, { enquiry: [{
            type: core_1.Input
        }], canDeleteLeads: [{
            type: core_1.Input
        }], onDelete: [{
            type: core_1.Output
        }], onArchive: [{
            type: core_1.Output
        }], onReinstateLead: [{
            type: core_1.Output
        }] }); })();
