"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("@uirouter/angular");
const accommodation_preferences_component_1 = require("./accommodation-preferences/components/accommodation-preferences.component");
const ilu_leads_dashboard_component_1 = require("./dashboard/ilu-leads-dashboard.component");
const archived_leads_component_1 = require("./archived-leads/components/archived-leads.component");
exports.states = [
    {
        name: "accommodationPreferences",
        url: "/accommodationPreferences",
        component: accommodation_preferences_component_1.AccommodationPreferencesComponent,
    },
    {
        name: "viewEnquiries",
        url: "/independentLiving/viewEnquiries?location&unitType&mobility&tag",
        component: ilu_leads_dashboard_component_1.ILULeadsDashboardComponent,
        params: {
            location: {
                type: 'int',
                array: true,
                value: [],
                squash: true
            },
            unitType: {
                type: 'int',
                array: true,
                value: [],
                squash: true
            },
            mobility: {
                type: 'int',
                array: true,
                value: [],
                squash: true
            },
            tag: {
                type: 'int',
                array: true,
                value: [],
                squash: true
            },
        },
        dynamic: true
    },
    {
        name: "viewArchivedEnquiries",
        url: "/independentLiving/viewEnquiries/archived",
        component: archived_leads_component_1.ArchivedLeadsComponent,
    },
];
