"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * The ILU lead dashboard filter definition.
 */
class ILULeadsDashboardFilter {
    constructor() {
        /**
         * The facility locations to filter on.
         */
        this.facilityLocations = [];
        /**
         * The unit type to filter on.
         */
        this.unitTypes = [];
        /**
         * The accessibility requirements to filter on.
         */
        this.accessibilityRequirements = [];
        /**
         * The tags to filter on.
         */
        this.tags = [];
    }
}
exports.ILULeadsDashboardFilter = ILULeadsDashboardFilter;
