"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class BedHistoryDto {
    constructor(startDateUtc, endDateUtc, id, bedId, residencyId) {
        this.startDateUtc = startDateUtc;
        this.endDateUtc = endDateUtc;
        this.id = id;
        this.bedId = bedId;
        this.residencyId = residencyId;
    }
}
exports.BedHistoryDto = BedHistoryDto;
