"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ListItem {
    constructor(source) {
        if (typeof source === 'string') {
            this.id = this.text = source;
        }
        if (typeof source === 'object') {
            this.id = source.id;
            this.text = source.text;
            this.isHeader = source.isHeader;
        }
    }
}
exports.ListItem = ListItem;
