"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class FacilityDto {
}
exports.FacilityDto = FacilityDto;
class MtcfsReportFilter {
}
exports.MtcfsReportFilter = MtcfsReportFilter;
class CareMinutesReportFilter {
}
exports.CareMinutesReportFilter = CareMinutesReportFilter;
class MtcfsReportDto {
}
exports.MtcfsReportDto = MtcfsReportDto;
class CareMinutesReportDto {
}
exports.CareMinutesReportDto = CareMinutesReportDto;
class ResidentFinanceEffectiveDate {
}
exports.ResidentFinanceEffectiveDate = ResidentFinanceEffectiveDate;
class UpdateMtcfEffectiveDatesDto {
}
exports.UpdateMtcfEffectiveDatesDto = UpdateMtcfEffectiveDatesDto;
class ResidentCardsReportFilter {
}
exports.ResidentCardsReportFilter = ResidentCardsReportFilter;
class RoomsReportFilter {
}
exports.RoomsReportFilter = RoomsReportFilter;
class RoomTypeDto {
}
exports.RoomTypeDto = RoomTypeDto;
class MovementsReportFilter {
}
exports.MovementsReportFilter = MovementsReportFilter;
