"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("@uirouter/angular");
const organisation_details_component_1 = require("./organisation-details/organisation-details.component");
const edit_organisation_details_component_1 = require("./organisation-details/edit-organisation-details.component");
const deviceActivation_component_1 = require("./deviceActivation/deviceActivation.component");
exports.states = [
    {
        name: "organisationDetails",
        url: "/admin/organisationDetails",
        component: organisation_details_component_1.OrganisationDetailsComponent,
        resolve: {
            authorized: ["authentication.service", auth => {
                    return auth.userHasPermissionDeferred(x => { return x.organisationDetails.canView; });
                }]
        }
    },
    {
        name: "editOrganisationDetails",
        url: "/admin/organisationDetails/edit",
        component: edit_organisation_details_component_1.EditOrganisationDetailsComponent,
        params: {
            org: null
        },
        resolve: {
            authorized: ["authentication.service", auth => {
                    return auth.userHasPermissionDeferred(x => { return x.organisationDetails.canModify; });
                }]
        }
    },
    {
        name: "deviceActivation",
        url: "/admin/deviceActivation",
        component: deviceActivation_component_1.DeviceActivationComponent,
        resolve: {
            authorized: ["authentication.service", auth => {
                    return auth.userHasPermissionDeferred(x => { return x.deviceActivation.canAction; });
                }]
        }
    }
];
