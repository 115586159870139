"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("moment");
require("underscore");
const core_1 = require("@angular/core");
const core_2 = require("@uirouter/core");
const billing_service_1 = require("../billing.service");
const devextreme_angular_1 = require("devextreme-angular");
const Models_1 = require("../Models");
const i0 = require("@angular/core");
const i1 = require("@uirouter/core");
const i2 = require("../billing.service");
const i3 = require("@angular/common");
const i4 = require("devextreme-angular");
const i5 = require("devextreme-angular/ui/nested");
const i6 = require("devextreme-angular/core");
function ViewBillingRunsComponent_dx_data_grid_10_div_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "p");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const data_r5 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵclassMap(data_r5.data.status == "Draft" ? "status_draft" : "status_committed");
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(data_r5.data.status);
} }
function ViewBillingRunsComponent_dx_data_grid_10_div_11_Template(rf, ctx) { if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "a", 21);
    i0.ɵɵlistener("click", function ViewBillingRunsComponent_dx_data_grid_10_div_11_Template_a_click_1_listener() { i0.ɵɵrestoreView(_r8); const data_r6 = ctx.$implicit; const ctx_r7 = i0.ɵɵnextContext(2); return ctx_r7.continueNewOrViewCommittedBillingRun(data_r6.data); });
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const data_r6 = ctx.$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(data_r6.data.status == "Draft" ? "Continue" : "Open");
} }
function ViewBillingRunsComponent_dx_data_grid_10_div_14_a_1_Template(rf, ctx) { if (rf & 1) {
    const _r13 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 21);
    i0.ɵɵlistener("click", function ViewBillingRunsComponent_dx_data_grid_10_div_14_a_1_Template_a_click_0_listener() { i0.ɵɵrestoreView(_r13); const data_r9 = i0.ɵɵnextContext().$implicit; const ctx_r11 = i0.ɵɵnextContext(2); return ctx_r11.exportCommittedBillingRun(data_r9.data); });
    i0.ɵɵtext(1, "Export");
    i0.ɵɵelementEnd();
} }
function ViewBillingRunsComponent_dx_data_grid_10_div_14_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, ViewBillingRunsComponent_dx_data_grid_10_div_14_a_1_Template, 2, 0, "a", 22);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const data_r9 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", data_r9.data.status == "Committed");
} }
function ViewBillingRunsComponent_dx_data_grid_10_div_17_a_1_Template(rf, ctx) { if (rf & 1) {
    const _r18 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 21);
    i0.ɵɵlistener("click", function ViewBillingRunsComponent_dx_data_grid_10_div_17_a_1_Template_a_click_0_listener() { i0.ɵɵrestoreView(_r18); const data_r14 = i0.ɵɵnextContext().$implicit; const ctx_r16 = i0.ɵɵnextContext(2); return ctx_r16.copyCommittedBillingRun(data_r14.data); });
    i0.ɵɵtext(1, "Copy");
    i0.ɵɵelementEnd();
} }
function ViewBillingRunsComponent_dx_data_grid_10_div_17_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, ViewBillingRunsComponent_dx_data_grid_10_div_17_a_1_Template, 2, 0, "a", 22);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const data_r14 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", data_r14.data.status == "Committed");
} }
function ViewBillingRunsComponent_dx_data_grid_10_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "dx-data-grid", 8);
    i0.ɵɵelement(1, "dxo-paging", 9);
    i0.ɵɵelement(2, "dxi-column", 10);
    i0.ɵɵelement(3, "dxi-column", 11);
    i0.ɵɵelement(4, "dxi-column", 12);
    i0.ɵɵelement(5, "dxi-column", 13);
    i0.ɵɵelement(6, "dxi-column", 14);
    i0.ɵɵelementStart(7, "dxi-column", 15);
    i0.ɵɵtemplate(8, ViewBillingRunsComponent_dx_data_grid_10_div_8_Template, 3, 4, "div", 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "dxi-column", 17);
    i0.ɵɵelementStart(10, "dxi-button", 18);
    i0.ɵɵtemplate(11, ViewBillingRunsComponent_dx_data_grid_10_div_11_Template, 3, 1, "div", 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(12, "dxi-column", 17);
    i0.ɵɵelementStart(13, "dxi-button", 19);
    i0.ɵɵtemplate(14, ViewBillingRunsComponent_dx_data_grid_10_div_14_Template, 2, 1, "div", 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(15, "dxi-column", 17);
    i0.ɵɵelementStart(16, "dxi-button", 20);
    i0.ɵɵtemplate(17, ViewBillingRunsComponent_dx_data_grid_10_div_17_Template, 2, 1, "div", 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("dataSource", ctx_r0.billingRunsData)("showBorders", true)("showColumnLines", false)("showRowLines", true)("cellHintEnabled", true)("allowColumnResizing", true)("columnAutoWidth", true);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("enabled", false);
    i0.ɵɵadvance(7);
    i0.ɵɵproperty("dxTemplateOf", "statusTemplate");
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("dxTemplateOf", "continueTemplate");
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("dxTemplateOf", "exportTemplate");
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("dxTemplateOf", "copyTemplate");
} }
if (!/localhost/.test(document.location.host)) {
    core_1.enableProdMode();
}
class ViewBillingRunsComponent {
    constructor($state, billingService) {
        this.$state = $state;
        this.billingService = billingService;
        this.billingRunsData = [];
        this.createNewBillingRun = () => {
            this.$state.go("createBillingRun.addDetails", { id: 0 });
        };
        this.continueNewOrViewCommittedBillingRun = (data) => {
            if (data.status == Models_1.BillingRunStatus.AddDetails) {
                this.$state.go("createBillingRun.addDetails", { id: data.id });
                this.billingService.notifyStateChange("createBillingRun.addDetails");
            }
            else if (data.status == Models_1.BillingRunStatus.SelectionCriteria) {
                this.$state.go("createBillingRun.selectionCriteria", { id: data.id });
                this.billingService.notifyStateChange("createBillingRun.selectionCriteria");
            }
            else if (data.status == Models_1.BillingRunStatus.ConfirmResidents) {
                this.$state.go("createBillingRun.confirmResidents", { id: data.id });
                this.billingService.notifyStateChange("createBillingRun.confirmResidents");
            }
            else if (data.status == Models_1.BillingRunStatus.ReviewAndCommit) {
                this.$state.go("createBillingRun.reviewAndCommit", { id: data.id });
                this.billingService.notifyStateChange("createBillingRun.reviewAndCommit");
            }
            else if (data.status == Models_1.BillingRunStatus.Committed)
                this.$state.go("viewCommittedBillingRun", { id: data.id });
        };
        this.exportCommittedBillingRun = (data) => {
        };
        this.copyCommittedBillingRun = (data) => {
        };
        //Todo: Get billing runs from service          
    }
}
exports.ViewBillingRunsComponent = ViewBillingRunsComponent;
ViewBillingRunsComponent.ɵfac = function ViewBillingRunsComponent_Factory(t) { return new (t || ViewBillingRunsComponent)(i0.ɵɵdirectiveInject(i1.StateService), i0.ɵɵdirectiveInject(i2.BillingService)); };
ViewBillingRunsComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ViewBillingRunsComponent, selectors: [["view-billing-runs"]], viewQuery: function ViewBillingRunsComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(devextreme_angular_1.DxDataGridComponent, true);
    } if (rf & 2) {
        var _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dataGrid = _t.first);
    } }, decls: 11, vars: 1, consts: [["nav-publisher", "billing", 1, "view-billing-runs"], [1, "page-header"], [1, "col-xs-2"], [1, "col-xs-2", "create-new-btn"], ["type", "button", 1, "btn", "btn-primary", "btn-lg", "pull-right", 3, "click"], [1, "col-xs-8"], [1, "col-xs-12", "billing-runs-table"], ["id", "gridContainer", 3, "dataSource", "showBorders", "showColumnLines", "showRowLines", "cellHintEnabled", "allowColumnResizing", "columnAutoWidth", 4, "ngIf"], ["id", "gridContainer", 3, "dataSource", "showBorders", "showColumnLines", "showRowLines", "cellHintEnabled", "allowColumnResizing", "columnAutoWidth"], [3, "enabled"], ["dataField", "name", "alignment", "left", "dataType", "string", "caption", "NAME"], ["cssClass", "description", "dataField", "description", "alignment", "left", "dataType", "string", "width", "190", "height", "auto", "caption", "DESCRIPTION"], ["dataField", "residentsCount", "alignment", "left", "dataType", "number", "caption", "RESIDENTS"], ["dataField", "chargeToDate", "alignment", "left", "format", "dd MMM yyyy", "dataType", "date", "caption", "CHARGE TO DATE"], ["dataField", "createdBy", "alignment", "left", "dataType", "string", "caption", "CREATED BY"], ["dataField", "status", "alignment", "left", "dataType", "string", "width", "90", "cellTemplate", "statusTemplate"], [4, "dxTemplate", "dxTemplateOf"], ["type", "buttons", "alignment", "left"], ["template", "continueTemplate", "hint", "Continue to edit current new billing run"], ["template", "exportTemplate"], ["template", "copyTemplate"], ["href", "javascript:;", 3, "click"], ["href", "javascript:;", 3, "click", 4, "ngIf"]], template: function ViewBillingRunsComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "h1");
        i0.ɵɵtext(4, "Billing Runs");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "div", 3);
        i0.ɵɵelementStart(6, "button", 4);
        i0.ɵɵlistener("click", function ViewBillingRunsComponent_Template_button_click_6_listener() { return ctx.createNewBillingRun(); });
        i0.ɵɵtext(7, "Create New");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelement(8, "div", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(9, "div", 6);
        i0.ɵɵtemplate(10, ViewBillingRunsComponent_dx_data_grid_10_Template, 18, 12, "dx-data-grid", 7);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(10);
        i0.ɵɵproperty("ngIf", ctx.billingRunsData.length > 0);
    } }, directives: [i3.NgIf, i4.DxDataGridComponent, i5.DxoPagingComponent, i5.DxiColumnComponent, i6.DxTemplateDirective, i5.DxiButtonComponent], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ViewBillingRunsComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'view-billing-runs',
                templateUrl: './viewBillingRuns.component.html'
            }]
    }], function () { return [{ type: i1.StateService }, { type: i2.BillingService }]; }, { dataGrid: [{
            type: core_1.ViewChild,
            args: [devextreme_angular_1.DxDataGridComponent, { static: false }]
        }] }); })();
