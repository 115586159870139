"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const lead_card_1 = require("../../models/lead-card");
const i0 = require("@angular/core");
const i1 = require("@angular/common");
const i2 = require("./loading-lead-card.component");
const i3 = require("../../dashboard/enquiry-lead-card.component");
function LeadCardComponent_loading_lead_card_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "loading-lead-card");
} }
function LeadCardComponent_enquiry_lead_card_2_Template(rf, ctx) { if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "enquiry-lead-card", 4);
    i0.ɵɵlistener("onDelete", function LeadCardComponent_enquiry_lead_card_2_Template_enquiry_lead_card_onDelete_0_listener($event) { i0.ɵɵrestoreView(_r3); const ctx_r2 = i0.ɵɵnextContext(); return ctx_r2.handleOnDelete($event); })("onArchive", function LeadCardComponent_enquiry_lead_card_2_Template_enquiry_lead_card_onArchive_0_listener($event) { i0.ɵɵrestoreView(_r3); const ctx_r4 = i0.ɵɵnextContext(); return ctx_r4.handleOnArchive($event); })("onReinstateLead", function LeadCardComponent_enquiry_lead_card_2_Template_enquiry_lead_card_onReinstateLead_0_listener($event) { i0.ɵɵrestoreView(_r3); const ctx_r5 = i0.ɵɵnextContext(); return ctx_r5.handleOnReinstateLead($event); });
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("leadCard", ctx_r1.leadCard)("canDeleteLeads", ctx_r1.canDeleteLeads);
} }
/**
 * Displays ILU Lead card depending based on card type.
 */
class LeadCardComponent {
    constructor() {
        /**
         * Is the card muted? Defaults to false.
         */
        this.isMuted = false;
        /**
         * Event emitted when the user selects the delete action for the lead card.
         */
        this.onDelete = new core_1.EventEmitter();
        /**
         * Event emitted when the user selects the archive action for the lead card.
         */
        this.onArchive = new core_1.EventEmitter();
        /**
         * Event emitted when the user selects the re-instate from archive action for the lead card.
         */
        this.onReinstateLead = new core_1.EventEmitter();
    }
    /**
     * Wheather the provided @see LeadCard is an enquiry
     *
     * @param leadCard The @see leadCard
     * @returns true if the provided leadCard is an enquiry otherwise false.
     */
    isEnquiry(leadCard) {
        return leadCard.cardType === lead_card_1.CardType.Enquiry;
    }
    /**
     * Wheather the provided @see LeadCard is a loading card.
     *
     * @param leadCard The @see leadCard
     * @returns true if the provided leadCard is a loading card otherwise false.
     */
    isLoading(leadCard) {
        return leadCard.cardType === lead_card_1.CardType.Loading;
    }
    /**
     * Handles the onDelete event from the child components.
     */
    handleOnDelete(leadCard) {
        this.onDelete.emit(leadCard);
    }
    /**
     * Handles the onArchive event from the child components.
     */
    handleOnArchive(leadCard) {
        this.onArchive.emit(leadCard);
    }
    /**
     * Handles the onReinstateLead event from the child components.
     */
    handleOnReinstateLead(leadCard) {
        this.onReinstateLead.emit(leadCard);
    }
}
exports.LeadCardComponent = LeadCardComponent;
LeadCardComponent.ɵfac = function LeadCardComponent_Factory(t) { return new (t || LeadCardComponent)(); };
LeadCardComponent.ɵcmp = i0.ɵɵdefineComponent({ type: LeadCardComponent, selectors: [["lead-card"]], inputs: { leadCard: "leadCard", canDeleteLeads: "canDeleteLeads", isMuted: "isMuted" }, outputs: { onDelete: "onDelete", onArchive: "onArchive", onReinstateLead: "onReinstateLead" }, decls: 4, vars: 3, consts: [[1, "dna-r", "ilu-lead-card"], [4, "ngIf"], [3, "leadCard", "canDeleteLeads", "onDelete", "onArchive", "onReinstateLead", 4, "ngIf"], [1, "mute", 3, "hidden"], [3, "leadCard", "canDeleteLeads", "onDelete", "onArchive", "onReinstateLead"]], template: function LeadCardComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, LeadCardComponent_loading_lead_card_1_Template, 1, 0, "loading-lead-card", 1);
        i0.ɵɵtemplate(2, LeadCardComponent_enquiry_lead_card_2_Template, 1, 2, "enquiry-lead-card", 2);
        i0.ɵɵelement(3, "div", 3);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.isLoading(ctx.leadCard));
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.isEnquiry(ctx.leadCard));
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("hidden", !ctx.leadCard.isMuted);
    } }, directives: [i1.NgIf, i2.LoadingLeadCardComponent, i3.EnquiryLeadCardComponent], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(LeadCardComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'lead-card',
                templateUrl: './lead-card.component.html'
            }]
    }], null, { leadCard: [{
            type: core_1.Input
        }], canDeleteLeads: [{
            type: core_1.Input
        }], isMuted: [{
            type: core_1.Input
        }], onDelete: [{
            type: core_1.Output
        }], onArchive: [{
            type: core_1.Output
        }], onReinstateLead: [{
            type: core_1.Output
        }] }); })();
