"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("../dtos/simple-enquiry-dto");
class LeadCard {
    constructor(enquiry) {
        this.cardType = CardType.Enquiry;
        this.enquiry = enquiry;
        this.isMuted = false;
    }
}
exports.LeadCard = LeadCard;
var CardType;
(function (CardType) {
    CardType[CardType["Loading"] = 0] = "Loading";
    CardType[CardType["Enquiry"] = 1] = "Enquiry";
})(CardType = exports.CardType || (exports.CardType = {}));
