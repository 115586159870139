"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const users_service_1 = require("../services/users.service");
const i0 = require("@angular/core");
const i1 = require("../services/users.service");
const i2 = require("@angular/common");
const i3 = require("@angular/forms");
const i4 = require("../../common/directives/show-hide-password.directive");
function AccountSettingsComponent_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelementStart(1, "div", 2);
    i0.ɵɵelementStart(2, "div", 16);
    i0.ɵɵelement(3, "div", 17);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("innerHtml", ctx_r0.error, i0.ɵɵsanitizeHtml);
} }
const _c0 = function (a0) { return { "empty-password": a0 }; };
const _c1 = function (a0, a1) { return { "empty-password": a0, "wrong-password-input": a1 }; };
const _c2 = function (a0, a1) { return { "wrong-password": a0, "correct-password": a1 }; };
class AccountSettingsComponent {
    constructor(state, usersService, changeDetector) {
        this.state = state;
        this.usersService = usersService;
        this.changeDetector = changeDetector;
        this.upperCharValidator = new RegExp(/^(?=.*[A-Z]).+$/);
        this.lowerCharValidator = new RegExp(/^(?=.*[a-z]).+$/);
        this.numberValidator = new RegExp(/^(?=.*\d).+$/);
        this.newPassword = "";
        this.oldPassword = "";
    }
    atLeast8Chars() {
        return this.newPassword.length >= 8;
    }
    newPasswordUpperAndLower() {
        return this.lowerCharValidator.test(this.newPassword) && this.upperCharValidator.test(this.newPassword);
    }
    newPasswordNumbersValid() {
        return this.numberValidator.test(this.newPassword);
    }
    newPasswordDifferentValid() {
        return this.oldPassword !== this.newPassword;
    }
    cancel() {
        this.state.go("dashboard");
    }
    showError(err) {
        this.error = err.toString();
        this.changeDetector.detectChanges();
    }
    changePassword() {
        if (!this.atLeast8Chars() || !this.newPasswordUpperAndLower() || !this.newPasswordNumbersValid() || !this.newPasswordDifferentValid()) {
            this.showError('New password does not meet security requirements');
            return;
        }
        this.usersService.changePassword(this.oldPassword, this.newPassword).then(() => {
            this.state.go("dashboard");
        }, (error) => {
            if (error.modelStateSummary.oldPassword) {
                this.showError(error.modelStateSummary.oldPassword);
            }
            if (error.modelStateSummary.newPassword) {
                this.showError(error.modelStateSummary.newPassword);
            }
        });
    }
}
exports.AccountSettingsComponent = AccountSettingsComponent;
AccountSettingsComponent.ɵfac = function AccountSettingsComponent_Factory(t) { return new (t || AccountSettingsComponent)(i0.ɵɵdirectiveInject('$state'), i0.ɵɵdirectiveInject(i1.UsersService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef)); };
AccountSettingsComponent.ɵcmp = i0.ɵɵdefineComponent({ type: AccountSettingsComponent, selectors: [["account-settings"]], decls: 52, vars: 26, consts: [[1, "account-settings"], [1, "row"], [1, "col-xs-8", "col-xs-offset-2"], [1, "header"], ["class", "row", 4, "ngIf"], [1, "contents"], [1, "col-xs-6"], [1, "col-xs-6", "old-password"], ["type", "password", "name", "oldpassword", "showHidePassword", "", 3, "ngModel", "ngClass", "ngModelChange"], [1, "col-xs-6", "new-password"], ["type", "password", "name", "newPassword", "showHidePassword", "", "maxlength", "100", 3, "ngModel", "ngClass", "ngModelChange"], [1, "password-validation", 3, "ngClass"], [1, "buttons"], [1, "col-xs-12"], [1, "btn", "btn-primary", "btn-lg", "pull-right", 3, "click"], [1, "btn", "btn-warning", "btn-lg", "pull-right", 3, "click"], [1, "banner", "banner-small", "banner-error"], [3, "innerHtml"]], template: function AccountSettingsComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "h1", 3);
        i0.ɵɵtext(4, "Account settings");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(5, AccountSettingsComponent_div_5_Template, 4, 1, "div", 4);
        i0.ɵɵelementStart(6, "div", 1);
        i0.ɵɵelementStart(7, "div", 2);
        i0.ɵɵelementStart(8, "div", 5);
        i0.ɵɵelementStart(9, "div", 1);
        i0.ɵɵelementStart(10, "div", 6);
        i0.ɵɵelementStart(11, "h2");
        i0.ɵɵtext(12, "Change password");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(13, "div", 1);
        i0.ɵɵelementStart(14, "div", 6);
        i0.ɵɵtext(15, "Enter your current password");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(16, "div", 6);
        i0.ɵɵtext(17, "New password");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(18, "div", 1);
        i0.ɵɵelementStart(19, "div", 7);
        i0.ɵɵelementStart(20, "input", 8);
        i0.ɵɵlistener("ngModelChange", function AccountSettingsComponent_Template_input_ngModelChange_20_listener($event) { return ctx.oldPassword = $event; });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(21, "div", 9);
        i0.ɵɵelementStart(22, "input", 10);
        i0.ɵɵlistener("ngModelChange", function AccountSettingsComponent_Template_input_ngModelChange_22_listener($event) { return ctx.newPassword = $event; });
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(23, "div", 11);
        i0.ɵɵelementStart(24, "span");
        i0.ɵɵtext(25, "\u25CF");
        i0.ɵɵelementEnd();
        i0.ɵɵelement(26, "div");
        i0.ɵɵtext(27, " At least 8 characters ");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(28, "div", 11);
        i0.ɵɵelementStart(29, "span");
        i0.ɵɵtext(30, "\u25CF");
        i0.ɵɵelementEnd();
        i0.ɵɵelement(31, "div");
        i0.ɵɵtext(32, " Upper and lower case letters ");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(33, "div", 11);
        i0.ɵɵelementStart(34, "span");
        i0.ɵɵtext(35, "\u25CF");
        i0.ɵɵelementEnd();
        i0.ɵɵelement(36, "div");
        i0.ɵɵtext(37, " At least one number ");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(38, "div", 11);
        i0.ɵɵelementStart(39, "span");
        i0.ɵɵtext(40, "\u25CF");
        i0.ɵɵelementEnd();
        i0.ɵɵelement(41, "div");
        i0.ɵɵtext(42, " Different from current password ");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(43, "div", 1);
        i0.ɵɵelementStart(44, "div", 2);
        i0.ɵɵelementStart(45, "div", 12);
        i0.ɵɵelementStart(46, "div", 1);
        i0.ɵɵelementStart(47, "div", 13);
        i0.ɵɵelementStart(48, "button", 14);
        i0.ɵɵlistener("click", function AccountSettingsComponent_Template_button_click_48_listener() { return ctx.changePassword(); });
        i0.ɵɵtext(49, "Save");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(50, "button", 15);
        i0.ɵɵlistener("click", function AccountSettingsComponent_Template_button_click_50_listener() { return ctx.cancel(); });
        i0.ɵɵtext(51, "Cancel");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(5);
        i0.ɵɵproperty("ngIf", ctx.error);
        i0.ɵɵadvance(15);
        i0.ɵɵproperty("ngModel", ctx.oldPassword)("ngClass", i0.ɵɵpureFunction1(9, _c0, !ctx.oldPassword));
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngModel", ctx.newPassword)("ngClass", i0.ɵɵpureFunction2(11, _c1, !ctx.newPassword, !!ctx.newPassword && (!ctx.atLeast8Chars() || !ctx.newPasswordUpperAndLower() || !ctx.newPasswordNumbersValid() || !ctx.newPasswordDifferentValid())));
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(14, _c2, !!ctx.newPassword && !ctx.atLeast8Chars(), !!ctx.newPassword && ctx.atLeast8Chars()));
        i0.ɵɵadvance(5);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(17, _c2, !!ctx.newPassword && !ctx.newPasswordUpperAndLower(), !!ctx.newPassword && ctx.newPasswordUpperAndLower()));
        i0.ɵɵadvance(5);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(20, _c2, !!ctx.newPassword && !ctx.newPasswordNumbersValid(), !!ctx.newPassword && ctx.newPasswordNumbersValid()));
        i0.ɵɵadvance(5);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(23, _c2, !!ctx.newPassword && !ctx.newPasswordDifferentValid(), !!ctx.newPassword && ctx.newPasswordDifferentValid()));
    } }, directives: [i2.NgIf, i3.DefaultValueAccessor, i4.ShowHidePasswordDirective, i3.NgControlStatus, i3.NgModel, i2.NgClass, i3.MaxLengthValidator], encapsulation: 2, changeDetection: 0 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AccountSettingsComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'account-settings',
                templateUrl: './account-settings.component.html',
                changeDetection: core_1.ChangeDetectionStrategy.OnPush
            }]
    }], function () { return [{ type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['$state']
            }] }, { type: i1.UsersService }, { type: i0.ChangeDetectorRef }]; }, null); })();
