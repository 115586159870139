"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("../enums/enquiry-accessibility-requirement.enum");
const prospect_dto_1 = require("./prospect-dto");
require("./tag-dto");
require("../accommodation-preferences/dto/current-accommodation-preferences-dto");
class SimpleEnquiryDto {
}
exports.SimpleEnquiryDto = SimpleEnquiryDto;
/**
 * Returns a string of all the prospect names for the provide enquiry.
 *
 * @param enquiry The enquiry.
 *
 * @returns A string of all the prosect names.
 */
function getEnquiryNames(enquiry) {
    if (enquiry.prospects.length == 1) {
        return prospect_dto_1.getProspectName(enquiry.prospects[0]);
    }
    else if (enquiry.prospects.length > 1) {
        const prospects = [...enquiry.prospects];
        const lastProspect = prospects.pop();
        let names = prospects.map((prospect) => prospect_dto_1.getProspectName(prospect)).join(', ');
        return `${names} & ${prospect_dto_1.getProspectName(lastProspect)}`;
    }
    return '';
}
exports.getEnquiryNames = getEnquiryNames;
