"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const angular_hybrid_1 = require("@uirouter/angular-hybrid");
const authentication_states_1 = require("./authentication.states");
const account_settings_component_1 = require("./account-settings/account-settings.component");
const users_service_1 = require("./services/users.service");
const shared_module_1 = require("../common/shared.module");
const i0 = require("@angular/core");
const i1 = require("@uirouter/angular-hybrid");
class AuthenticationModule {
}
exports.AuthenticationModule = AuthenticationModule;
AuthenticationModule.ɵmod = i0.ɵɵdefineNgModule({ type: AuthenticationModule });
AuthenticationModule.ɵinj = i0.ɵɵdefineInjector({ factory: function AuthenticationModule_Factory(t) { return new (t || AuthenticationModule)(); }, providers: [users_service_1.UsersService], imports: [[
            shared_module_1.SharedModule,
            angular_hybrid_1.UIRouterUpgradeModule.forChild({ states: authentication_states_1.states })
        ]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(AuthenticationModule, { declarations: [account_settings_component_1.AccountSettingsComponent], imports: [shared_module_1.SharedModule, i1.UIRouterUpgradeModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AuthenticationModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [
                    shared_module_1.SharedModule,
                    angular_hybrid_1.UIRouterUpgradeModule.forChild({ states: authentication_states_1.states })
                ],
                providers: [users_service_1.UsersService],
                declarations: [account_settings_component_1.AccountSettingsComponent]
            }]
    }], null, null); })();
