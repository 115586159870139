"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const snack_bar_1 = require("@angular/material/snack-bar");
const i0 = require("@angular/core");
const i1 = require("@angular/material/snack-bar");
class DnaRSnackBarService {
    constructor(snackBar) {
        this.snackBar = snackBar;
        this.info = (message) => {
            this.snackBar.open(message);
        };
        this.success = (message) => {
            this.snackBar.open(message, null, { panelClass: ['dnar-sanck-bar', 'dnar-sanck-bar-success'] });
        };
        this.failure = (message) => {
            this.snackBar.open(message, null, { panelClass: ['dnar-sanck-bar', 'dnar-sanck-bar-failure'] });
        };
    }
}
exports.DnaRSnackBarService = DnaRSnackBarService;
DnaRSnackBarService.DEFAULT_OPTIONS = {
    duration: 8000,
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
    panelClass: 'dnar-sanck-bar'
};
DnaRSnackBarService.ɵfac = function DnaRSnackBarService_Factory(t) { return new (t || DnaRSnackBarService)(i0.ɵɵinject(i1.MatSnackBar)); };
DnaRSnackBarService.ɵprov = i0.ɵɵdefineInjectable({ token: DnaRSnackBarService, factory: DnaRSnackBarService.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DnaRSnackBarService, [{
        type: core_1.Injectable
    }], function () { return [{ type: i1.MatSnackBar }]; }, null); })();
