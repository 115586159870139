"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const anacc_varaiable_component_dto_1 = require("../dtos/anacc-varaiable-component-dto");
const anacc_fixed_component_funding_rate_dto_1 = require("../dtos/anacc-fixed-component-funding-rate-dto");
const i0 = require("@angular/core");
const i1 = require("@angular/common");
/**
 * Display's AN-ACC daily rate details.
 */
class AnaccDailyRateComponent {
    /**
     * @inheritDoc
     */
    ngOnChanges(changes) {
        if (this.anaccVariableComponent) {
            this.dailyTotal = (this.anaccFixedComponent && this.anaccVariableComponent.rate) ? Number(this.anaccFixedComponent.baseCareTariff) + Number(this.anaccVariableComponent.rate) : null;
        }
        else {
            this.dailyTotal = null;
        }
    }
}
exports.AnaccDailyRateComponent = AnaccDailyRateComponent;
AnaccDailyRateComponent.ɵfac = function AnaccDailyRateComponent_Factory(t) { return new (t || AnaccDailyRateComponent)(); };
AnaccDailyRateComponent.ɵcmp = i0.ɵɵdefineComponent({ type: AnaccDailyRateComponent, selectors: [["anacc-daily-rate"]], inputs: { anaccVariableComponent: "anaccVariableComponent", anaccFixedComponent: "anaccFixedComponent" }, features: [i0.ɵɵNgOnChangesFeature], decls: 24, vars: 10, consts: [[1, "anacc-daily-rate", "row"], [1, "col-xs-3"], [1, "col-xs-3", "text-right"]], template: function AnaccDailyRateComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "div");
        i0.ɵɵtext(3, "Class");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "div");
        i0.ɵɵtext(5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(6, "div", 2);
        i0.ɵɵelementStart(7, "div");
        i0.ɵɵtext(8, "Fixed");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(9, "div");
        i0.ɵɵtext(10);
        i0.ɵɵpipe(11, "currency");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(12, "div", 2);
        i0.ɵɵelementStart(13, "div");
        i0.ɵɵtext(14, "Variable");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(15, "div");
        i0.ɵɵtext(16);
        i0.ɵɵpipe(17, "currency");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(18, "div", 2);
        i0.ɵɵelementStart(19, "div");
        i0.ɵɵtext(20, "Daily Total");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(21, "div");
        i0.ɵɵtext(22);
        i0.ɵɵpipe(23, "currency");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(5);
        i0.ɵɵtextInterpolate(ctx.anaccVariableComponent && ctx.anaccVariableComponent.anaccClass && ctx.anaccVariableComponent.anaccClass !== "Unknown" ? ctx.anaccVariableComponent.anaccClass : "-");
        i0.ɵɵadvance(5);
        i0.ɵɵtextInterpolate(ctx.anaccFixedComponent ? i0.ɵɵpipeBind1(11, 4, ctx.anaccFixedComponent.baseCareTariff) : "-");
        i0.ɵɵadvance(6);
        i0.ɵɵtextInterpolate(ctx.anaccVariableComponent && ctx.anaccVariableComponent.rate ? i0.ɵɵpipeBind1(17, 6, ctx.anaccVariableComponent.rate) : "-");
        i0.ɵɵadvance(6);
        i0.ɵɵtextInterpolate(ctx.dailyTotal ? i0.ɵɵpipeBind1(23, 8, ctx.dailyTotal) : "-");
    } }, pipes: [i1.CurrencyPipe], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AnaccDailyRateComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'anacc-daily-rate',
                templateUrl: './anacc-daily-rate.component.html'
            }]
    }], null, { anaccVariableComponent: [{
            type: core_1.Input
        }], anaccFixedComponent: [{
            type: core_1.Input
        }] }); })();
