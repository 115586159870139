"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("underscore");
const core_1 = require("@angular/core");
const admin_service_1 = require("../admin.service");
const Models_1 = require("../Models");
const core_2 = require("@uirouter/core");
const i0 = require("@angular/core");
const i1 = require("@uirouter/core");
const i2 = require("../admin.service");
const i3 = require("@angular/forms");
const i4 = require("@angular/common");
const i5 = require("devextreme-angular");
const i6 = require("devextreme-angular/ui/nested");
function OrganisationDetailsComponent_div_16_Template(rf, ctx) { if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 19);
    i0.ɵɵtext(1, "\n                        ");
    i0.ɵɵelementStart(2, "button", 20);
    i0.ɵɵlistener("click", function OrganisationDetailsComponent_div_16_Template_button_click_2_listener() { i0.ɵɵrestoreView(_r3); const ctx_r2 = i0.ɵɵnextContext(); return ctx_r2.editOrganisationDetail(); });
    i0.ɵɵtext(3, "Edit");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(4, "\n                    ");
    i0.ɵɵelementEnd();
} }
const _c0 = function () { return { readOnly: true }; };
function OrganisationDetailsComponent_div_108_Template(rf, ctx) { if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵtext(1, "\n                                    ");
    i0.ɵɵelementStart(2, "div");
    i0.ɵɵtext(3, "\n                                        ");
    i0.ɵɵelementStart(4, "dx-data-grid", 21);
    i0.ɵɵlistener("onEditorPreparing", function OrganisationDetailsComponent_div_108_Template_dx_data_grid_onEditorPreparing_4_listener($event) { i0.ɵɵrestoreView(_r5); const ctx_r4 = i0.ɵɵnextContext(); return ctx_r4.onEditorPreparing($event); });
    i0.ɵɵtext(5, "\n                                            ");
    i0.ɵɵelement(6, "dxo-paging", 22);
    i0.ɵɵtext(7, "\n                                            ");
    i0.ɵɵelementStart(8, "dxo-editing", 23);
    i0.ɵɵtext(9, "\n                                            ");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(10, "\n\n                                            ");
    i0.ɵɵelementStart(11, "dxi-column", 24);
    i0.ɵɵtext(12, "\n                                            ");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(13, "\n                                            ");
    i0.ɵɵelement(14, "dxi-column", 25);
    i0.ɵɵtext(15, "\n                                        ");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(16, "\n                                    ");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(17, "\n                                ");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("dataSource", ctx_r1.organisation.facilityRaNumbers)("showBorders", true);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("enabled", false);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("allowUpdating", false)("allowAdding", false)("allowDeleting", false);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("editorOptions", i0.ɵɵpureFunction0(7, _c0));
} }
if (!/localhost/.test(document.location.host)) {
    core_1.enableProdMode();
}
class OrganisationDetailsComponent {
    constructor($state, uiRouter, adminService, notificationService, authenticationService) {
        this.$state = $state;
        this.uiRouter = uiRouter;
        this.adminService = adminService;
        this.notificationService = notificationService;
        this.authenticationService = authenticationService;
        this.editOrganisationDetail = () => {
            this.$state.go("editOrganisationDetails", { org: this.organisation });
        };
        this.MapState = (stateName) => {
            if (stateName === 'AustralianCapitalTerritory')
                this.state = 'Australian Capital Territory';
            else if (stateName === 'NewSouthWales')
                this.state = 'New South Wales';
            else if (stateName === 'NorthernTerritory')
                this.state = 'Northern Territory';
            else if (stateName === 'Queensland')
                this.state = 'Queensland';
            else if (stateName === 'SouthAustralia')
                this.state = 'South Australia';
            else if (stateName === 'Tasmania')
                this.state = 'Tasmania';
            else if (stateName === 'Victoria')
                this.state = 'Victoria';
            else if (stateName === 'WesternAustralia')
                this.state = 'Western Australia';
        };
        this.organisationId = this.uiRouter.globals.params.id;
    }
    ngOnInit() {
        this.organisation = new Models_1.OrganisationDto();
        this.adminService.getOrganisation().then((response) => {
            this.organisation = response;
            //See if we already have multiple RA Numbers in the
            this.organisation.facilityRaNumbers.forEach(frn => {
                if (frn.facilityRaNumber != this.organisation.prodaRaNumber) {
                    this.organisation.hasMultipleRANumbers = true;
                    return;
                }
            });
            this.MapState(this.organisation.state);
            this.canModifyOrganisationDetails = this.authenticationService.userPermissions.organisationDetails.canModify;
        }, () => {
            this.notificationService.error("Unexpected error while getting the organisation details.");
        });
    }
}
exports.OrganisationDetailsComponent = OrganisationDetailsComponent;
OrganisationDetailsComponent.ɵfac = function OrganisationDetailsComponent_Factory(t) { return new (t || OrganisationDetailsComponent)(i0.ɵɵdirectiveInject(i1.StateService), i0.ɵɵdirectiveInject(i1.UIRouter), i0.ɵɵdirectiveInject(i2.AdminService), i0.ɵɵdirectiveInject('notification.service'), i0.ɵɵdirectiveInject('authentication.service')); };
OrganisationDetailsComponent.ɵcmp = i0.ɵɵdefineComponent({ type: OrganisationDetailsComponent, selectors: [["organisation-details"]], decls: 117, vars: 10, consts: [["nav-publisher", "admin", 1, "clinical-integration"], [1, "col-xs-12"], [1, "col-xs-8"], ["ng-submit", "$ctrl.process()"], [1, "row"], [1, "col-xs-10"], [1, "page-header"], ["class", "col-xs-2 pull-right margin-top-30", 4, "ngIf"], [1, "panel", "panel-default"], [1, "panel-body"], [1, "col-xs-6"], [1, "sub-header"], [1, "space-below"], [1, "view-label"], [1, "view-value"], [1, "row", "space-below"], [1, "col-xs-5"], [1, "view-note"], ["class", "row", 4, "ngIf"], [1, "col-xs-2", "pull-right", "margin-top-30"], ["type", "button", "requires-permission", "canModifyOrganisationDetails", 1, "add-new-funding-rate", "btn", "btn-primary", "btn-lg", "pull-right", 3, "click"], ["id", "gridContainer", 3, "dataSource", "showBorders", "onEditorPreparing"], [3, "enabled"], ["mode", "cell", 3, "allowUpdating", "allowAdding", "allowDeleting"], ["dataField", "facilityName", "caption", "Facility", 3, "editorOptions"], ["dataField", "facilityRaNumber", "caption", "RA Number"]], template: function OrganisationDetailsComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtext(1, "\n    ");
        i0.ɵɵelementStart(2, "div", 1);
        i0.ɵɵtext(3, "\n        ");
        i0.ɵɵelementStart(4, "div", 2);
        i0.ɵɵtext(5, "\n            ");
        i0.ɵɵelementStart(6, "form", 3);
        i0.ɵɵtext(7, "\n                ");
        i0.ɵɵelementStart(8, "div", 4);
        i0.ɵɵtext(9, "\n                    ");
        i0.ɵɵelementStart(10, "div", 5);
        i0.ɵɵtext(11, "\n                        ");
        i0.ɵɵelementStart(12, "h1", 6);
        i0.ɵɵtext(13, "Organisation details");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(14, "\n                    ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(15, "\n                    ");
        i0.ɵɵtemplate(16, OrganisationDetailsComponent_div_16_Template, 5, 0, "div", 7);
        i0.ɵɵtext(17, "\n                ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(18, "\n\n                ");
        i0.ɵɵelementStart(19, "div", 8);
        i0.ɵɵtext(20, "\n                    ");
        i0.ɵɵelementStart(21, "div", 9);
        i0.ɵɵtext(22, "\n\n                        ");
        i0.ɵɵelementStart(23, "div", 4);
        i0.ɵɵtext(24, "\n                            ");
        i0.ɵɵelementStart(25, "div", 10);
        i0.ɵɵtext(26, "\n                                ");
        i0.ɵɵelementStart(27, "h2", 11);
        i0.ɵɵtext(28);
        i0.ɵɵelementEnd();
        i0.ɵɵtext(29, "\n                            ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(30, "\n                        ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(31, "\n                        ");
        i0.ɵɵelementStart(32, "div", 4);
        i0.ɵɵtext(33, "\n                            ");
        i0.ɵɵelementStart(34, "div", 10);
        i0.ɵɵtext(35, "\n                                ");
        i0.ɵɵelementStart(36, "div", 12);
        i0.ɵɵtext(37, "\n                                    ");
        i0.ɵɵelementStart(38, "h2", 11);
        i0.ɵɵtext(39, "Address (optional)");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(40, "\n                                ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(41, "\n\n                                ");
        i0.ɵɵelementStart(42, "div", 13);
        i0.ɵɵtext(43, "Street line 1");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(44, "\n                                ");
        i0.ɵɵelementStart(45, "div", 14);
        i0.ɵɵtext(46);
        i0.ɵɵelementEnd();
        i0.ɵɵtext(47, "\n\n                                ");
        i0.ɵɵelement(48, "div", 15);
        i0.ɵɵtext(49, "\n\n                                ");
        i0.ɵɵelementStart(50, "div", 13);
        i0.ɵɵtext(51, "Street 2");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(52, "\n                                ");
        i0.ɵɵelementStart(53, "div", 14);
        i0.ɵɵtext(54);
        i0.ɵɵelementEnd();
        i0.ɵɵtext(55, "\n\n                                ");
        i0.ɵɵelement(56, "div", 15);
        i0.ɵɵtext(57, "\n\n                                ");
        i0.ɵɵelementStart(58, "div", 13);
        i0.ɵɵtext(59, "Suburb");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(60, "\n                                ");
        i0.ɵɵelementStart(61, "div", 14);
        i0.ɵɵtext(62);
        i0.ɵɵelementEnd();
        i0.ɵɵtext(63, "\n\n                                ");
        i0.ɵɵelement(64, "div", 15);
        i0.ɵɵtext(65, "\n\n                                ");
        i0.ɵɵelementStart(66, "div", 13);
        i0.ɵɵtext(67, "State");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(68, "\n                                ");
        i0.ɵɵelementStart(69, "div", 14);
        i0.ɵɵtext(70);
        i0.ɵɵelementEnd();
        i0.ɵɵtext(71, "\n\n                                ");
        i0.ɵɵelement(72, "div", 15);
        i0.ɵɵtext(73, "\n                                ");
        i0.ɵɵelementStart(74, "div", 13);
        i0.ɵɵtext(75, "Postcode");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(76, "\n                                ");
        i0.ɵɵelementStart(77, "div", 14);
        i0.ɵɵtext(78);
        i0.ɵɵelementEnd();
        i0.ɵɵtext(79, "\n\n                                ");
        i0.ɵɵelement(80, "div", 15);
        i0.ɵɵtext(81, "\n                                ");
        i0.ɵɵelementStart(82, "div", 13);
        i0.ɵɵtext(83, "Country");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(84, "\n                                ");
        i0.ɵɵelementStart(85, "div", 14);
        i0.ɵɵtext(86);
        i0.ɵɵelementEnd();
        i0.ɵɵtext(87, "\n                            ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(88, "\n\n                            ");
        i0.ɵɵelementStart(89, "div", 16);
        i0.ɵɵtext(90, "\n\n                                ");
        i0.ɵɵelementStart(91, "div", 4);
        i0.ɵɵtext(92, "\n                                    ");
        i0.ɵɵelementStart(93, "h2", 11);
        i0.ɵɵtext(94, "PRODA RA number");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(95, "\n                                    ");
        i0.ɵɵelementStart(96, "div", 13);
        i0.ɵɵtext(97, "Organisation RA number");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(98, "\n                                    ");
        i0.ɵɵelementStart(99, "div", 14);
        i0.ɵɵtext(100);
        i0.ɵɵelementEnd();
        i0.ɵɵtext(101, "\n                                    ");
        i0.ɵɵelementStart(102, "div", 17);
        i0.ɵɵtext(103, "New facilities get this number by default.");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(104, "\n                                ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(105, "\n                                ");
        i0.ɵɵelement(106, "div", 15);
        i0.ɵɵtext(107, "\n                                ");
        i0.ɵɵtemplate(108, OrganisationDetailsComponent_div_108_Template, 18, 8, "div", 18);
        i0.ɵɵtext(109, "\n                            ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(110, "\n                        ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(111, "\n                    ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(112, "\n                ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(113, "\n            ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(114, "\n        ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(115, "\n    ");
        i0.ɵɵelementEnd();
        i0.ɵɵtext(116, "\n");
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(16);
        i0.ɵɵproperty("ngIf", ctx.canModifyOrganisationDetails);
        i0.ɵɵadvance(12);
        i0.ɵɵtextInterpolate1("Organisation detail for ", ctx.organisation.name, "");
        i0.ɵɵadvance(18);
        i0.ɵɵtextInterpolate(ctx.organisation.street1);
        i0.ɵɵadvance(8);
        i0.ɵɵtextInterpolate(ctx.organisation.street2);
        i0.ɵɵadvance(8);
        i0.ɵɵtextInterpolate(ctx.organisation.suburb);
        i0.ɵɵadvance(8);
        i0.ɵɵtextInterpolate(ctx.state);
        i0.ɵɵadvance(8);
        i0.ɵɵtextInterpolate(ctx.organisation.postCode);
        i0.ɵɵadvance(8);
        i0.ɵɵtextInterpolate(ctx.organisation.country);
        i0.ɵɵadvance(14);
        i0.ɵɵtextInterpolate(ctx.organisation.prodaRaNumber);
        i0.ɵɵadvance(8);
        i0.ɵɵproperty("ngIf", ctx.organisation.hasMultipleRANumbers);
    } }, directives: [i3.ɵangular_packages_forms_forms_y, i3.NgControlStatusGroup, i3.NgForm, i4.NgIf, i5.DxDataGridComponent, i6.DxoPagingComponent, i6.DxoEditingComponent, i6.DxiColumnComponent], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(OrganisationDetailsComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'organisation-details',
                templateUrl: './organisation-details.component.html',
                preserveWhitespaces: true
            }]
    }], function () { return [{ type: i1.StateService }, { type: i1.UIRouter }, { type: i2.AdminService }, { type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['notification.service']
            }] }, { type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['authentication.service']
            }] }]; }, null); })();
