"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class BedStatusItemDto {
    constructor(startDate, endDate, id, bedId) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.id = id;
        this.bedId = bedId;
        this.isDeleted = false;
    }
}
exports.BedStatusItemDto = BedStatusItemDto;
