"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MovementEnum;
(function (MovementEnum) {
    MovementEnum["All"] = "All";
    MovementEnum["AdmissionOrDeparture"] = "Admission/Departure";
    MovementEnum["EmergencyLeave"] = "Emergency leave";
    MovementEnum["HospitalLeave"] = "Hospital leave";
    MovementEnum["SocialLeave"] = "Social leave";
    MovementEnum["TransitionCareLeave"] = "Transition care leave";
})(MovementEnum = exports.MovementEnum || (exports.MovementEnum = {}));
