"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const progress_spinner_1 = require("@angular/material/progress-spinner");
const common_1 = require("@angular/common");
const dnaR_spinner_component_1 = require("./dnaR-spinner.component");
const i0 = require("@angular/core");
class DnaRSpinnerModule {
    static forRoot() {
        return {
            ngModule: DnaRSpinnerModule
        };
    }
}
exports.DnaRSpinnerModule = DnaRSpinnerModule;
DnaRSpinnerModule.ɵmod = i0.ɵɵdefineNgModule({ type: DnaRSpinnerModule });
DnaRSpinnerModule.ɵinj = i0.ɵɵdefineInjector({ factory: function DnaRSpinnerModule_Factory(t) { return new (t || DnaRSpinnerModule)(); }, imports: [[common_1.CommonModule, progress_spinner_1.MatProgressSpinnerModule]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DnaRSpinnerModule, { declarations: [dnaR_spinner_component_1.DnaRSpinner], imports: [common_1.CommonModule, progress_spinner_1.MatProgressSpinnerModule], exports: [dnaR_spinner_component_1.DnaRSpinner] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DnaRSpinnerModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [common_1.CommonModule, progress_spinner_1.MatProgressSpinnerModule],
                declarations: [dnaR_spinner_component_1.DnaRSpinner],
                exports: [dnaR_spinner_component_1.DnaRSpinner],
                schemas: [
                    core_1.CUSTOM_ELEMENTS_SCHEMA
                ]
            }]
    }], null, null); })();
