"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("underscore");
const core_1 = require("@angular/core");
const core_2 = require("@uirouter/core");
const devextreme_angular_1 = require("devextreme-angular");
const billing_service_1 = require("../billing.service");
const Models_1 = require("../Models");
const i0 = require("@angular/core");
const i1 = require("@uirouter/core");
const i2 = require("../billing.service");
const i3 = require("@angular/forms");
const i4 = require("devextreme-angular");
const i5 = require("devextreme-angular/ui/nested");
const i6 = require("@angular/common");
const i7 = require("devextreme-angular/core");
function ConfirmResidentsComponent_dxi_column_42_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "dxi-column", 24);
} if (rf & 2) {
    i0.ɵɵproperty("allowSorting", false);
} }
function ConfirmResidentsComponent_div_44_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 27);
    i0.ɵɵelementStart(1, "span");
    i0.ɵɵtext(2, "Departed");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} }
const _c0 = function (a0, a1) { return { "col-xs-8": a0, "col-xs-12": a1 }; };
function ConfirmResidentsComponent_div_44_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "div", 25);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, ConfirmResidentsComponent_div_44_div_3_Template, 3, 0, "div", 26);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const data_r3 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("title", data_r3.value);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(4, _c0, data_r3.data.isDeparted, !data_r3.data.isDeparted));
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(data_r3.value);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", data_r3.data.isDeparted);
} }
const _c1 = function () { return ["facilityName", "residentName", "crid", "debtorId"]; };
const _c2 = function () { return { mode: "multiple", showCheckBoxesMode: "always" }; };
if (!/localhost/.test(document.location.host)) {
    core_1.enableProdMode();
}
class ConfirmResidentsComponent {
    constructor($state, uiRouter, billingService, facilityService, notificationService) {
        this.$state = $state;
        this.uiRouter = uiRouter;
        this.billingService = billingService;
        this.facilityService = facilityService;
        this.notificationService = notificationService;
        this.billingRun = new Models_1.BillingRunDto();
        this.selectedFacilities = [];
        this.selectedResidents = [];
        this.back = () => {
            this.billingRun.status = Models_1.BillingRunStatus.SelectionCriteria;
            this.billingRun.residencyIds = this.selectedResidents.map(x => x.residencyId);
            this.billingService.saveBillingRun(this.billingRun).then((response) => {
                this.$state.go("createBillingRun.selectionCriteria");
                this.billingService.notifyStateChange("createBillingRun.selectionCriteria");
            }, () => {
                this.notificationService.error("Unexpected error while saving the billing run.");
            });
        };
        this.next = (isFormValid) => {
            if (isFormValid) {
                this.billingRun.status = Models_1.BillingRunStatus.ReviewAndCommit;
                this.billingRun.residencyIds = this.selectedResidents.map(x => x.residencyId);
                this.billingService.saveBillingRun(this.billingRun).then((response) => {
                    this.$state.go("createBillingRun.reviewAndCommit");
                    this.billingService.notifyStateChange("createBillingRun.reviewAndCommit");
                }, () => {
                    this.notificationService.error("Unexpected error while saving the billing run.");
                });
            }
        };
        this.billingRunId = this.uiRouter.globals.params.id;
        this.billingService.notifyStateChange("createBillingRun.confirmResidents");
    }
    ngOnInit() {
        this.facilities = this.facilityService.activeUserFacilities;
        this.billingService.getBillingRun(this.billingRunId).then((response) => {
            this.billingRun = response;
            this.selectedFacilities = this.facilities.filter(x => _.some(response.facilityIds, y => { return y == x.id; }));
            this.selectedFacilitiesStr = this.billingService.checkAllFacilitiesSelected(this.facilities, this.selectedFacilities) ? "All" : this.selectedFacilities.map(x => x.name).join(',');
            this.selectedCareTypes = response.careTypes.map(x => Models_1.CareType[x]);
            this.selectedCareTypesStr = this.billingService.checkAllCareTypesSelected(this.selectedFacilities, this.selectedCareTypes) ? "All" : this.selectedCareTypes.join(",");
            this.selectedFeeTypes = response.feeTypes.map(x => Models_1.FeeType[x]);
            this.selectedFeeTypesStr = this.billingService.checkAllFeeTypesSelected(this.selectedCareTypes, this.selectedFeeTypes) ? "All" : this.selectedFeeTypes.join(',');
            this.billingService.getBillingRunResidents(this.billingRun.id).then((response) => {
                this.billingRunResidents = response;
                if (this.billingRun.residencyIds.length > 0)
                    this.selectedResidents = this.billingRunResidents.filter(x => _.some(this.billingRun.residencyIds, y => { return y == x.residencyId; }));
                else //Set all residents selected by default
                    this.selectedResidents = this.billingRunResidents;
            }, () => {
                this.notificationService.error("Unexpected error while getting the billing run residents.");
            });
        }, () => {
            this.notificationService.error("Unexpected error while getting the billing run.");
        });
    }
}
exports.ConfirmResidentsComponent = ConfirmResidentsComponent;
ConfirmResidentsComponent.ɵfac = function ConfirmResidentsComponent_Factory(t) { return new (t || ConfirmResidentsComponent)(i0.ɵɵdirectiveInject(i1.StateService), i0.ɵɵdirectiveInject(i1.UIRouter), i0.ɵɵdirectiveInject(i2.BillingService), i0.ɵɵdirectiveInject('facility.service'), i0.ɵɵdirectiveInject('notification.service')); };
ConfirmResidentsComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ConfirmResidentsComponent, selectors: [["confirm-residents"]], viewQuery: function ConfirmResidentsComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(devextreme_angular_1.DxDataGridComponent, true);
    } if (rf & 2) {
        var _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dataGrid = _t.first);
    } }, decls: 64, vars: 30, consts: [[1, "confirm-residents"], ["novalidate", "", 3, "ngSubmit"], ["confirmResidentsForm", "ngForm"], [1, "col-xs-8", "main-area"], [1, "col-xs-12"], [1, "control-label", "v-align-middle"], [1, "col-xs-12", "billing-run-selection"], [1, "col-xs-3"], [1, "row"], [1, "col-xs-12", "charge-summary-table"], ["id", "gridContainer", 3, "dataSource", "columns", "width", "selection", "selectedRowKeys", "showBorders", "showRowLines", "allowColumnResizing", "columnAutoWidth", "selectedRowKeysChange"], [3, "enabled", "pageSize"], [3, "visible"], ["dataField", "facilityName", "alignment", "left", "dataType", "string", "caption", "FACILITY", 3, "allowSorting", 4, "ngIf"], ["cssClass", "resident-column", "dataField", "residentName", "alignment", "left", "dataType", "string", "cellTemplate", "residentTemplate", "caption", "RESIDENT", 3, "allowSorting"], [4, "dxTemplate", "dxTemplateOf"], ["dataField", "crid", "alignment", "left", "dataType", "string", "caption", "CRID", 3, "allowSorting"], ["dataField", "debtorId", "alignment", "left", "dataType", "string", "caption", "DEBTOR ID", 3, "allowSorting"], [1, "col-xs-4"], [1, "navigation-btn"], ["text", "Next", "type", "success", 3, "disabled", "useSubmitBehavior"], ["type", "button", 1, "btn", "btn-secondary", "btn-lg", "pull-right", 3, "click"], [1, "col-xs-12", "billing-run-info-panel"], [1, "margin-top-15"], ["dataField", "facilityName", "alignment", "left", "dataType", "string", "caption", "FACILITY", 3, "allowSorting"], [1, "resident-name", 3, "ngClass", "title"], ["class", "col-xs-4 departed-label", 4, "ngIf"], [1, "col-xs-4", "departed-label"]], template: function ConfirmResidentsComponent_Template(rf, ctx) { if (rf & 1) {
        const _r5 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "form", 1, 2);
        i0.ɵɵlistener("ngSubmit", function ConfirmResidentsComponent_Template_form_ngSubmit_1_listener() { i0.ɵɵrestoreView(_r5); const _r0 = i0.ɵɵreference(2); return ctx.next(_r0.form.valid); });
        i0.ɵɵelementStart(3, "div", 3);
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵelementStart(5, "label", 5);
        i0.ɵɵtext(6, "Your selection");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "div", 6);
        i0.ɵɵelementStart(8, "div", 7);
        i0.ɵɵelementStart(9, "div", 8);
        i0.ɵɵelementStart(10, "span");
        i0.ɵɵelementStart(11, "strong");
        i0.ɵɵtext(12, "Facilities");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(13, "div", 8);
        i0.ɵɵtext(14);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(15, "div", 7);
        i0.ɵɵelementStart(16, "div", 8);
        i0.ɵɵelementStart(17, "span");
        i0.ɵɵelementStart(18, "strong");
        i0.ɵɵtext(19, "Care type");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(20, "div", 8);
        i0.ɵɵtext(21);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(22, "div", 7);
        i0.ɵɵelementStart(23, "div", 8);
        i0.ɵɵelementStart(24, "span");
        i0.ɵɵelementStart(25, "strong");
        i0.ɵɵtext(26, "Fee type");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(27, "div", 8);
        i0.ɵɵtext(28);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(29, "div", 7);
        i0.ɵɵelementStart(30, "div", 8);
        i0.ɵɵelementStart(31, "span");
        i0.ɵɵelementStart(32, "strong");
        i0.ɵɵtext(33, "Residents in this run");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(34, "div", 8);
        i0.ɵɵtext(35);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(36, "div", 9);
        i0.ɵɵelementStart(37, "label", 5);
        i0.ɵɵtext(38, "Charge summary");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(39, "dx-data-grid", 10);
        i0.ɵɵlistener("selectedRowKeysChange", function ConfirmResidentsComponent_Template_dx_data_grid_selectedRowKeysChange_39_listener($event) { return ctx.selectedResidents = $event; });
        i0.ɵɵelement(40, "dxo-paging", 11);
        i0.ɵɵelement(41, "dxo-filter-row", 12);
        i0.ɵɵtemplate(42, ConfirmResidentsComponent_dxi_column_42_Template, 1, 1, "dxi-column", 13);
        i0.ɵɵelementStart(43, "dxi-column", 14);
        i0.ɵɵtemplate(44, ConfirmResidentsComponent_div_44_Template, 4, 7, "div", 15);
        i0.ɵɵelementEnd();
        i0.ɵɵelement(45, "dxi-column", 16);
        i0.ɵɵelement(46, "dxi-column", 17);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(47, "div", 18);
        i0.ɵɵelementStart(48, "div", 19);
        i0.ɵɵelement(49, "dx-button", 20);
        i0.ɵɵelementStart(50, "button", 21);
        i0.ɵɵlistener("click", function ConfirmResidentsComponent_Template_button_click_50_listener() { return ctx.back(); });
        i0.ɵɵtext(51, "Back");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(52, "div", 22);
        i0.ɵɵelementStart(53, "strong");
        i0.ɵɵtext(54);
        i0.ɵɵelementEnd();
        i0.ɵɵelement(55, "div", 23);
        i0.ɵɵtext(56);
        i0.ɵɵelement(57, "div", 23);
        i0.ɵɵelementStart(58, "label", 5);
        i0.ɵɵelementStart(59, "strong");
        i0.ɵɵtext(60, "Charge to date");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(61, "div");
        i0.ɵɵtext(62);
        i0.ɵɵpipe(63, "date");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(14);
        i0.ɵɵtextInterpolate(ctx.selectedFacilitiesStr);
        i0.ɵɵadvance(7);
        i0.ɵɵtextInterpolate(ctx.selectedCareTypesStr);
        i0.ɵɵadvance(7);
        i0.ɵɵtextInterpolate(ctx.selectedFeeTypesStr);
        i0.ɵɵadvance(7);
        i0.ɵɵtextInterpolate(ctx.selectedResidents.length);
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("dataSource", ctx.billingRunResidents)("columns", i0.ɵɵpureFunction0(28, _c1))("width", 750)("selection", i0.ɵɵpureFunction0(29, _c2))("selectedRowKeys", ctx.selectedResidents)("showBorders", true)("showRowLines", true)("allowColumnResizing", true);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("enabled", true)("pageSize", 50);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("visible", true);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.selectedFacilities.length > 1);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("allowSorting", false);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("dxTemplateOf", "residentTemplate");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("allowSorting", false);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("allowSorting", false);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("disabled", ctx.selectedResidents.length == 0)("useSubmitBehavior", true);
        i0.ɵɵadvance(5);
        i0.ɵɵtextInterpolate(ctx.billingRun.name);
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate1(" ", ctx.billingRun.description, " ");
        i0.ɵɵadvance(6);
        i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(63, 25, ctx.billingRun.chargeToDateUtc, "dd MMM yyyy"));
    } }, directives: [i3.ɵangular_packages_forms_forms_y, i3.NgControlStatusGroup, i3.NgForm, i4.DxDataGridComponent, i5.DxoPagingComponent, i5.DxoFilterRowComponent, i6.NgIf, i5.DxiColumnComponent, i7.DxTemplateDirective, i4.DxButtonComponent, i6.NgClass], pipes: [i6.DatePipe], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ConfirmResidentsComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'confirm-residents',
                templateUrl: './confirmResidents.component.html'
            }]
    }], function () { return [{ type: i1.StateService }, { type: i1.UIRouter }, { type: i2.BillingService }, { type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['facility.service']
            }] }, { type: undefined, decorators: [{
                type: core_1.Inject,
                args: ['notification.service']
            }] }]; }, { dataGrid: [{
            type: core_1.ViewChild,
            args: [devextreme_angular_1.DxDataGridComponent, { static: false }]
        }] }); })();
