"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const i0 = require("@angular/core");
/**
 * Blank loading ILU lead card.
 */
class LoadingLeadCardComponent {
}
exports.LoadingLeadCardComponent = LoadingLeadCardComponent;
LoadingLeadCardComponent.ɵfac = function LoadingLeadCardComponent_Factory(t) { return new (t || LoadingLeadCardComponent)(); };
LoadingLeadCardComponent.ɵcmp = i0.ɵɵdefineComponent({ type: LoadingLeadCardComponent, selectors: [["loading-lead-card"]], decls: 8, vars: 0, consts: [[1, "loading-lead-card"], [1, "template", 2, "margin-bottom", "26px", "width", "60%"], [1, "template", 2, "margin-bottom", "16px", "width", "100%"], [1, "template", "pull-right", 2, "width", "12%"], [2, "clear", "both"]], template: function LoadingLeadCardComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelement(1, "div", 1);
        i0.ɵɵelement(2, "div", 1);
        i0.ɵɵelement(3, "div", 2);
        i0.ɵɵelement(4, "div", 2);
        i0.ɵɵelement(5, "div", 3);
        i0.ɵɵelement(6, "div", 4);
        i0.ɵɵelement(7, "div");
        i0.ɵɵelementEnd();
    } }, encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(LoadingLeadCardComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'loading-lead-card',
                templateUrl: './loading-lead-card.component.html'
            }]
    }], null, null); })();
