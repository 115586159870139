"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const platform_browser_1 = require("@angular/platform-browser");
const forms_1 = require("@angular/forms");
const shared_module_1 = require("../common/shared.module");
const angular_hybrid_1 = require("@uirouter/angular-hybrid");
const devextreme_angular_1 = require("devextreme-angular");
const admin_states_1 = require("./admin.states");
const deviceActivation_component_1 = require("./deviceActivation/deviceActivation.component");
const organisation_details_component_1 = require("./organisation-details/organisation-details.component");
const edit_organisation_details_component_1 = require("./organisation-details/edit-organisation-details.component");
const admin_service_1 = require("./admin.service");
const organisation_service_1 = require("./services/organisation.service");
const i0 = require("@angular/core");
const i1 = require("@uirouter/angular-hybrid");
class AdminModule {
}
exports.AdminModule = AdminModule;
AdminModule.ɵmod = i0.ɵɵdefineNgModule({ type: AdminModule });
AdminModule.ɵinj = i0.ɵɵdefineInjector({ factory: function AdminModule_Factory(t) { return new (t || AdminModule)(); }, providers: [
        admin_service_1.AdminService,
        organisation_service_1.OrganisationService
    ], imports: [[
            shared_module_1.SharedModule,
            platform_browser_1.BrowserModule,
            forms_1.FormsModule,
            devextreme_angular_1.DxFormModule,
            devextreme_angular_1.DxButtonModule,
            devextreme_angular_1.DxDataGridModule,
            devextreme_angular_1.DxDateBoxModule,
            devextreme_angular_1.DxDropDownBoxModule,
            devextreme_angular_1.DxListModule,
            devextreme_angular_1.DxValidatorModule,
            devextreme_angular_1.DxPopupModule,
            angular_hybrid_1.UIRouterUpgradeModule.forChild({ states: admin_states_1.states })
        ]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(AdminModule, { declarations: [organisation_details_component_1.OrganisationDetailsComponent,
        edit_organisation_details_component_1.EditOrganisationDetailsComponent,
        deviceActivation_component_1.DeviceActivationComponent], imports: [shared_module_1.SharedModule,
        platform_browser_1.BrowserModule,
        forms_1.FormsModule,
        devextreme_angular_1.DxFormModule,
        devextreme_angular_1.DxButtonModule,
        devextreme_angular_1.DxDataGridModule,
        devextreme_angular_1.DxDateBoxModule,
        devextreme_angular_1.DxDropDownBoxModule,
        devextreme_angular_1.DxListModule,
        devextreme_angular_1.DxValidatorModule,
        devextreme_angular_1.DxPopupModule, i1.UIRouterUpgradeModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AdminModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [
                    shared_module_1.SharedModule,
                    platform_browser_1.BrowserModule,
                    forms_1.FormsModule,
                    devextreme_angular_1.DxFormModule,
                    devextreme_angular_1.DxButtonModule,
                    devextreme_angular_1.DxDataGridModule,
                    devextreme_angular_1.DxDateBoxModule,
                    devextreme_angular_1.DxDropDownBoxModule,
                    devextreme_angular_1.DxListModule,
                    devextreme_angular_1.DxValidatorModule,
                    devextreme_angular_1.DxPopupModule,
                    angular_hybrid_1.UIRouterUpgradeModule.forChild({ states: admin_states_1.states })
                ],
                providers: [
                    admin_service_1.AdminService,
                    organisation_service_1.OrganisationService
                ],
                declarations: [
                    organisation_details_component_1.OrganisationDetailsComponent,
                    edit_organisation_details_component_1.EditOrganisationDetailsComponent,
                    deviceActivation_component_1.DeviceActivationComponent
                ]
            }]
    }], null, null); })();
