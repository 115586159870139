"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// This is the latest Material paginator as of 30 July 2023 (16.2.0???) (https://github.com/angular/components/blob/main/src/material/paginator/paginator.ts)
// but hacked down for Angular 9
// and given a HTML template that matches DNA-R styling
// and hacked to include functionality for DNA-R
// and hacked to remove superfluous functionality
const coercion_1 = require("@angular/cdk/coercion");
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const i0 = require("@angular/core");
const i1 = require("@angular/forms");
const i2 = require("@angular/common");
const i3 = require("@angular/material/button");
function DnaRPaginator_option_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 10);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const pageSize_r1 = ctx.$implicit;
    i0.ɵɵpropertyInterpolate("value", pageSize_r1);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1("", pageSize_r1, " ");
} }
/** The default page size if there is no page size and there are no provided page size options. */
const DEFAULT_PAGE_SIZE = 50;
/**
 * Change event object that is emitted when the user selects a
 * different page size or navigates to another page.
 */
class PageEvent {
    constructor(init) {
        Object.assign(this, init);
    }
}
exports.PageEvent = PageEvent;
/**
 * Base class with all of the `MatPaginator` functionality.
 * @docs-private
 */
class _MatPaginatorBase {
    constructor(_changeDetectorRef, defaults) {
        this._changeDetectorRef = _changeDetectorRef;
        this._pageIndex = 0;
        this._length = 0;
        this._pageSize = DEFAULT_PAGE_SIZE;
        this._pageSizeOptions = [];
        this._hidePageSize = false;
        /** The term to use to describe item in the UI */
        this.itemsDisplayLabel = "Items";
        /** Event emitted when the paginator changes the page size or page index. */
        this.page = new core_1.EventEmitter();
        /** Use obserable to debounce changes to page index */
        this.changeDisplayPageIndex = new rxjs_1.Subject();
        if (defaults) {
            const { pageSize, pageSizeOptions, hidePageSize } = defaults;
            if (pageSize != null) {
                this._pageSize = pageSize;
            }
            if (pageSizeOptions != null) {
                this._pageSizeOptions = pageSizeOptions;
            }
            if (hidePageSize != null) {
                this._hidePageSize = hidePageSize;
            }
        }
    }
    /** The zero-based page index of the displayed list of items. Defaulted to 0. */
    get pageIndex() {
        return this._pageIndex;
    }
    set pageIndex(value) {
        this._pageIndex = Math.max(coercion_1.coerceNumberProperty(value), 0);
        this._changeDetectorRef.markForCheck();
    }
    /** The one-based page index of the displayed list of items. Defaults to 1. Intended to display to users */
    get displayPageIndex() {
        return this._pageIndex + 1;
    }
    setDisplayPageIndex(value) {
        if (value < 1) {
            value = 1;
        }
        else if (value > this.getNumberOfPages()) {
            value = this.getNumberOfPages();
        }
        const previousPageIndex = this.pageIndex;
        this.pageIndex = value - 1;
        this._emitPageEvent(previousPageIndex);
    }
    /** The length of the total number of items that are being paginated. Defaulted to 0. */
    get length() {
        return this._length;
    }
    set length(value) {
        this._length = coercion_1.coerceNumberProperty(value);
        this._changeDetectorRef.markForCheck();
    }
    /** Number of items to display on a page. By default set to 50. */
    get pageSize() {
        return this._pageSize;
    }
    set pageSize(value) {
        this._pageSize = value;
        this._updateDisplayedPageSizeOptions();
    }
    /** The set of provided page size options to display to the user. */
    get pageSizeOptions() {
        return this._pageSizeOptions;
    }
    set pageSizeOptions(value) {
        this._pageSizeOptions = value || [];
        this._updateDisplayedPageSizeOptions();
    }
    /** Whether to hide the page size selection UI from the user. */
    get hidePageSize() {
        return this._hidePageSize;
    }
    set hidePageSize(value) {
        this._hidePageSize = coercion_1.coerceBooleanProperty(value);
    }
    ngOnInit() {
        this._initialized = true;
        this._updateDisplayedPageSizeOptions();
        this.changeDisplayPageIndex
            .pipe(operators_1.debounceTime(400), operators_1.distinctUntilChanged()).subscribe(value => {
            this.setDisplayPageIndex(value);
        });
    }
    ngOnDestroy() {
        this.changeDisplayPageIndex.unsubscribe();
    }
    /** Advances to the next page if it exists. */
    nextPage() {
        if (!this.hasNextPage()) {
            return;
        }
        const previousPageIndex = this.pageIndex;
        this.pageIndex = this.pageIndex + 1;
        this._emitPageEvent(previousPageIndex);
    }
    /** Move back to the previous page if it exists. */
    previousPage() {
        if (!this.hasPreviousPage()) {
            return;
        }
        const previousPageIndex = this.pageIndex;
        this.pageIndex = this.pageIndex - 1;
        this._emitPageEvent(previousPageIndex);
    }
    /** Move to the first page if not already there. */
    firstPage() {
        // hasPreviousPage being false implies at the start
        if (!this.hasPreviousPage()) {
            return;
        }
        const previousPageIndex = this.pageIndex;
        this.pageIndex = 0;
        this._emitPageEvent(previousPageIndex);
    }
    /** Move to the last page if not already there. */
    lastPage() {
        // hasNextPage being false implies at the end
        if (!this.hasNextPage()) {
            return;
        }
        const previousPageIndex = this.pageIndex;
        this.pageIndex = this.getNumberOfPages() - 1;
        this._emitPageEvent(previousPageIndex);
    }
    /** Whether there is a previous page. */
    hasPreviousPage() {
        return this.pageIndex >= 1 && !isNaN(this.pageSize) && this.pageSize != 0;
    }
    /** Whether there is a next page. */
    hasNextPage() {
        const maxPageIndex = this.getNumberOfPages() - 1;
        return this.pageIndex < maxPageIndex && !isNaN(this.pageSize) && this.pageSize != 0;
    }
    /** Calculate the number of pages */
    getNumberOfPages() {
        if (!this.pageSize || isNaN(this.pageSize)) {
            return 0;
        }
        return Math.ceil(this.length / this.pageSize);
    }
    /**
     * Changes the page size so that the first item displayed on the page will still be
     * displayed using the new page size.
     *
     * For example, if the page size is 10 and on the second page (items indexed 10-19) then
     * switching so that the page size is 5 will set the third page as the current page so
     * that the 10th item will still be displayed.
     */
    _changePageSize(pageSize) {
        const previousPageIndex = this.pageIndex;
        if (!isNaN(pageSize.target.value) && !isNaN(this.pageSize)) {
            // Current page needs to be updated to reflect the new page size. Navigate to the page
            // containing the previous page's first item.
            const startIndex = this.pageIndex * this.pageSize;
            this.pageIndex = Math.floor(startIndex / pageSize.target.value) || 0;
        }
        else {
            this.pageIndex = 0;
        }
        this.pageSize = pageSize.target.value;
        this._emitPageEvent(previousPageIndex);
    }
    /** Checks whether the buttons for going forwards should be disabled. */
    _nextButtonsDisabled() {
        return !this.hasNextPage();
    }
    /** Checks whether the buttons for going backwards should be disabled. */
    _previousButtonsDisabled() {
        return !this.hasPreviousPage();
    }
    /**
     * Updates the list of page size options to display to the user. Includes making sure that
     * the page size is an option and that the list is sorted.
     */
    _updateDisplayedPageSizeOptions() {
        if (!this._initialized) {
            return;
        }
        // If no page size is provided, use the first page size option or the default page size.
        if (!this.pageSize) {
            this._pageSize =
                this.pageSizeOptions.length != 0 ? this.pageSizeOptions[0] : DEFAULT_PAGE_SIZE;
        }
        this._changeDetectorRef.markForCheck();
    }
    /** Emits an event notifying that a change of the paginator's properties has been triggered. */
    _emitPageEvent(previousPageIndex) {
        this.page.emit({
            previousPageIndex,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            length: this.length,
        });
    }
}
exports._MatPaginatorBase = _MatPaginatorBase;
_MatPaginatorBase.ɵfac = function _MatPaginatorBase_Factory(t) { i0.ɵɵinvalidFactory(); };
_MatPaginatorBase.ɵdir = i0.ɵɵdefineDirective({ type: _MatPaginatorBase, inputs: { pageIndex: "pageIndex", displayPageIndex: "displayPageIndex", length: "length", pageSize: "pageSize", pageSizeOptions: "pageSizeOptions", hidePageSize: "hidePageSize", itemsDisplayLabel: "itemsDisplayLabel" }, outputs: { page: "page" } });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(_MatPaginatorBase, [{
        type: core_1.Directive
    }], function () { return [{ type: i0.ChangeDetectorRef }, { type: undefined }]; }, { pageIndex: [{
            type: core_1.Input
        }], displayPageIndex: [{
            type: core_1.Input
        }], length: [{
            type: core_1.Input
        }], pageSize: [{
            type: core_1.Input
        }], pageSizeOptions: [{
            type: core_1.Input
        }], hidePageSize: [{
            type: core_1.Input
        }], itemsDisplayLabel: [{
            type: core_1.Input
        }], page: [{
            type: core_1.Output
        }] }); })();
/**
 * Component to provide navigation between paged information. Displays the size of the current
 * page, user-selectable options to change that size, what items are being shown, and
 * navigational button to go to the previous or next page.
 */
class DnaRPaginator extends _MatPaginatorBase {
    constructor(changeDetectorRef) {
        super(changeDetectorRef, null);
    }
}
exports.DnaRPaginator = DnaRPaginator;
DnaRPaginator.ɵfac = function DnaRPaginator_Factory(t) { return new (t || DnaRPaginator)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef)); };
DnaRPaginator.ɵcmp = i0.ɵɵdefineComponent({ type: DnaRPaginator, selectors: [["dnaR-paginator"]], hostAttrs: ["role", "group", 1, "mat-mdc-paginator"], features: [i0.ɵɵInheritDefinitionFeature], decls: 15, vars: 11, consts: [[1, "dna-r-paginator"], [3, "hidden"], [1, "paginator-page-size-select", 3, "ngModel", "change"], [3, "value", 4, "ngFor", "ngForOf"], [1, "paginator-range-label"], ["mat-icon-button", "", "type", "button", 3, "disabled", "click"], [1, "icon", "icon-chevron-left-outline"], [1, "paginator-page-label"], ["type", "text", 1, "paginator-page-input", 3, "ngModel", "ngModelChange"], [1, "icon", "icon-chevron-right-outline"], [3, "value"]], template: function DnaRPaginator_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "select", 2);
        i0.ɵɵlistener("change", function DnaRPaginator_Template_select_change_2_listener($event) { return ctx._changePageSize($event); });
        i0.ɵɵtemplate(3, DnaRPaginator_option_3_Template, 2, 2, "option", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵtext(4);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "div");
        i0.ɵɵelementStart(6, "div", 4);
        i0.ɵɵtext(7);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(8, "button", 5);
        i0.ɵɵlistener("click", function DnaRPaginator_Template_button_click_8_listener() { return ctx.previousPage(); });
        i0.ɵɵelement(9, "div", 6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(10, "div", 7);
        i0.ɵɵelementStart(11, "input", 8);
        i0.ɵɵlistener("ngModelChange", function DnaRPaginator_Template_input_ngModelChange_11_listener($event) { return ctx.changeDisplayPageIndex.next($event); });
        i0.ɵɵelementEnd();
        i0.ɵɵtext(12);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(13, "button", 5);
        i0.ɵɵlistener("click", function DnaRPaginator_Template_button_click_13_listener() { return ctx.nextPage(); });
        i0.ɵɵelement(14, "div", 9);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("hidden", ctx.hidePageSize);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngModel", ctx.pageSize);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngForOf", ctx.pageSizeOptions);
        i0.ɵɵadvance(1);
        i0.ɵɵtextInterpolate1(" ", ctx.itemsDisplayLabel, " per page ");
        i0.ɵɵadvance(3);
        i0.ɵɵtextInterpolate3(" Page ", ctx.displayPageIndex, " of ", ctx.getNumberOfPages(), " (", ctx.length, " items) ");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("disabled", ctx._previousButtonsDisabled());
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngModel", ctx.displayPageIndex);
        i0.ɵɵadvance(1);
        i0.ɵɵtextInterpolate1(" of ", ctx.getNumberOfPages(), " ");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("disabled", ctx._nextButtonsDisabled());
    } }, directives: [i1.SelectControlValueAccessor, i1.NgControlStatus, i1.NgModel, i2.NgForOf, i3.MatButton, i1.DefaultValueAccessor, i1.NgSelectOption, i1.ɵangular_packages_forms_forms_x], encapsulation: 2, changeDetection: 0 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DnaRPaginator, [{
        type: core_1.Component,
        args: [{
                selector: 'dnaR-paginator',
                templateUrl: 'dnaR-paginator.html',
                inputs: [],
                host: {
                    'class': 'mat-mdc-paginator',
                    'role': 'group',
                },
                changeDetection: core_1.ChangeDetectionStrategy.OnPush,
                encapsulation: core_1.ViewEncapsulation.None,
            }]
    }], function () { return [{ type: i0.ChangeDetectorRef }]; }, null); })();
